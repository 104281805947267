import React from 'react'
import { Field as FormikField } from 'formik'
import { Form, Radio } from 'antd'

const RadioGroup = ({
  name,
  label,
  value,
  style,
  onChange,
  setFieldTouched,
  children,
  options,
  disabled,
}) => (
  <Form.Item label={label}>
    <div className='validation_block'>
      <FormikField
        render={() => (
          <Radio.Group
            style={style}
            value={value}
            name={name}
            onChange={e => onChange(name, e.target.value)}
            onBlur={() => setFieldTouched(name, true)}
            disabled={disabled}
          >
            {options?.map(o => <Radio key={o} value={o}>{o}</Radio>)}
          </Radio.Group>
        )}
      />
      <div className='validation_block margin_top__small'>{children}</div>
    </div>
  </Form.Item>
)

export default RadioGroup
