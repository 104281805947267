import React from 'react'
import moment from 'moment'
import { List, Tooltip } from 'antd'
import setAvatarStyle from '../../../utils/getStyle/getAvatarStyle'
import stopPropagation from '../../../utils/stopPropagation'

const LecturesListItem = ({
  lecture,
  getItemActivityClass,
  setActiveLecture,
  number,
  icon,
  onIconClick,
}) => {
  const { speaker, title, id, date } = lecture
  return (
    <List.Item
      id={`lecture-${id}`}
      key={id}
      className={getItemActivityClass(id)}
      onClick={() => setActiveLecture(id)}
    >
      <span className='lectures_list__id'>{`${number + 1}.`}</span>
      <span className='lectures_list__title'>{title}</span>
      <div className='lectures_list__speaker_block'>
        {speaker && (
          <div className='user_avatar__small' style={setAvatarStyle(speaker.photo)} />
        )}
        {speaker?.id
          ? (
            <Tooltip title={speaker.name}>
              <span className='lectures_list__speaker'>{speaker.name}</span>
            </Tooltip>
          )
          : <span className='lectures_list__speaker secondary_text__inactive'>Не назначен</span>}
      </div>
      <span className='lectures_list__time'>{moment(date).format('DD MMM HH:mm')}</span>
      <div
        className='icon__wrapper__hover_enabled lectures_list__icon'
        onClick={e => stopPropagation(() => onIconClick({ id, title }), e)}
      >
        <div className={icon} />
      </div>
    </List.Item>
  )
}

export default LecturesListItem
