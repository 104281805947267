import React from 'react'
import moment from 'moment'

const EventsCardListItem = ({ event, index, marker }) => (
  <>
    <div className='card_list__item_marker'><div className={marker} /></div>
    <span className='card_list__item_id'>{`${index + 1}.`}</span>
    <span className='card_list__item_title'>{`${event.title}`}</span>
    <span className='card_list__item_place'>{`${event.place?.title || 'Не назначено'}`}</span>
    <span className='card_list__item_time'>{moment(event.date).format('DD.MM.YY')}</span>
  </>
)

export default EventsCardListItem
