import {
  dataLabelsColor,
  gridLabelsColor,
  gridLinesColor,
  gridLinesOptions,
  legendOptions,
} from './globalSetups'

const horizontalGroupedBarChartOptions = (maxScaleVal, cropYLabels) => ({
  ...legendOptions,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      ticks: {
        fontColor: gridLabelsColor,
        fontFamily: 'DIN Pro Light',
        suggestedMax: maxScaleVal,
        beginAtZero: true,
      },
      gridLines: gridLinesOptions,
    }],
    yAxes: [{
      ticks: {
        fontColor: gridLabelsColor,
        fontFamily: 'DIN Pro Light',
        callback: value => cropYLabels && value.length >= 4 ? `${value.substring(0, 3)}...` : value,
      },
      gridLines: { ...gridLinesOptions, zeroLineColor: gridLinesColor, zeroLineWidth: 0.5 },
    }],
  },
  plugins: {
    datalabels: {
      ...dataLabelsColor,
      display: true,
      align: 'end',
      anchor: 'end',
      offset: 5,
    },
  },
})

export default horizontalGroupedBarChartOptions
