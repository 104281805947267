import { put, call } from 'redux-saga/effects'
import { message } from 'antd'

import { POST } from '../utils/methods'
import HOST from '../utils/data/connectionData'
import { authHeader, contentHeader } from '../utils/data/requestHeaders'
import { SET_TOKEN, SET_TOKEN_LOADING } from '../actions/account'
import { removeTokenFromStorage } from '../utils/manageTokens'

const request = (path, payload) => call(fetch, `${HOST}${path}`, payload)

function* getNewToken() {
  const response = yield request('/refresh', {
    method: POST,
    headers: authHeader(localStorage.getItem('refresh_token')),
  })

  const res = yield response.json()
  if (res.message === 'Token has expired') return null

  return yield res
}

const getHeader = header => header === 'auth' ? authHeader() : contentHeader()

function* getResponse({ method, path, headers, body, isFile }) {
  const payload = {
    method,
    headers: getHeader(headers),
    body: isFile ? body : JSON.stringify(body),
  }

  const response = yield request(path, payload)
  const res = yield response.statusText === 'NO CONTENT' ? {} : response.json()

  if (res.msg || res.message)
    switch (res.msg || res.message) {
      case 'Token has expired': {
        yield put({ type: SET_TOKEN_LOADING })
        const newToken = yield getNewToken()

        if (newToken === null) {
          removeTokenFromStorage()
          window.location.pathname = '/auth'
          return null
        }

        if (newToken.access_token && newToken.refresh_token) yield put({
          type: SET_TOKEN,
          token: newToken,
        })

        return yield getResponse({
          method: method,
          path: path,
          headers: headers,
          body: body,
          isFile: isFile,
        })
      }

      case 'Not enough segments':
      case 'Token has been revoked':
        return yield getResponse({
          method: method,
          path: path,
          headers: headers,
          body: body,
          isFile: isFile,
        })

      case 'Invalid Credentials':
      case 'No such user': {
        message.error('Неверный логин или пароль')
        return null
      }

      case 'Phone number is occupied': {
        message.error('Введенный номер телефона уже используется')
        return null
      }

      case 'No such event': {
        message.error('Событие не найдено')
        return null
      }

      case 'Not found': {
        const targetPath = path.split('?')[0]
        switch (targetPath) {
          case '/user': {
            message.error('Пользователь не найден')
            return null
          }
          case '/event': {
            message.error('Событие не найдено')
            return null
          }
          case '/poll': {
            message.error('Голосование не найдено')
            return null
          }
          default: {
            message.error('Объект не найден')
            return null
          }
        }
      }

      case 'Signature verification failed':
      case 'Account not found': {
        window.location.pathname = '/auth'
        return null
      }

      case 'Date range is being overflowed by existing booking': return res

      case 'Event not found': {
        message.error('Событие не найдено')
        return res
      }

      case 'No suitable tables found': {
        message.error('Нет доступных столов')
        return null
      }

      case 'Access denied': {
        message.error('Недостаточно прав')
        return null
      }

      case 'Old password does not match': {
        message.error('Старый пароль введен неверно')
        return null
      }

      case 'New password is invalid': {
        message.error('Новый пароль содержит недопустимые символы')
        return null
      }

      case 'User has no email specified': {
        message.error('Для пользователя не установлен e-mail адрес')
        return null
      }

      case 'Ending time should be larger than the starting one': {
        message.error('Дата окончания должна быть посте даты начала')
        return null
      }

      case 'OK': return res

      case 'Graph data JSON is in invalid format': return null

      default: {
        message.error('Внутренняя ошибка сервера')
        return res
      }
    }

  return res
}

export default getResponse
