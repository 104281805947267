import moment from 'moment'

// TODO: проверить что входные параметры даны верно

const getTimeMarkerStyle = (dateFrom, dateTo) => {
  const basic = 'round_marker'
  if (moment().isBefore(moment(dateFrom))) return `blue_${basic}`
  if (moment().isAfter(moment(dateTo))) return `yellow_${basic}`
  return `green_${basic}`
}

export default getTimeMarkerStyle
