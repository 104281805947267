import React, { useState } from 'react'
import stopPropagation from '../../../utils/stopPropagation'

const ScreenListItem = ({
  item,
  activeItem,
  handleClick,
  fieldToShow,
  subtitleField,
  selectedItem,
  setSelectedItem,
  customOnCancel,
  setDefaultChartOnAir,
}) => {
  const [isConfirmShow, setIsConfirmShow] = useState(false)

  const getCheckBtnStyle = () => {
    const basicStyle = 'screen_list__item_check'
    return (item.id === activeItem) ? `${basicStyle}__active` : basicStyle
  }

  const getItemStyle = () => {
    const basicStyle = 'screen_list__item'
    if (activeItem === null) return basicStyle

    return (item.id === activeItem) ? basicStyle : `${basicStyle}__inactive`
  }

  const getActivityButtonStyle = () => {
    const basicStyle = 'screen_list__button'
    return (item.id === activeItem) ? `${basicStyle}__red` : `${basicStyle}__blue`
  }

  const handleConfirm = () => {
    handleClick(item.id)
    setIsConfirmShow(false)
    setSelectedItem(null)
    item.id === activeItem && setDefaultChartOnAir && setDefaultChartOnAir()
  }

  const showConfirm = () => {
    setSelectedItem(selectedItem === item.id ? null : item.id)
    setIsConfirmShow(true)
  }

  const hideConfirm = () => {
    setIsConfirmShow(false)
    setSelectedItem(null)
  }

  return (
    <div key={item.id} onClick={e => stopPropagation(showConfirm, e)} className={`${getItemStyle()}`}>
      <div className='screen_list__item__content_wrapper'>
        <div className='screen_list__item__title_wrapper'>
          <div className='list__item__title'>{item[fieldToShow]}</div>
          {subtitleField && item[subtitleField]
            && <div className='list__item__subtitle'>{item[subtitleField]}</div>}
        </div>
        {isConfirmShow && (selectedItem === item.id) && (
          <div className='screen_list__button_container'>
            <div
              className={getActivityButtonStyle()}
              onClick={e => stopPropagation(handleConfirm, e)}
            >
              {(item.id === activeItem) ? 'Стоп' : 'Старт'}
            </div>
            <div
              className='screen_list__button_gray'
              onClick={e => stopPropagation(customOnCancel || hideConfirm, e)}
            >
              Отмена
            </div>
          </div>
        )}
        <div className={getCheckBtnStyle()}><div className='icon__play' /></div>
      </div>
    </div>
  )
}

export default ScreenListItem
