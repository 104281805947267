import moment from 'moment'
import { TODAY } from '../formatDate'

const filterEvents = (list, type) => {
  let filteredList = []

  switch (type) {
    case 'all': {
      filteredList = list
      break
    }
    case 'future': {
      filteredList = list.filter(event => moment(event.date).isSameOrAfter(TODAY, 'day'))
      break
    }
    case 'completed': {
      filteredList = list.filter(event => moment(event.date).isBefore(TODAY, 'day'))
      break
    }
    default:
      break
  }

  return filteredList
}

export default filterEvents
