import { put, takeLatest } from 'redux-saga/effects'
import { message } from 'antd'

import getResponse from './getResponse'
import { DELETE, GET, POST } from '../utils/methods'
import { auth, content } from '../utils/data/requestHeaders'
import { dateToShortString } from '../utils/formatDate'
import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCEED,
  POST_BOOKING,
  POST_BOOKING_SUCCEED,
  SET_BOOKING_LOADING,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCEED,
  POST_FAST_BOOKING,
  POST_FAST_BOOKING_SUCCEED,
  GET_USER_BOOKING_HISTORY,
  GET_USER_BOOKING_HISTORY_SUCCEED,
} from '../actions/booking'
import nextPaginationStart from '../utils/nextPaginationStart'
import { ITEMS_ON_PAGE } from '../utils/data/constants'

function* getBookings({ tableId, callback }) {
  try {
    yield put({ type: SET_BOOKING_LOADING })
    const booking = yield getResponse({
      method: GET,
      path: `/book_list/table?table=${tableId}`,
      headers: auth,
    })

    yield put({ type: GET_BOOKINGS_SUCCEED, booking })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* getUserBookings({ userId, page, callback }) {
  try {
    yield put({ type: SET_BOOKING_LOADING })
    const path = `/book_list/user?start=${nextPaginationStart(page)}&limit=${ITEMS_ON_PAGE}&user=${userId}`
    const booking = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_USER_BOOKING_HISTORY_SUCCEED, booking, page })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* postBooking({ booking, callback }) {
  try {
    const newBooking = yield getResponse({
      method: POST,
      path: '/book_operator',
      headers: content,
      body: booking,
    })

    if (newBooking.message === 'Date range is being overflowed by existing booking') {
      const dateFrom = dateToShortString(newBooking.overflows[0].date_from)
      const dateTo = dateToShortString(newBooking.overflows[0].date_to)
      message.error(`Стол занят с ${dateFrom} до ${dateTo}`)
      return null
    }
    yield put({ type: POST_BOOKING_SUCCEED, newBooking })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* postFastBooking({ booking, history, addToHistory, callback }) {
  try {
    const newBooking = yield getResponse({
      method: POST,
      path: '/book_operator/fast',
      headers: content,
      body: booking,
    })

    if (!newBooking) return null

    yield put({ type: addToHistory ? POST_BOOKING_SUCCEED : POST_FAST_BOOKING_SUCCEED, newBooking })
    const type = booking.type === 'Переговорная' ? 'room' : 'table'

    history && history.push(`/reservation/${type}/${newBooking.table.id}`)
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* deleteBooking({ bookId }) {
  try {
    yield getResponse({ method: DELETE, path: `/books/${bookId}`, headers: auth })
    yield put({ type: DELETE_BOOKING_SUCCEED, bookId })
    message.success('Бронирование удалено')
  } catch (e) { console.log(e.message) }
}

export default function* pollSagas() {
  yield takeLatest(GET_USER_BOOKING_HISTORY, getUserBookings)
  yield takeLatest(GET_BOOKINGS, getBookings)
  yield takeLatest(POST_BOOKING, postBooking)
  yield takeLatest(DELETE_BOOKING, deleteBooking)
  yield takeLatest(POST_FAST_BOOKING, postFastBooking)
}
