import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'

import ScreenList from '../../Screens/ScreensCardSideBar/ScreenList'
import LargeSpin from '../../UI/Spin/LargeSpin'

const ScreensPopUp = ({
  screens,
  activePresenter,
  setActivePresenter,
  setSwitchActivePopUp,
  customTopOffset,
  customOnCancel,
  isVisible,
}) => {
  const calculateHeight = () => 64 * screens.length + 20

  const calculateCustomTopOffset = () => {
    const popUpHeight = calculateHeight()
    return popUpHeight > 200 ? '-210px' : `-${popUpHeight + 10}px`
  }

  const handleKeyDown = useCallback(e =>
    e.keyCode === 27 && setSwitchActivePopUp(null), [setSwitchActivePopUp])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => document.removeEventListener('keydown', handleKeyDown, false)
  }, [handleKeyDown])

  return (
    <div
      id='screens-pop-up'
      className='pop_up__angle__small'
      onKeyDown={handleKeyDown}
      style={{ height: calculateHeight() + 'px', top: customTopOffset && calculateCustomTopOffset() }}
    >
      <div className='pop_up__angle__small__wrapper'>
        {screens
          ? (
            <ScreenList
              isVisible={isVisible}
              activePresenter={activePresenter}
              setActivePresenter={setActivePresenter}
              list={screens}
              fieldToShow='title'
              subtitleField='description'
              customOnCancel={customOnCancel}
            />
          )
          : <LargeSpin />}
      </div>
    </div>
  )
}

const mapStateToProps = ({ presenters }) => ({
  screens: presenters?.list.filter(p => p.status === 'activated' && !p.on_air),
})

export default connect(mapStateToProps)(ScreensPopUp)
