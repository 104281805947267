import React from 'react'
import * as _ from 'lodash'
import { animated, Transition } from 'react-spring/renderprops'
import { config } from 'react-spring'
import { Timeline } from 'antd'

import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import TimeLineItem from './TimeLineItem'

class TimeLine extends React.PureComponent {
  state = { notifications: [] }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.list !== prevState.notifications) {
      const newList = _.uniqBy(nextProps.list, 'id')
      return ({ notifications: newList })
    }
    return null
  }

  render() {
    const { list, deleteNotification, checkHeight } = this.props

    return (
      <Timeline pending={false} className='side_bar__notifications'>
        {list?.length > 0
          ? (
            <Transition
              unique
              items={this.state.notifications}
              keys={item => item.id}
              trail={25}
              config={(item, state) => {
                switch (state) {
                  case 'enter': return { duration: 200, tension: 400, friction: 50 }
                  case 'leave': return config.default
                  default: return config.default
                }
              }}
              from={{ maxHeight: 0, opacity: 0, transform: 'scale(0.8)' }}
              enter={[{ maxHeight: 1000, opacity: 1 }, { transform: 'scale(1)' }]}
              leave={{ opacity: 0, transform: 'scale(0.8)', maxHeight: 0 }}
              onDestroyed={checkHeight}
            >
              {item => styles => (
                <animated.div style={styles}>
                  <TimeLineItem notification={item} key={item.id} onDelete={deleteNotification} />
                </animated.div>
              )}
            </Transition>
          )
          : <EmptyPlaceholder text='Список уведомлений пуст' />}
      </Timeline>
    )
  }
}

export default TimeLine
