import { all, fork } from 'redux-saga/effects'
import eventsSagas from './eventsSagas'
import filesSagas from './filesSagas'
import lecturesSagas from './lecturesSagas'
import usersSagas from './usersSagas'
import pollSagas from './pollSagas'
import accountSagas from './accountSagas'
import officesSagas from './officesSagas'
import bookingSagas from './bookingSagas'
import presentersSagas from './presentersSagas'
import notificationsSagas from './notificationsSagas'

export default function* watchSaga() {
  yield all([
    fork(eventsSagas),
    fork(filesSagas),
    fork(lecturesSagas),
    fork(usersSagas),
    fork(pollSagas),
    fork(accountSagas),
    fork(officesSagas),
    fork(bookingSagas),
    fork(presentersSagas),
    fork(notificationsSagas),
  ])
}
