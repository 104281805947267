import {
  DELETE_POLL_SUCCEED,
  GET_POLL_SUCCEED,
  GET_POLLS_SUCCEED,
  POLLS_LOADING,
  SAVE_POLL_SUCCEED,
  CLEAN_VOTING,
  GET_POLL_STATISTIC_SUCCEED,
  GET_APP_LINKS_SUCCEED,
} from '../actions/poll'

export const poll = (state = {}, action) => {
  switch (action.type) {
    case POLLS_LOADING: return { ...state, loading: true }

    case GET_POLLS_SUCCEED: return { ...state, list: action.list, loading: false }

    case GET_POLL_SUCCEED: return { ...state, poll: action.poll, loading: false }

    case GET_POLL_STATISTIC_SUCCEED: return { ...state, poll: action.poll, loading: false }

    case SAVE_POLL_SUCCEED: return state

    case DELETE_POLL_SUCCEED:
      return { ...state, list: state.list.filter(p => p.id !== action.pollId) }

    case CLEAN_VOTING: return { ...state, poll: {} }

    case GET_APP_LINKS_SUCCEED: return { ...state, appLinks: action.links }

    default: return state
  }
}

export default poll
