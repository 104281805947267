import filesize from 'filesize'
import { notification } from 'antd'

import { cropStringAtCenter } from '../../../../utils/cropString'

export const validFileFormats = ['gif', 'jpg', 'png', 'zip', 'rar', 'csv', 'doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf', 'jpeg']

export const maxFileSizeBytes = 15 * 1024 * 1024

const getFileSize = file => file.size

const readableFileSize = file => filesize(file.size, { round: 0 })

const getFileExtension = file => file.name?.split('.').pop()

const isSizeValid = file => getFileSize(file) <= maxFileSizeBytes

const isExtensionValid = file => validFileFormats.find(e => e === getFileExtension(file))

const notificationBasicSetups = {
  message: 'Ошибка загрузки файла',
  placement: 'bottomRight',
  className: 'notification_antd',
  duration: 5,
}

export const isFileValid = file => {
  if (!isSizeValid(file)) {
    notification.error({
      ...notificationBasicSetups,
      description: `Файл "${cropStringAtCenter(file.name)}" слишком большой (${readableFileSize(file)}), максимум 15MB.`,
    })
    return false
  }
  if (!isExtensionValid(file)) {
    notification.error({
      ...notificationBasicSetups,
      description: `Недопустимый формат (.${getFileExtension(file)}) файла "${cropStringAtCenter(file.name)}".`,
    })
    return false
  }
  return true
}
