import { gridLinesOptions, gridLabelsColor, gridLinesColor, dataLabelsColor } from './globalSetups'

export const horizontalBarChartOptions = (extremeScaleValues, cropYLabels) => ({
  plugins: {
    datalabels: {
      ...dataLabelsColor,
      display: true,
      align: 'end',
      anchor: 'end',
      offset: 5,
    },
  },
  scales: {
    xAxes: [{
      ticks: {
        fontFamily: 'DIN Pro Light',
        fontColor: gridLabelsColor,
        suggestedMin: 0,
        beginAtZero: true,
        suggestedMax: extremeScaleValues.maxValue,
      },
      gridLines: gridLinesOptions,
    }],
    yAxes: [{
      ticks: {
        fontFamily: 'DIN Pro Light',
        fontColor: gridLabelsColor,
        callback: value => cropYLabels && value.length >= 4 ? `${value.substring(0, 3)}...` : value,
      },
      gridLines: { ...gridLinesOptions, zeroLineColor: gridLinesColor, zeroLineWidth: 0.5 },
    }],
  },
  legend: { display: false },
  tooltips: {
    enabled: true,
    displayColors: false,
  },
  maintainAspectRatio: false,
})

export const horizontalBarChartThickness = 6
export const horizontalBarChartBorderWidth = 0
