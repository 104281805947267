import React from 'react'
import { Route, Switch } from 'react-router-dom'

import UsersGroupList from './index'
import GroupCard from './InfoCard/index'

const Router = () => (
  <Switch>
    <Route exact path='/groups' component={UsersGroupList} />
    <Route exact path='/groups/:id' component={GroupCard} />
  </Switch>
)

export default Router
