import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Scrollbar } from 'react-scrollbars-custom'

const ScrollTransition = ({ children, loading, onScroll, style, transition = true }) => (
  <Scrollbar onScrollStop={onScroll} style={style}>
    {transition
      ? (
        <CSSTransition classNames='card_list' in={loading} timeout={400} appear mountOnEnter>
          {children}
        </CSSTransition>
      )
      : <>{children}</>}
  </Scrollbar>
)

export default ScrollTransition
