import React from 'react'
import { Tooltip } from 'antd'

import stopPropagation from '../../utils/stopPropagation'

const UsersGroupListItem = ({ group, index, onDelete, onClick, hideDeleteIcon }) => {
  const { title, description, id, count } = group
  return (
    <div key={id} className='card_list__item' onClick={e => stopPropagation(onClick, e)}>
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item__group_title'>{title}</span>
      <span className='card_list__item_description'>
        {description
          ? <span className='secondary_text'>{description}</span>
          : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
      </span>
      <span className='card_list__item__users_count'>
        <span className='secondary_text'>Пользователей:</span>
        <span className='secondary_text__white text__wrapper'>{count || 0}</span>
      </span>
      {onDelete && !hideDeleteIcon
        ? (
          <Tooltip title='Удалить' mouseEnterDelay={0.6}>
            <div
              className='icon__wrapper__hover_enabled full_height'
              onClick={e => stopPropagation(() => onDelete(group.id), e)}
            >
              {!hideDeleteIcon && <div className='icon__delete' />}
            </div>
          </Tooltip>
        )
        : <div className='icon__wrapper__full_height' />}
    </div>
  )
}

export default UsersGroupListItem
