import React, { Component } from 'react'
import * as _ from 'lodash'
import { connect } from 'react-redux'

import UsersList from './UsersList'
import { GET_USERS } from '../../../actions/users'
import Button from '../../UI/Button'

class UsersPickContainer extends Component {
  state = {
    selected: this.props.group?.users || [],
    searchString: '',
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false)
  }

  handleKeyDown = e => e.keyCode === 13 && this.props.onSave({ usersList: this.state.selected })

  getRemainItem = () => _.differenceBy(this.props.usersList, this.state.selected, 'id')

  addToSelected = payload => {
    const { selected } = this.state
    const newSelected = selected.concat(payload)
    this.setState({ selected: newSelected })
  }

  removeFromSelected = payload => {
    const { selected } = this.state
    const newSelected = selected.filter(user => user.id !== payload.id)
    this.setState({ selected: newSelected })
  }

  onCardClick = (departure, payload) => (departure === 'remain')
    ? this.addToSelected(payload)
    : this.removeFromSelected(payload)

  onSearchClose = () => this.setState({ searchString: '', page: 1 }, this.props.getUsers)

  render() {
    const { selected } = this.state
    const { loading, usersLoading, onSave } = this.props
    return (
      <div className='container__right_side' style={{ paddingLeft: '22px' }}>
        <div className='flex_container full_height'>
          <UsersList
            name='selected'
            list={selected}
            loading={loading}
            title='Пользователи в группе'
            onCardClick={this.onCardClick}
          />
          <UsersList
            name='remain'
            list={this.getRemainItem()}
            loading={usersLoading}
            title='Пользователи, не включенные в группу'
            onCardClick={this.onCardClick}
            search
            searchString={this.state.searchString}
            setSearchString={val => this.setState({ searchString: val })}
            onSearchClose={this.onSearchClose}
          />
        </div>
        <div className='button_container__edit_card'>
          <Button
            styles='bordered_btn'
            title='Сохранить'
            onClick={() => onSave({ usersList: selected })}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ users }) => {
  const usersWithoutExtraInfo = users?.list?.map(user =>
    ({ id: user.id, name: user.name, description: user.description }))

  return ({
    group: users?.groups?.currentGroup,
    usersList: usersWithoutExtraInfo,
    usersLoading: users?.loading,
    loading: users?.groups?.loading,
    count: users?.count,
  })
}

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch({ type: GET_USERS }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersPickContainer)
