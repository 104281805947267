import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import EventsRouter from './components/Events/EventsRouter'
import Screens from './components/Screens/index'
import Header from './components/Header/Header'
import Notifications from './components/Notifications/index'
import UsersGroupsRouter from './components/UserGroups/Router'
import MainPage from './components/MainPage'
import VotingRouter from './components/Voting/VotingRouter'
import VoteForm from './components/Voting/editing'

const OneSideBarRouter = () => (
  <>
    <Header />
    <div className='screen__one_sidebar'>
      <Switch>
        <Route path='/' exact component={MainPage}>
          <Redirect to='/events' />
        </Route>
        <Route path='/events' component={EventsRouter} />
        <Route path='/screens' component={Screens} />
        <Route path='/groups' component={UsersGroupsRouter} />
        <Route path='/voting/new' exact render={() => <VoteForm newPoll />} />
        <Route path='/voting' component={VotingRouter} />
      </Switch>
      <Notifications />
    </div>
  </>
)

export default OneSideBarRouter
