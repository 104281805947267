import React from 'react'
import { ErrorMessage, Form as FormikForm } from 'formik'

import SimpleInput from '../../UI/input/SimpleInput'
import TextAreaSimple from '../../UI/input/TextAreaSimple'
import ValidationMsg from '../../UI/ValidationMsg'
import RadioGroup from '../../UI/input/RadioGroup'

const GroupInfoForm = ({
  setFieldValue,
  setFieldTouched,
  values,
  radioOptions,
  errors,
  touched,
  disabled,
}) => (
  <div className='container__left_side__user_group_form'>
    <FormikForm>
      <SimpleInput
        name='title'
        label='Название*'
        placeholder='Введите название группы'
        value={values.title}
        onChange={setFieldValue}
        setFieldTouched={setFieldTouched}
        error={errors.title && touched.title}
        autofocus
        disabled={disabled}
      >
        <ErrorMessage component={ValidationMsg} name='title' />
      </SimpleInput>
      <TextAreaSimple
        name='description'
        label='Описание'
        placeholder='Введите описание группы'
        value={values.description}
        onChange={setFieldValue}
        setFieldTouched={setFieldTouched}
        style={{ height: '120px' }}
        autoSize={{ minRows: 4, maxRows: 14 }}
        error={errors.description && touched.description}
      >
        <ErrorMessage component={ValidationMsg} name='description' />
      </TextAreaSimple>
      <div className='user_group_form__radio_group'>
        <RadioGroup
          name='access'
          label='Права доступа'
          value={values.access}
          setFieldTouched={setFieldTouched}
          onChange={setFieldValue}
          disabled={disabled}
          options={radioOptions}
        />
      </div>
    </FormikForm>
  </div>
)

export default GroupInfoForm
