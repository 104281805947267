import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import * as _ from 'lodash'
import { message } from 'antd'

import MainCard from '../../MainCard'
import GroupInfoForm from './GroupInfoForm'
import UsersPickContainer from './UsersPickContainer'
import {
  GET_ACCESS_GROUP,
  GET_USERS,
  POST_GROUP,
  CLEAR_GROUP,
  UPDATE_GROUP,
} from '../../../actions/users'
import { getMaxLengthMsg } from '../../../utils/validation'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Название должно содержать минимум 2 символа')
    .max(50, field => getMaxLengthMsg(50, field))
    .required('Обязательное поле'),
  description: Yup.string()
    .max(250, field => getMaxLengthMsg(250, field)),
})

const translateAccessToRights = (access, withLongName) => {
  const extraName = withLongName ? 'can_' : ''
  const right = `global_${extraName}`
  return {
    [`${right}read`]: true,
    [`${right}write`]: (access === 'Глобальное редактирование' || access === 'Глобальный менеджмент'),
    [`${right}manage`]: access === 'Глобальный менеджмент',
  }
}

const translateRightsToAccess = rights => {
  if (rights?.global_manage) return 'Глобальный менеджмент'
  return rights?.global_write ? 'Глобальное редактирование' : 'Глобальное чтение'
}

class InnerForm extends React.Component {
  radioGroupData = [
    'Глобальное чтение',
    'Глобальное редактирование',
    'Глобальный менеджмент',
  ]

  componentDidMount() {
    const { getGroup, match, getUsersList, isNew } = this.props
    if (!isNew) getGroup(match.params.id)
    getUsersList()
  }

  componentWillUnmount() {
    this.props.clearGroup()
  }

  anyChangesInTheValues = usersList => {
    const { values, group } = this.props
    const { id, pre_defined, count, users, ...groupMainInfo } = group

    const valuesMainInfo = {
      title: values.title,
      description: values.description || '',
      ...translateAccessToRights(values.access, false),
    }
    if (!group.description) groupMainInfo.description = ''

    return {
      mainInfo: !_.isEqual(valuesMainInfo, groupMainInfo),
      usersInfo: !_.isEqual(usersList, group.users),
    }
  }

  packGroup = (changes, basicGroupInfo, usersIdArray) => {
    const { match, history } = this.props
    return ({
      id: match.params.id,
      mainInfo: changes.mainInfo ? basicGroupInfo : null,
      usersInfo: changes.usersInfo ? usersIdArray : null,
      callback: () => {
        history.push(`/groups/${match.params.id}`)
        message.success('Изменения сохранены')
      },
    })
  }

  getBasicGroupInfo = () => {
    const { access, ...valuesWithoutAccessField } = this.props.values
    return { ...valuesWithoutAccessField, ...translateAccessToRights(access, true) }
  }

  saveGroup = ({ usersList }) => {
    const { updateGroup, isNew, values, postNewGroup, history, setTouched } = this.props

    setTouched({ title: true, description: true })
    if (!ValidationSchema.isValidSync(values)) return

    if (isNew) {
      const usersObj = { ...this.getBasicGroupInfo(), users: usersList.map(u => u.id) }
      postNewGroup(usersObj, history)
      return
    }

    const changes = this.anyChangesInTheValues(usersList)
    const areValuesChanged = _.values(changes).some(item => Boolean(item))
    if (!areValuesChanged) {
      message.info('Изменений не обнаружено')
      return
    }

    const groupInfo = this.packGroup(changes, this.getBasicGroupInfo(), usersList.map(u => u.id))
    updateGroup(groupInfo)
  }


  render() {
    const { loading, values, setFieldValue, setFieldTouched, errors, touched, group } = this.props
    return (
      <>
        <MainCard
          title='Группа доступа'
          icon='icon__user'
          loading={loading}
          style={{ width: '100%' }}
        >
          <div className='flex_container full_height'>
            <GroupInfoForm
              values={values}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              radioOptions={this.radioGroupData}
              touched={touched}
              errors={errors}
              disabled={group?.pre_defined}
            />
            <UsersPickContainer onSave={this.saveGroup} />
          </div>
        </MainCard>
      </>
    )
  }
}

const GroupEditCard = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ group, isNew }) => isNew
    ? { title: '', description: '', access: 'Глобальное чтение' }
    : {
      title: group?.title,
      description: group?.description,
      access: translateRightsToAccess(group),
    },
  validationSchema: ValidationSchema,
  handleSubmit: () => null,
})(InnerForm)

const mapStateToProps = ({ users }) => ({
  usersLoading: users?.loading,
  group: users?.groups?.currentGroup,
  loading: users?.groups?.loading,
})

const mapDispatchToProps = dispatch => ({
  postNewGroup: (group, history) => dispatch({ type: POST_GROUP, group, history }),
  getGroup: id => dispatch({ type: GET_ACCESS_GROUP, id }),
  getUsersList: () => dispatch({ type: GET_USERS }),
  clearGroup: () => dispatch({ type: CLEAR_GROUP }),
  updateGroup: ({ id, mainInfo, usersInfo, callback }) =>
    dispatch({ type: UPDATE_GROUP, id, mainInfo, usersInfo, callback }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupEditCard))
