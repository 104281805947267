import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Auth from './components/Auth'
import ErrorPage from './components/ErrorPage/index'
import ERRORS from './utils/data/errors'
import { GET_ACCOUNT } from './actions/account'
import { GET_NOTIFICATIONS } from './actions/notifications'
import OneSideBarRouter from './OneSideBarRouter'
import NoSideBarRouter from './NoSidebarRouter'
import VotingResult from './components/Voting/VotingResult'

class App extends React.Component {
  componentDidMount() {
    if (window.location.pathname !== '/auth') {
      this.props.getAccount()
      this.props.getNotifications(1)
    }
  }

  render() {
    return (
      <Router>
        <div className='app_BG'>
          <Switch>
            <Route path='/voting/new' exact component={OneSideBarRouter} />
            <Route exact path='/voting/:id' component={VotingResult} />
            <Route exact path='/auth' component={Auth} />
            <Route exact path='/404' render={props => <ErrorPage {...props} error={ERRORS['404']} />} />
            <Route exact path='/500' render={props => <ErrorPage {...props} error={ERRORS['500']} />} />
            <Route path='/' exact component={OneSideBarRouter} />
            <Route path='/events' component={OneSideBarRouter} />
            <Route path='/screens' component={OneSideBarRouter} />
            <Route path='/users' component={NoSideBarRouter} />
            <Route exact path='/groups/new' component={NoSideBarRouter} />
            <Route exact path='/groups/:id/edit' component={NoSideBarRouter} />
            <Route path='/groups' component={OneSideBarRouter} />
            <Route path='/voting' component={OneSideBarRouter} />
            <Route render={props => <ErrorPage {...props} error={ERRORS['404']} />} />
          </Switch>
        </div>
      </Router>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getAccount: () => dispatch({ type: GET_ACCOUNT }),
  getNotifications: page => dispatch({ type: GET_NOTIFICATIONS, page }),
})

export default connect(null, mapDispatchToProps)(App)
