import React from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Form as FormikForm, withFormik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Logo from './helpers/Logo'
import SimpleInput from './UI/input/SimpleInput'
import Button from './UI/Button'
import { GET_ACCOUNT, LOG_IN_USER } from '../actions/account'
import PasswordInput from './UI/input/PasswordInput'
import ValidationMsg from './UI/ValidationMsg'
import { GET_NOTIFICATIONS } from '../actions/notifications'

const ValidationSchema = Yup.object().shape({
  username: Yup.string()
    .required('Обязательное поле'),
  password: Yup.string()
    .required('Обязательное поле'),
})

class InnerForm extends React.Component {
  logIn = async () => {
    const { values, setTouched, logInUser } = this.props
    setTouched({ username: true, password: true })

    if (!ValidationSchema.isValidSync(values)) return null
    await logInUser(values.username, values.password, this.succeedLogInCallback)
  }

  succeedLogInCallback = () => {
    const { getAccount, history, getNotifications } = this.props
    getAccount()
    getNotifications(1)
    history.push('/events')
  }

  getBasicInputProps = field => {
    const { setFieldValue, setFieldTouched, errors, touched } = this.props
    return ({
      onChange: setFieldValue,
      setFieldTouched,
      onEnter: this.logIn,
      error: errors[field] && touched[field],
      placeholder: `Введите ${field === 'username' ? 'логин' : 'пароль'}`,
    })
  }

  render() {
    const { values } = this.props
    return (
      <div className='app_BG'>
        <div className='flex_container__center'>
          <div className='card card__auth'>
            <Logo style={{ height: '35%', width: '80%', margin: '24px auto 27px' }} />
            <div className='flex_container full_height'>
              <FormikForm>
                <SimpleInput
                  name='username'
                  value={values.username}
                  label='Логин'
                  style={{ width: '100%' }}
                  {...this.getBasicInputProps('username')}
                >
                  <ErrorMessage component={ValidationMsg} name='username' />
                </SimpleInput>
                <PasswordInput
                  name='password'
                  value={values.password}
                  label='Пароль'
                  style={{ width: '100%' }}
                  {...this.getBasicInputProps('password')}
                >
                  <ErrorMessage component={ValidationMsg} name='password' />
                </PasswordInput>
                <div className='auth__btn'>
                  <Button styles='btn_solid margin_top' title='Войти' onClick={this.logIn} />
                </div>
              </FormikForm>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const Auth = withFormik({
  mapPropsToValues: () => ({ username: '', password: '' }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapDispatchToProps = dispatch => ({
  logInUser: (username, password, callback) =>
    dispatch({ type: LOG_IN_USER, username, password, callback }),
  getAccount: () => dispatch({ type: GET_ACCOUNT }),
  getNotifications: page => dispatch({ type: GET_NOTIFICATIONS, page }),
})

export default withRouter(connect(null, mapDispatchToProps)(Auth))
