import React from 'react'

const ChartTypeSwitch = ({ currentType, onTypeChange, types }) => {
  const getBtnStyle = chartTypeTitle => chartTypeTitle === currentType
    ? 'chart_type_switch__button__active'
    : 'chart_type_switch__button'

  const getIcon = chartType => chartType.title === currentType
    ? chartType.icon_active
    : chartType.icon

  const chartsType = [{
    title: 'pie',
    icon: 'icon__pie_chart',
    icon_active: 'icon__pie_chart__active',
  },
  {
    title: 'bar',
    icon: 'icon__bar_chart',
    icon_active: 'icon__bar_chart__active',
  },
  {
    title: 'horizontal_bar',
    icon: 'icon__horizontal_bar_chart',
    icon_active: 'icon__horizontal_bar_chart__active',
  }]

  return (
    <div className='chart_type_switch__block'>
      {chartsType.map(type => types.includes(type.title) && (
        <div
          className={getBtnStyle(type.title)}
          key={type.title}
          onClick={() => onTypeChange(type.title)}
        >
          <div className={getIcon(type)} />
        </div>
      ))}
    </div>
  )
}

export default ChartTypeSwitch
