import React from 'react'
import { connect } from 'react-redux'

import MainCard from '../../MainCard'
import Notifications from '../../Notifications/index'
import { GET_POLL } from '../../../actions/poll'
import Form from './Form'
import { GET_EVENTS_LIST } from '../../../actions/events'

class InnerForm extends React.Component {
  componentDidMount() {
    const { getEventsList, newPoll, getPoll, match } = this.props
    newPoll ? getEventsList() : getPoll(match.params.id, true)
  }

  render() {
    const { loading, newPoll } = this.props
    return (
      <>
        <MainCard icon='icon__voting' title='ГОЛОСОВАНИЕ' loading={loading}>
          <Form newPoll={newPoll} />
        </MainCard>
        <Notifications />
      </>
    )
  }
}

const mapStateToProps = ({ poll = {} }) => ({
  loading: poll.loading,
})

const mapDispatchToProps = dispatch => ({
  getEventsList: () => dispatch({ type: GET_EVENTS_LIST }),
  getPoll: (pollId, formatted) => dispatch({ type: GET_POLL, pollId, formatted }),
})

export default connect(mapStateToProps, mapDispatchToProps)(InnerForm)
