import React from 'react'
import { Bar } from 'react-chartjs-2'

import groupedBarChartOptions from './setups/groupedBarChartSetups'

const GroupedBarChart = ({ source }) => {
  const getMaxVal = () => {
    let maxVal = 0
    source?.datasets && source.datasets.forEach(item =>
      maxVal = (Math.max(...item.data) > maxVal) ? Math.max(...item.data) : maxVal)
    return maxVal + maxVal * 0.06
  }

  return (
    <div className='group_bar_chart__wrapper'>
      <div className='flex_container__flex_1 full_height'>
        <Bar data={source} options={groupedBarChartOptions(getMaxVal())} />
      </div>
    </div>
  )
}

export default GroupedBarChart
