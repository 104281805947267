import moment from 'moment'
import defaultSelect from '../data/defaultSelect'

const formPoll = ({ poll, events }) => {
  const { title, description, answers, lecture, event, date_to, date_from, id } = poll
  const getEventLink = () => {
    if (!event && !lecture) return defaultSelect
    if (!event && lecture) {
      const linkedEvent = events.list.find(e => e.id === lecture.event)
      return linkedEvent ? { id: linkedEvent.id, title: linkedEvent.title } : defaultSelect
    }
    return { id: event.id, title: event.title }
  }
  return {
    id: id,
    title: title,
    description: description,
    date_from: moment(date_from),
    date_to: date_to ? moment(date_to) : null,
    answers: answers?.map(a => a.text) || [],
    access: (event || lecture) ? 'Добавить к событию' : 'Видно всем',
    event: getEventLink(),
    lecture: lecture ? { id: lecture.id, title: lecture.title } : defaultSelect,
  }
}

export default formPoll
