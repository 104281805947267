import * as _ from 'lodash'
import { formatDateZeroSeconds } from '../formatDate'

export const packPoll = values => {
  const data = {
    title: values.title,
    description: values.description,
    answers: _.uniq(values.answers.filter(String)),
    date_from: formatDateZeroSeconds(values.dateFrom),
    date_to: values.dateTo ? formatDateZeroSeconds(values.dateTo) : null,
  }

  if (values.access !== 'Видно всем')
    values.lectureLink.id
      ? data.lecture = values.lectureLink.id
      : data.event = values.eventLink.id

  return data
}

export default packPoll
