export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_SUCCEED = 'GET_NOTIFICATIONS_SUCCEED'

export const POST_NOTIFICATION = 'POST_NOTIFICATION'
export const POST_NOTIFICATION_SUCCEED = 'POST_NOTIFICATION_SUCCEED'

export const SET_NOTIFICATIONS_LOADING = 'SET_NOTIFICATIONS_LOADING'

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCEED = 'DELETE_NOTIFICATION_SUCCEED'

export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
