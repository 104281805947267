export const SET_PRESENTERS_LOADING = 'SET_PRESENTERS_LOADING'

export const GET_PRESENTERS = 'GET_PRESENTERS'
export const GET_PRESENTERS_SUCCEED = 'GET_PRESENTERS_SUCCEED'

export const CLEAR_PRESENTS = 'CLEAR_PRESENTS'

export const PUT_PRESENTER = 'PUT_PRESENTER'
export const PUT_PRESENTER_SUCCEED = 'PUT_PRESENTER_SUCCEED'

export const POST_PRESENTER = 'POST_PRESENTER'
export const POST_PRESENTER_SUCCEED = 'POST_PRESENTER_SUCCEED'

export const DELETE_PRESENTER = 'DELETE_PRESENTER'
export const DELETE_PRESENTER_SUCCEED = 'DELETE_PRESENTER_SUCCEED'

export const ACTIVATE_PRESENTER = 'ACTIVATE_PRESENTER'
export const ACTIVATE_PRESENTER_SUCCEED = 'ACTIVATE_PRESENTER_SUCCEED'

export const START_PRESENTER_SESSION = 'START_PRESENTER_SESSION'

export const STOP_PRESENTER_SESSION = 'STOP_PRESENTER_SESSION'
export const STOP_PRESENTER_SESSION_SUCCEED = 'STOP_PRESENTER_SESSION_SUCCEED'

export const CHANGE_CHART_ON_PRESENTER = 'CHANGE_CHART_ON_PRESENTER'
