import React, { useState } from 'react'
import UserPickItem from './UserPickItem'
import ScrollTransition from '../../helpers/transition/ScrollTransition'
import ExpandingSearch from '../../UI/ExpandingSearch'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import LargeSpin from '../../UI/Spin/LargeSpin'

const UsersList = ({
  list,
  loading,
  name,
  onCardClick,
  onScroll,
  onSearchClose,
  search,
  searchString,
  setSearchString,
  title,
}) => {
  const [isSearchFieldExpanded, setIsSearchFieldExpanded] = useState(false)

  return (
    <div className='card_list__small'>
      <div className='card_list__small_container__title'>{title}</div>
      {search && (
        <div className='container__users_group_search'>
          <ExpandingSearch
            setIsExpanded={val => setIsSearchFieldExpanded(val)}
            setSearchString={setSearchString}
            searchString={searchString}
            isExpanded={isSearchFieldExpanded}
            onClose={onSearchClose}
            defaultExpanded
            large
            getAll
          />
        </div>
      )}
      {loading
        ? <LargeSpin />
        : (
          <ScrollTransition loading={!loading} onScroll={onScroll && onScroll}>
            <div className='card_list__small__wrapper'>
              {list?.length
                ? list.map(item => (
                  <UserPickItem
                    user={item}
                    key={item.id}
                    onClick={() => onCardClick(name, item)}
                    containerType={name}
                  />
                ))
                : <EmptyPlaceholder text='Список пуст' />}
            </div>
          </ScrollTransition>
        )}
    </div>
  )
}

export default UsersList
