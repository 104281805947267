import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Button from '../../UI/Button'
import EventsFilter from './EventsFilter'
import EventsList from './EventsList'
import filterEvents from '../../../utils/filters/filterEvents'
import MainCard from '../../MainCard'
import { GET_EVENTS_LIST, DELETE_EVENT } from '../../../actions/events'
import ConfirmationModal from '../../UI/modal/ConfirmationModal'

const defaultEventObj = { id: null, title: '' }

class List extends React.Component {
  state = {
    filter: 'all',
    filteredList: [],
    eventsList: [],
    deletedEvent: defaultEventObj,
    isConfirmShow: false,
  }

  componentDidMount() {
    this.props.getEventsList()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { eventsList } = nextProps
    return { eventsList, filteredList: filterEvents(eventsList, prevState.filter) }
  }

  setFilterTabStyle = radioItem => {
    const defaultClass = 'filter__tab'
    return (radioItem === this.state.filter) ? `${defaultClass}__active` : defaultClass
  }

  handleFilter = e => {
    const { value } = e.target
    if (value === this.state.filter) return
    this.setState(prevState => ({
      ...prevState,
      filter: value,
      filteredList: filterEvents(prevState.eventsList, value),
    }))
  }

  eventsFilterBlock = () => (
    <EventsFilter
      setStyle={this.setFilterTabStyle}
      onFilterTabChange={this.handleFilter}
      name='event-filter'
    />
  )

  createButtonBlock = () => (
    <Button
      styles='bordered_btn'
      icon='icon__add_lecture__rounded'
      title='Добавить событие'
      onClick={() => this.props.history.push('events/new')}
    />
  )

  hideConfirmation = () => this.setState({ isConfirmShow: false, deletedEvent: defaultEventObj })

  showEventDeleteConfirm = event => {
    this.setState({
      isConfirmShow: true,
      deletedEvent: { id: event.id, title: event.title },
    })
  }

  confirmEventDeletion = () => {
    this.props.deleteEvent(this.state.deletedEvent.id)
    this.hideConfirmation()
  }

  render() {
    const { filteredList, deletedEvent, isConfirmShow } = this.state
    const { loading } = this.props

    return (
      <>
        <MainCard
          icon='icon__big_calendar'
          title='СОБЫТИЯ'
          filter={this.eventsFilterBlock()}
          btn={this.createButtonBlock()}
          loading={loading}
        >
          <EventsList
            list={filteredList}
            onEventDelete={event => this.showEventDeleteConfirm(event)}
            loading={loading}
          />
        </MainCard>
        <ConfirmationModal
          id={deletedEvent.id}
          start={isConfirmShow}
          text={`Удалить событие "${deletedEvent.title}"?`}
          onConfirm={this.confirmEventDeletion}
          onClose={this.hideConfirmation}
        />
      </>
    )
  }
}


const mapStateToProps = ({ events }) => ({
  loading: events.loading || false,
  eventsList: events.list || [],
})

const mapDispatchToProps = dispatch => ({
  getEventsList: () => dispatch({ type: GET_EVENTS_LIST }),
  deleteEvent: eventId => dispatch({ type: DELETE_EVENT, eventId }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List))
