import React from 'react'
import { Timeline, Tooltip } from 'antd'
import { dateToShortString } from '../../utils/formatDate'

const TimeLineItem = ({ notification, onDelete }) => {
  const getMarkerStyle = () => {
    const basic = 'timeline_marker'
    return notification?.type === 'system' ? `${basic}__yellow` : `${basic}__green`
  }

  let debounce = null

  const delayedExecution = () => {
    if (debounce) clearTimeout(debounce)
    debounce = setTimeout(() => onDelete(notification), 300)
  }

  return (
    <Timeline.Item className={getMarkerStyle()} id={notification.id}>
      <div className='notification__text'>{notification?.text}</div>
      <div className='notification__subtitle_block'>
        {notification.type === 'system' && (
          <Tooltip title='Удалить уведомление'>
            <div
              onClick={delayedExecution}
              className='icon__wrapper__hover_enabled'
              style={{ marginBottom: '2px' }}
            >
              <div className='icon__delete' />
            </div>
          </Tooltip>
        )}
        <span className='notification__time_stamp'>
          {dateToShortString(notification.dateNotify)}
        </span>
      </div>
    </Timeline.Item>
  )
}

export default TimeLineItem
