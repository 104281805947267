import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import Logo from '../helpers/Logo'
import UserInfo from './UserInfo'
import { NAVIGATION } from '../../utils/data/navigation'

class Header extends Component {
  renderNavigation = () => (
    NAVIGATION.map(item => (
      <NavLink
        to={item.address}
        key={item.title}
        className='nav_link__inactive'
        activeClassName='nav_link__active'
      >
        {item.title}
      </NavLink>
    ))
  )

  render() {
    return (
      <div className='header'>
        <div className='logo_placeholder' onClick={() => this.props.history.push('/')}>
          <Logo />
        </div>
        <nav className='nav_block'>{this.renderNavigation()}</nav>
        <UserInfo />
      </div>
    )
  }
}

export default withRouter(Header)
