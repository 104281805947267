import React from 'react'
import { ErrorMessage, Form as FormikForm, withFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'

import Button from '../../UI/Button'
import SimpleInput from '../../UI/input/SimpleInput'
import ValidationMsg from '../../UI/ValidationMsg'
import { getMaxLengthMsg } from '../../../utils/validation'
import { POST_PRESENTER } from '../../../actions/presenters'
import { setDefaultCursorStyle } from '../../../utils/cursorStyleSwitch'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Обязательное поле')
    .min(6, 'Название не менее 6 символов')
    .max(120, field => getMaxLengthMsg(120, field)),
  description: Yup.string()
    .max(140, field => getMaxLengthMsg(140, field)),
})

const InnerForm = ({
  values,
  setFieldValue,
  setFieldTouched,
  resetForm,
  onCancel,
  setTouched,
  postPresenter,
  touched,
  errors,
}) => {
  const savePresenter = async () => {
    await setTouched({ title: true, description: true })

    if (!ValidationSchema.isValidSync(values)) {
      setDefaultCursorStyle()
      return null
    }

    const updatedPresenter = {
      title: values.title.trim().replace(/ +(?= )/g, ''),
      description: values.description,
    }

    postPresenter(updatedPresenter, resetForm)
  }

  return (
    <FormikForm>
      <SimpleInput
        name='title'
        label='Название экрана*'
        value={values.title}
        style={{ width: '100%' }}
        placeholder='Введите название экрана'
        onChange={setFieldValue}
        setFieldTouched={setFieldTouched}
        error={touched.title && errors.title}
      >
        <ErrorMessage component={ValidationMsg} name='title' />
      </SimpleInput>
      <SimpleInput
        name='description'
        label='Описание'
        value={values.description}
        style={{ width: '100%' }}
        placeholder='Введите описание экрана'
        onChange={setFieldValue}
        setFieldTouched={setFieldTouched}
        error={touched.description && errors.description}
      >
        <ErrorMessage component={ValidationMsg} name='description' />
      </SimpleInput>
      <div
        className='button_container__content_right'
        style={{ maxHeight: 'unset', overflow: 'visible' }}
      >
        <Button styles='simple_btn' title='Скрыть' onClick={onCancel} />
        <Button
          delay={700}
          styles='rounded_btn__save_event'
          title='Создать экран'
          onClick={savePresenter}
        />
      </div>
    </FormikForm>
  )
}

const NewPresenterForm = withFormik({
  enableReinitialize: false,
  mapPropsToValues: () => ({ title: '', description: '' }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapDispatchToProps = dispatch => ({
  postPresenter: (presenter, callback) => dispatch({ type: POST_PRESENTER, presenter, callback }),
})

export default connect(null, mapDispatchToProps)(NewPresenterForm)
