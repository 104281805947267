import React, { useEffect, useCallback, useRef } from 'react'
import PopUpTransition from '../../helpers/transition/PopUptransition'

const HANDLED_PARENTS = [
  '.ant-calendar',
  '.ant-select-dropdown',
  '.ant-time-picker-panel-inner',
  '.ant-calendar-year-panel',
  '.ant-calendar-month-panel',
  '.ant-calendar-decade-panel',
  '.anticon',
]

const Modal = ({ children, onClose, start, style, extraClassName }) => {
  const wrapperRef = useRef(null)
  const handleKeyDown = useCallback(e => e.keyCode === 27 && onClose(), [onClose])

  const isClickOnNestedElements = target => {
    const isClickOnCalendar = HANDLED_PARENTS.map(parent => Boolean(target?.closest(parent)))
    return isClickOnCalendar.some(parent => parent)
  }

  const handleClickOutside = ({ target }) => {
    const { current } = wrapperRef

    if (target.tagName === 'svg' || target.tagName === 'path') return
    if (isClickOnNestedElements(target)) return
    current && !current?.contains(target) && onClose()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    document.addEventListener('mousedown', handleClickOutside, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleKeyDown])

  return (
    <PopUpTransition loading={start}>
      <div className='modal_bg' onKeyDown={handleKeyDown}>
        <div
          className={`modal_card ${extraClassName ? (' ' + extraClassName) : ''}`}
          ref={wrapperRef}
          style={style}
        >
          <div className='icon__x' onClick={onClose} />
          {children}
        </div>
      </div>
    </PopUpTransition>
  )
}

export default Modal
