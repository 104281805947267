import React from 'react'
import { connect } from 'react-redux'
import { Spin, Checkbox } from 'antd'

import NewUserForm from './forms/NewUser'
import LargeSpin from '../UI/Spin/LargeSpin'
import UserList from './UserList'
import MainCard from '../MainCard'
import FadeTransition from '../helpers/transition/FadeTransition'
import ScrollTransition from '../helpers/transition/ScrollTransition'
import { CLEAR_FILES } from '../../actions/files'
import { GET_USERS, DELETE_USER } from '../../actions/users'
import { ITEMS_ON_PAGE } from '../../utils/data/constants'
import ConfirmationModal from '../UI/modal/ConfirmationModal'
import ExpandingSearch from '../UI/ExpandingSearch'

export const DEFAULT_USER = { id: null, name: '' }

class UserCard extends React.Component {
  state = {
    page: 1,
    filteredList: [],
    usersList: [],
    isConfirmShow: false,
    isSearchFieldExpanded: false,
    searchString: '',
    selectedUser: DEFAULT_USER,
    isInactiveUsersVisible: false,
  }

  componentDidMount() {
    this.props.getUsers(1)
  }

  componentWillUnmount() {
    this.props.clearPhoto()
  }

  static getDerivedStateFromProps(nextProps) {
    return { usersList: nextProps.users }
  }

  loadFilteredUsers = () => {
    const { page, searchString } = this.state
    this.props.getUsers(page, this.getFilterStringForResponse(), searchString)
  }

  toggleInactiveUsersVisibility = () => this.setState(prevState => ({
    isInactiveUsersVisible: !prevState.isInactiveUsersVisible,
    page: 1,
  }), this.loadFilteredUsers)

  getFilterStringForResponse = () => this.state.isInactiveUsersVisible ? 'true' : false

  setActiveUser = user => {
    const newActiveUser = { id: user?.id, name: user?.name }
    this.setState({ activeUser: newActiveUser })
  }

  removeUser = id => {
    this.props.deleteUser(id)
    this.setState({ isConfirmShow: false, selectedUser: DEFAULT_USER })
  }

  onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    const { loading, count } = this.props
    const isAllFetched = count <= (this.state.page * ITEMS_ON_PAGE)

    if (loading || isAllFetched) return null

    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 1)
    if (isScrollAtTheBottom)
      this.setState(prevState => ({ page: prevState.page + 1 }), this.loadFilteredUsers)
  }

  showUserDeletionConfirmation = ({ id, name }) => this.setState({
    isConfirmShow: true,
    selectedUser: { id, name },
  })

  closeUserDeletionConfirm = () => this.setState({
    isConfirmShow: false,
    selectedUser: DEFAULT_USER,
  })

  render() {
    const {
      usersList,
      page,
      isConfirmShow,
      isSearchFieldExpanded,
      selectedUser,
      isInactiveUsersVisible,
    } = this.state
    const { loading } = this.props

    const isFirstLoading = page === 1
    const newLoading = loading && isFirstLoading

    return (
      <>
        <MainCard title='ВСЕ ПОЛЬЗОВАТЕЛИ' icon='icon__user' style={{ width: '100%' }}>
          <div className='flex_container full_height'>
            <NewUserForm />
            <div className='container__right_side'>
              <FadeTransition loading style={{ marginBottom: '12px' }}>
                <div className='flex_container flex_container__space_between'>
                  <ExpandingSearch
                    filter={this.getFilterStringForResponse()}
                    setIsExpanded={val => this.setState({ isSearchFieldExpanded: val })}
                    setSearchString={val => this.setState({ searchString: val, page: 1 })}
                    searchString={this.state.searchString}
                    extraStyle={{ flex: 2 }}
                    isExpanded={isSearchFieldExpanded}
                    defaultExpanded
                    large
                    autofocus
                  />
                  <div className='flex_container__align_items__center flex_container__flex_1'>
                    <Checkbox
                      checked={isInactiveUsersVisible}
                      onChange={this.toggleInactiveUsersVisibility}
                    >
                      Неактивные аккаунты
                    </Checkbox>
                  </div>
                </div>
              </FadeTransition>
              {(loading && page === 1)
                ? <LargeSpin />
                : (
                  <ScrollTransition onScroll={this.onListScroll} loading={!newLoading}>
                    <div className='flex_container__column flex_container__center'>
                      <UserList
                        list={usersList}
                        onDelete={this.showUserDeletionConfirmation}
                        onItemClick={this.setActiveUser}
                      />
                      {loading && !isFirstLoading
                        && <div style={{ marginTop: '10px' }}><Spin /></div>}
                    </div>
                  </ScrollTransition>
                )}
            </div>
          </div>
        </MainCard>
        <ConfirmationModal
          id={selectedUser?.id}
          start={isConfirmShow}
          text={`Удалить пользователя ${selectedUser.name}?`}
          onConfirm={() => this.removeUser(selectedUser?.id)}
          onClose={this.closeUserDeletionConfirm}
        />
      </>
    )
  }
}

const mapStateToProps = ({ users }) => ({
  users: users?.list,
  loading: users?.loading,
  count: users?.count,
})

const mapDispatchToProps = dispatch => ({
  clearPhoto: () => dispatch({ type: CLEAR_FILES }),
  deleteUser: (userId, callback) => dispatch({ type: DELETE_USER, userId, callback }),
  getUsers: (page, filter, searchString) =>
    dispatch({ type: GET_USERS, page, filter, searchString }),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCard)
