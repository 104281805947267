import React from 'react'
import filesize from 'filesize'
import { connect } from 'react-redux'
import { Field as FormikField } from 'formik'
import { Form, Tooltip } from 'antd'

import FadeTransition from '../../../helpers/transition/FadeTransition'
import UploadedFilesList from './UploadedFileList'
import { isFileValid, validFileFormats } from './fileRestrictions'
import {
  ADD_FILE_TO_NEW_LECTURE,
  DELETE_FILE,
  DELETE_FILE_FROM_NEW_LECTURE,
  POST_FILE,
} from '../../../../actions/files'

class SimpleFileUploader extends React.Component {
  // state = { files: this.props.papers }

  // static getDerivedStateFromProps = (nextProps, prevState) => {
  //   if (nextProps.papers !== prevState.files && nextProps.papers !== undefined)
  //     return { files: nextProps.papers }
  //   if (nextProps.papers === undefined || !nextProps.papers.length) return { files: [] }
  //   return { files: prevState.files }
  // }

  packFile = file => {
    const data = new FormData()
    data.append('file', file)
    data.append('title', file.name)
    data.append('size', filesize(file.size, { round: 0 }))

    return data
  }

  onUploadChange = ({ target }) => {
    const { addFileToNewLecture, isNew, lectureId, newObject, postFile } = this.props

    Array.prototype.forEach.call(target.files, file => {
      if (!isFileValid(file)) return
      const data = this.packFile(file)
      isNew ? addFileToNewLecture(data) : postFile(data, lectureId, newObject)
    })
  }

  onFileDelete = (fileId, lectureId) => {
    const { isNew, deleteFileFromNewLecture, deleteFile, newObject } = this.props
    isNew ? deleteFileFromNewLecture(fileId) : deleteFile(fileId, lectureId, newObject)
  }

  renderHelpIcon = () => {
    const iconStyle = { position: 'absolute', top: '-7px', left: '76px', width: '16px', height: '16px' }

    return (
      <Tooltip
        placement='topLeft'
        arrowPointAtCenter
        title={`${validFileFormats.map(i => `.${i}`).join(', ')} файлы не более 15 MB`}
        overlayStyle={{ whiteSpace: 'pre-wrap', minWidth: '220px' }}
      >
        <div className='icon__info' style={iconStyle} />
      </Tooltip>
    )
  }

  renderLabel = () => (
    <div style={{ position: 'relative', width: '100px', height: '16px' }} className='label'>
      {this.props.label}
      {this.renderHelpIcon()}
    </div>
  )

  getFullPapersArr = () => {
    const oldPapers = this.props.papers?.length > 0 ? this.props.papers : []
    const newPapers = this.props.newPapers?.length > 0 ? this.props.newPapers : []
    return [...oldPapers, ...newPapers]
  }

  render() {
    const { lectureId } = this.props
    return (
      <Form.Item>
        <FormikField
          render={() => (
            <>
              {this.renderLabel()}
              <input
                type='file'
                accept={validFileFormats.map(f => `.${f}`).join(', ')}
                ref={ref => this.upload = ref}
                className='input__file_uploader'
                onChange={this.onUploadChange}
                onClick={e => e.target.value = null}
                multiple
              />
              <div className='input__upload_container'>
                <span className='input__upload_placeholder'>Файл не более 15 MB</span>
                <div className='input__upload_button' onClick={e => this.upload.click()}>
                  Выбрать
                </div>
              </div>
              {this.getFullPapersArr().map((f, index) => (
                <FadeTransition loading={Boolean(f.id)} key={f.id}>
                  <UploadedFilesList
                    icons
                    file={f}
                    key={f.id || index}
                    lectureId={lectureId}
                    onDelete={this.onFileDelete}
                  />
                </FadeTransition>
              ))}
            </>
          )}
        />
      </Form.Item>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  postFile: (file, lectureId, newObject) =>
    dispatch({ type: POST_FILE, file, lectureId, newObject }),
  deleteFile: (fileId, lectureId, newObject) =>
    dispatch({ type: DELETE_FILE, fileId, lectureId, newObject }),
  addFileToNewLecture: file => dispatch({ type: ADD_FILE_TO_NEW_LECTURE, file }),
  deleteFileFromNewLecture: fileId => dispatch({ type: DELETE_FILE_FROM_NEW_LECTURE, fileId }),
})

export default connect(null, mapDispatchToProps)(SimpleFileUploader)
