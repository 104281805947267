import React from 'react'

import UserInGroupItem from './UserInGroupItem'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import Button from '../../UI/Button'
import ScrollTransition from '../../helpers/transition/ScrollTransition'

const UsersInGroupList = ({ users, onButtonClick }) => (
  <div className='container__right_side'>
    <ScrollTransition loading style={{ marginBottom: '10px' }}>
      <div className='card_list full_height'>
        {users?.length
          ? users.map((user, index) => <UserInGroupItem key={user.id} user={user} index={index} />)
          : <EmptyPlaceholder text='В группе нет пользователей' />}
      </div>
    </ScrollTransition>
    <div className='button_container__edit_card'>
      <Button
        icon='icon__edit icon__wrapper'
        styles='bordered_btn'
        title='Редактировать'
        onClick={onButtonClick}
      />
    </div>
  </div>
)

export default UsersInGroupList
