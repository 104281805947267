import React from 'react'
import { ErrorMessage } from 'formik'

import UnderlinedTitle from '../../UI/UnderlinedTitle'
import FadeTransition from '../../helpers/transition/FadeTransition'
import TextAreaSimple from '../../UI/input/TextAreaSimple'
import ValidationMsg from '../../UI/ValidationMsg'
import MultiInput from '../../UI/input/MultiInput'

const LeftSideVoteForm = ({
  loading,
  values,
  setFieldTouched,
  setFieldValue,
  shouldDisableAnswersEditing,
  errors,
  touched,
}) => {
  const isErrorField = field => errors[field] && touched[field]
  return (
    <div className='container_form__left' style={{ overflow: 'hidden' }}>
      <UnderlinedTitle title='ОПРОС' loading={loading} />
      <FadeTransition loading={!loading}>
        <div>
          <TextAreaSimple
            name='title'
            label='Заголовок опроса*'
            value={values.title}
            style={{ height: '70px' }}
            setFieldTouched={setFieldTouched}
            onChange={setFieldValue}
            placeholder='Введите заголовок опроса'
            error={isErrorField('title')}
          >
            <ErrorMessage component={ValidationMsg} name='title' />
          </TextAreaSimple>
          <TextAreaSimple
            name='description'
            label='Текст опроса'
            value={values.description}
            style={{ height: '70px' }}
            setFieldTouched={setFieldTouched}
            onChange={setFieldValue}
            placeholder='Введите текст опроса'
            error={isErrorField('description')}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </TextAreaSimple>
          <MultiInput
            name='answers'
            label='Варианты ответа*'
            value={values.answers || []}
            onChange={setFieldValue}
            errors={errors.answers}
            touched={touched}
            disable={shouldDisableAnswersEditing}
          >
            <ErrorMessage component={ValidationMsg} name='answers' />
          </MultiInput>
        </div>
      </FadeTransition>
    </div>
  )
}

export default LeftSideVoteForm
