import moment from 'moment'

export const TODAY = moment.utc()

export const formatDateFromTwoFields = (date = TODAY, time = TODAY) =>
  `${date.format('YYYY-MM-DD')}T${time.format('HH:mm:00Z')}`

export const formatDate = (date = moment()) => `${date.format('YYYY-MM-DDTHH:mm:ssZ')}`

export const dateToString = date => `${moment(date).format('DD MMM YYYY  HH:mm')}`

export const dateToShortString = date => `${moment(date).format('HH:mm DD MMM')}`

export const formatDateZeroMinutes = (date = moment()) => `${date.format('YYYY-MM-DDTHH:00:00Z')}`
export const formatDateZeroSeconds = (date = moment()) => `${date.format('YYYY-MM-DDTHH:mm:00Z')}`

export const dateToStringNoHour = date => `${moment(date).format('DD MMM YYYY')}`

export const dateToStringDayOfWeek = date => `${moment(date).format('dd,DD MMM HH:mm')}`
