import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { ErrorMessage, Form as FormikForm, withFormik } from 'formik'
import { message } from 'antd'

import Button from '../../UI/Button'
import SimpleInput from '../../UI/input/SimpleInput'
import ValidationMsg from '../../UI/ValidationMsg'
import { setDefaultCursorStyle } from '../../../utils/cursorStyleSwitch'
import { getMaxLengthMsg } from '../../../utils/validation'
import { PUT_PRESENTER } from '../../../actions/presenters'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Обязательное поле')
    .min(6, 'Название не менее 6 символов')
    .max(120, field => getMaxLengthMsg(120, field)),
  description: Yup.string()
    .max(140, field => getMaxLengthMsg(140, field)),
})

class InnerForm extends React.Component {
  isValuesTheSame = () => {
    const { editMode, values, tableInfo } = this.props
    return Boolean(editMode
      && (values.title === tableInfo.title)
      && (values.description === tableInfo.description))
  }

  savePresenter = async () => {
    const { values, setTouched, presenter, onCancel, putPresenter } = this.props

    await setTouched({ title: true, description: true })

    if (this.isValuesTheSame()) {
      message.info('Изменений не обнаружено')
      setDefaultCursorStyle()
      return null
    }

    if (!ValidationSchema.isValidSync(values)) {
      setDefaultCursorStyle()
      return null
    }

    const updatedPresenter = {
      id: presenter.id,
      title: values.title.trim().replace(/ +(?= )/g, ''),
      description: values.description,
    }

    putPresenter(updatedPresenter, onCancel)
  }

  render() {
    const { values, setFieldValue, setFieldTouched, onCancel, errors, touched } = this.props
    return (
      <div className='form_table__block' style={{ marginBottom: '20px', marginRight: '12px' }}>
        <FormikForm>
          <div className='flex_container__row flex_container__space_between'>
            <div className='flex_container__flex_1'>
              <SimpleInput
                name='title'
                label='Название экрана*'
                value={values.title}
                style={{ width: '100%', marginRight: '20px' }}
                placeholder='Введите название экрана'
                onChange={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={touched.title && errors.title}
              >
                <ErrorMessage component={ValidationMsg} name='title' />
              </SimpleInput>
            </div>
            <div className='flex_container__flex_1'>
              <SimpleInput
                name='description'
                label='Описание'
                value={values.description}
                style={{ width: '100%' }}
                placeholder='Введите описание экрана'
                onChange={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={touched.description && errors.description}
              >
                <ErrorMessage component={ValidationMsg} name='description' />
              </SimpleInput>
            </div>
          </div>

          <div className='flex_container__row' style={{ marginTop: '10px' }}>
            <div className='button_container__content_right'>
              <Button styles='simple_btn' title='Отмена' onClick={onCancel} />
              <Button
                delay={700}
                styles='bordered_btn'
                title='Сохранить экран'
                onClick={this.savePresenter}
              />
            </div>
          </div>
        </FormikForm>
      </div>
    )
  }
}

const EditPresenter = withFormik({
  enableReinitialize: false,
  mapPropsToValues: ({ presenter }) => ({
    description: presenter?.description || '',
    title: presenter?.title || '',
  }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapDispatchToProps = dispatch => ({
  putPresenter: (presenter, callback) => dispatch({ type: PUT_PRESENTER, presenter, callback }),
})

export default connect(null, mapDispatchToProps)(EditPresenter)
