import React from 'react'
import { ErrorMessage } from 'formik'
import moment from 'moment'

import DateTimePick from '../../../UI/input/DateTimePick'
import SimpleInput from '../../../UI/input/SimpleInput'
import TagPicker from '../../../UI/input/TagPicker'
import TextAreaSimple from '../../../UI/input/TextAreaSimple'
import ValidationMsg from '../../../UI/ValidationMsg'
import TagsSelect from '../../../UI/input/TagsSelect'
import ScrollTransition from '../../../helpers/transition/ScrollTransition'

const EventForm = ({ values, setFieldTouched, setFieldValue, groups, getErrorCondition }) => {
  const basicFieldProps = { onChange: setFieldValue, setFieldTouched: setFieldTouched }
  return (
    <ScrollTransition loading>
      <div style={{ marginRight: '15px', padding: '0 2px' }}>
        <TextAreaSimple
          name='title'
          label='Название*'
          value={values.title}
          style={{ height: '50px' }}
          placeholder='Введите название события'
          error={getErrorCondition('title')}
          {...basicFieldProps}
        >
          <ErrorMessage component={ValidationMsg} name='title' />
        </TextAreaSimple>
        <TextAreaSimple
          name='description'
          label='Описание*'
          value={values.description}
          style={{ height: '90px' }}
          error={getErrorCondition('description')}
          {...basicFieldProps}
        >
          <ErrorMessage component={ValidationMsg} name='description' />
        </TextAreaSimple>
        <TagPicker
          fieldName='tags'
          label='Тематические теги'
          tags={values.tags}
          onConfirm={setFieldValue}
        />
        <DateTimePick
          label='Время начала'
          dateValue={values.startDate || moment()}
          timeValue={values.startTime || moment()}
          dateFieldName='startDate'
          timeFieldName='startTime'
          onBlur={setFieldTouched}
          {...basicFieldProps}
        />
        <SimpleInput
          name='place'
          label='Офис/Кабинет'
          value={values.place}
          style={{ width: '100%' }}
          placeholder='Место проведения'
          error={getErrorCondition('place')}
          {...basicFieldProps}
        >
          <ErrorMessage component={ValidationMsg} name='place' />
        </SimpleInput>
        <TagsSelect
          name='group_whitelist_read'
          label='Могут просматривать только'
          value={values.group_whitelist_read}
          source={groups || []}
          {...basicFieldProps}
        />
      </div>
    </ScrollTransition>
  )
}

export default EventForm
