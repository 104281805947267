import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FieldArray } from 'formik'
import { connect } from 'react-redux'

import Button from '../../../UI/Button'
import LecturesList from './LecturesList'
import { DELETE_LECTURE } from '../../../../actions/lectures'
import ConfirmationModal from '../../../UI/modal/ConfirmationModal'
import EditLectureModal from './EditLectureModal'

const LecturesFormBlock = ({ lectures, onSave, eventId, newObject, deleteLecture, history }) => {
  const [activeItem, setActiveItem] = useState(null)
  const [isNewLecture, setIsNewLecture] = useState(false)
  const [deletedLecture, setDeletedLecture] = useState(null)

  const createNewLecture = () => {
    setIsNewLecture(true)
    setActiveItem(null)
  }

  const addLectureBtnProps = {
    styles: 'bordered_btn padding-top',
    style: { marginBottom: '20px' },
    icon: 'icon__add_lecture__rounded',
    title: 'Добавить доклад',
    onClick: createNewLecture,
  }

  const saveEventBtnProps = {
    styles: 'bordered_btn',
    title: 'Сохранить событие',
    onClick: onSave,
    delay: 700,
  }

  const cancelBtnProps = () => ({
    styles: 'simple_btn',
    title: 'Отмена',
    onClick: () => history.goBack(),
  })

  const getItemActivityClass = item => `lectures_list__item${item === activeItem ? '__active' : ''}`

  const findActiveItem = () => activeItem ? lectures.find(l => l.id === activeItem) : {}

  const setActiveLecture = lectureId => {
    setActiveItem(lectureId)
    setIsNewLecture(false)
  }

  const removeLecture = id => {
    if (id === activeItem) setActiveLecture(null)
    deleteLecture(id)
    setDeletedLecture(null)
  }

  const setActiveLectureItem = lectureId => {
    if (lectureId === activeItem) setActiveItem(null)
    else setActiveItem(lectureId)
    setIsNewLecture(false)
  }

  return (
    <>
      <FieldArray
        name='lectures'
        render={() => (
          <>
            <div className='flex_container__column flex_container__flex_1 max_min_height__setup'>
              <Button {...addLectureBtnProps} />
              <LecturesList
                newObject={newObject}
                getItemActivityClass={getItemActivityClass}
                setActiveLecture={setActiveLectureItem}
                onIconClick={({ id, title }) => setDeletedLecture({ id, title })}
              />
              {(activeItem || isNewLecture) && (
                <EditLectureModal
                  start={(activeItem || isNewLecture)}
                  eventId={eventId}
                  isNew={isNewLecture}
                  newObject={newObject}
                  activeItem={activeItem}
                  lecture={findActiveItem()}
                  setActiveLecture={setActiveLecture}
                  onSave={onSave}
                />
              )}
            </div>
            <div className='button_container__edit_card'>
              <Button {...cancelBtnProps()} />
              <Button {...saveEventBtnProps} />
            </div>
          </>
        )}
      />
      <ConfirmationModal
        start={Boolean(deletedLecture)}
        onClose={() => setDeletedLecture(null)}
        onConfirm={() => removeLecture(deletedLecture?.id)}
        text={`Удалить лекцию "${deletedLecture?.title}"`}
      />
    </>
  )
}

const mapStateToProps = ({ events }, { newObject }) => {
  let lectures
  if (newObject) lectures = events.newEvent?.lectures || []
  else lectures = events.event?.lectures || []
  return { lectures }
}

const mapDispatchToProps = dispatch => ({
  deleteLecture: lectureId => dispatch({ type: DELETE_LECTURE, lectureId }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LecturesFormBlock))
