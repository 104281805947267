import React from 'react'
import { Route, Switch } from 'react-router-dom'

import VoteForm from './editing/index'
import List from './index'

const VotingRouter = () => (
  <Switch>
    <Route path='/voting' exact component={List} />
    <Route path='/voting/:id/edit' exact component={VoteForm} />
  </Switch>
)

export default VotingRouter
