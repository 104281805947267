import React, { useState } from 'react'

import LecturesListItem from '../../LecturesListItem'
import AnimatedHeight from '../../../../UI/AnimatedHeight'
import LectureCard from '../LectureCard'

const LectureListBlock = ({ lecture, index }) => {
  const [isOpen, setIsOpen] = useState(false)

  const getItemActivityClass = () => {
    const defaultClass = 'lectures_list__item'
    return isOpen ? `${defaultClass}__active` : defaultClass
  }

  return (
    <>
      <LecturesListItem
        lecture={lecture}
        icon='icon__expand'
        number={index}
        key={'item ' + lecture.id}
        getItemActivityClass={getItemActivityClass}
        setActiveLecture={() => setIsOpen(!isOpen)}
      />
      <AnimatedHeight condition={isOpen} key={'animated ' + lecture.id} maxHeight={3000}>
        <LectureCard lecture={lecture} />
      </AnimatedHeight>
    </>
  )
}

export default LectureListBlock
