import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import ActivateModal from './ActivateModal'
import MainCard from '../MainCard'
import PresentersList from './PresentersList'
import {
  GET_PRESENTERS,
  CLEAR_PRESENTS,
  ACTIVATE_PRESENTER,
  STOP_PRESENTER_SESSION, DELETE_PRESENTER,
} from '../../actions/presenters'
import { ITEMS_ON_PAGE } from '../../utils/data/constants'
import ConfirmationModal from '../UI/modal/ConfirmationModal'
import UnderlinedTitle from '../UI/UnderlinedTitle'
import NewPresenterForm from './forms/NewPresenter'
import LeftScrollTransition from '../helpers/transition/LeftSlideTransition'

class Screens extends React.Component {
  state = {
    page: 1,
    isActivateModalShow: false,
    isConfirmShow: false,
    selectedPresenter: null,
    isStopConfirmShow: false,
    isPresenterFormVisible: false,
  }

  statuses = ['not_activated', 'waiting', 'activated']

  componentDidMount() {
    this.props.getPresenters(this.state.page)
  }

  componentWillUnmount() {
    this.props.clearPresenters()
  }

  onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    const { loading, count, getPresenters } = this.props
    const isAllFetched = count <= (this.state.page * ITEMS_ON_PAGE)

    if (loading || isAllFetched) return null

    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 1)
    if (isScrollAtTheBottom)
      this.setState(prevState => ({ page: prevState.page + 1 }),
        () => getPresenters(this.state.page))
  }

  onActivateBtnClick = (presenter = null) =>
    presenter === null
      ? this.setState({ isActivateModalShow: false, selectedPresenter: null })
      : this.setState({ isActivateModalShow: true, selectedPresenter: presenter })

  onDeletionPopUpClose = () => this.setState({ isConfirmShow: false, selectedPresenter: null })

  confirmActivate = () => {
    const numberOfElementsInStatusesArr = this.statuses.length - 1

    const currentStatusIndex = this.statuses.indexOf(this.state.selectedPresenter?.status)

    const nextStatus = currentStatusIndex < numberOfElementsInStatusesArr
      ? this.statuses[currentStatusIndex + 1]
      : this.statuses[(currentStatusIndex % numberOfElementsInStatusesArr)]

    this.setState(prevState => ({
      selectedPresenter: { ...prevState.selectedPresenter, status: nextStatus },
    }), () => this.props.activatePresenter(this.state.selectedPresenter.id))
  }

  onPresenterDelete = presenter =>
    this.setState({ isConfirmShow: true, selectedPresenter: presenter })

  onPresenterStop = presenter =>
    this.setState({ isStopConfirmShow: true, selectedPresenter: presenter })

  onPresenterStopConfirm = () => {
    this.setState({ isStopConfirmShow: false })
    this.props.stopPresenterSession(this.state.selectedPresenter.id)
  }

  onPresenterStopCancel = () => this.setState({ isStopConfirmShow: false, selectedPresenter: null })

  onCreateScreen = () =>
    this.setState(prev => ({ isPresenterFormVisible: !prev.isPresenterFormVisible }))

  render() {
    const { deletePresenter, loading } = this.props
    const {
      isActivateModalShow,
      selectedPresenter,
      isConfirmShow,
      isStopConfirmShow,
      isPresenterFormVisible,
      page,
    } = this.state

    return (
      <>
        <MainCard
          title='ЭКРАНЫ'
          icon='icon__screen'
          loading={loading && page === 1}
          style={{ overflow: 'visible' }}
        >
          <div className='flex_container full_height' style={{ overflow: 'visible' }}>
            <LeftScrollTransition loading={!loading && isPresenterFormVisible}>
              <div className='container__screen_info' style={{ overflow: 'visible' }}>
                <UnderlinedTitle title='СОЗДАНИЕ ЭКРАНА' />
                <NewPresenterForm
                  onCancel={() => this.setState({ isPresenterFormVisible: false })}
                />
              </div>
            </LeftScrollTransition>
            <PresentersList
              isPresenterFormVisible={isPresenterFormVisible}
              onListScroll={this.onListScroll}
              onActivateBtnClick={presenter => this.onActivateBtnClick(presenter)}
              onDelete={this.onPresenterDelete}
              onPresenterStop={this.onPresenterStop}
              onCreateScreenClick={this.onCreateScreen}
              compact={isPresenterFormVisible}
            />
          </div>
        </MainCard>

        {isActivateModalShow && (
          <ActivateModal
            title='Экран'
            serialNumberField='serial_number'
            isShow={isActivateModalShow}
            currentObj={selectedPresenter}
            onActivate={this.confirmActivate}
            onClose={() => this.onActivateBtnClick(null)}
          />
        )}
        {isConfirmShow && (
          <ConfirmationModal
            id={selectedPresenter?.id}
            start={isConfirmShow}
            text={`Удалить экран "${selectedPresenter.title}"?`}
            onConfirm={() => deletePresenter(selectedPresenter.id, this.onDeletionPopUpClose)}
            onClose={this.onDeletionPopUpClose}
          />
        )}
        {isStopConfirmShow && (
          <ConfirmationModal
            id={selectedPresenter?.id}
            start={isStopConfirmShow}
            text={`Остановить трансляцию на экране "${selectedPresenter.title}"?`}
            onConfirm={this.onPresenterStopConfirm}
            onClose={this.onPresenterStopCancel}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = ({ presenters }) => {
  const { loading, count } = presenters
  return { loading, count }
}

const mapDispatchToProps = dispatch => ({
  stopPresenterSession: presenter => dispatch({ type: STOP_PRESENTER_SESSION, presenter }),
  getPresenters: page => dispatch({ type: GET_PRESENTERS, page }),
  clearPresenters: () => dispatch({ type: CLEAR_PRESENTS }),
  deletePresenter: (presenterId, callback) =>
    dispatch({ type: DELETE_PRESENTER, presenterId, callback }),
  activatePresenter: (presenterId, callback) =>
    dispatch({ type: ACTIVATE_PRESENTER, presenterId, callback }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Screens))
