import React from 'react'

const BigCardTitle = ({ icon, title, subtitle, topBtn }) => (
  <div className='card_title full_width'>
    <div className={icon} />
    <div className='flex_container flex_container__flex_1'>
      <span className='large_btn__title'>{title?.toUpperCase()}</span>
      <span className='large_btn__title'>{subtitle?.toUpperCase()}</span>
    </div>
    <div className='card_title__top_btn_padding'>
      {topBtn}
    </div>
  </div>
)

export default BigCardTitle
