import React from 'react'
import { Form, Select } from 'antd'
import { Field as FormikField } from 'formik'

const { Option } = Select
const TagsSelect = ({ label, name, value, onChange, setFieldTouched, source, children }) => {
  return (
    <Form.Item label={label}>
      <div className='validation_block'>
        <FormikField
          render={() => (
            <Select
              name={name}
              mode='multiple'
              value={value}
              placeholder='Выберите группы пользователей'
              onChange={val => onChange(name, val)}
              onBlur={setFieldTouched}
              style={{ width: '100%' }}
            >
              {source?.map(item => <Option key={item.id}>{item.title}</Option>)}
            </Select>
          )}
        />
        <div className='validation_block margin_top__small'>{children}</div>
      </div>
    </Form.Item>
  )
}

export default TagsSelect
