import {
  GET_PRESENTERS_SUCCEED,
  SET_PRESENTERS_LOADING,
  CLEAR_PRESENTS,
  PUT_PRESENTER_SUCCEED,
  ACTIVATE_PRESENTER_SUCCEED,
  STOP_PRESENTER_SESSION_SUCCEED, POST_PRESENTER_SUCCEED, DELETE_PRESENTER_SUCCEED,
} from '../actions/presenters'

export const presenters = (state = {}, action) => {
  switch (action.type) {
    case SET_PRESENTERS_LOADING: return { ...state, loading: true }

    case PUT_PRESENTER_SUCCEED: {
      const newList = state.list
      const targetIndex = state.list.findIndex(presenter => presenter.id === action.presenter.id)
      newList[targetIndex] = { ...newList[targetIndex], ...action.presenter }
      return { ...state, list: [...newList] }
    }

    case POST_PRESENTER_SUCCEED: {
      const newList = [action.presenter, ...state.list]
      return { ...state, list: newList }
    }

    case ACTIVATE_PRESENTER_SUCCEED: {
      const newList = state.list
      const targetIndex = state.list.findIndex(presenter => presenter.id === action.newPresenter.id)
      newList[targetIndex] = action.newPresenter
      return { ...state, list: [...newList] }
    }

    case STOP_PRESENTER_SESSION_SUCCEED: {
      const { presenter } = action

      if (!state.list || state.list.length <= 0) return state
      const targetElementIndex = state.list?.findIndex(p => p.id === presenter?.id)
      const newList = state.list
      newList[targetElementIndex] = presenter
      return { ...state, list: [...newList] }
    }

    case GET_PRESENTERS_SUCCEED: {
      const newList = state?.list?.length > 0 && (action.page !== 1)
        ? [...state.list, ...action.list]
        : action.list

      return {
        ...state,
        list: newList,
        count: action.count,
        loading: false,
      }
    }

    case DELETE_PRESENTER_SUCCEED: {
      const newList = state.list.filter(p => p.id !== action.presenterId)
      return { ...state, list: newList }
    }

    case CLEAR_PRESENTS: return { ...state, list: null, count: null }

    default: return state
  }
}

export default presenters
