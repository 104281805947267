import React from 'react'
import { Tooltip } from 'antd'

import { withRouter } from 'react-router-dom'
import stopPropagation from '../../../utils/stopPropagation'

const CardListItem = ({
  children,
  onDelete,
  onCardClick,
  onEdit,
  small,
  style,
  confirm,
  confirmDeletion,
  extraClassName,
}) => {
  const getGeneralClassName = () => {
    const basicName = `card_list__item${small ? '__small' : ''}`
    return `${basicName}${extraClassName ? ` ${extraClassName}` : ''}`
  }
  return (
    <>
      <div
        style={style}
        className={getGeneralClassName()}
        onClick={e => stopPropagation(onCardClick, e)}
      >
        {children}
        {(onDelete || confirmDeletion) && (
          <Tooltip title='Удалить' mouseEnterDelay={0.6}>
            <div
              className='icon__wrapper__hover_enabled full_height'
              onClick={e => stopPropagation(confirm ? confirmDeletion : onDelete, e)}
            >
              <div className='icon__delete' />
            </div>
          </Tooltip>
        )}
        {onEdit && (
          <Tooltip title='Редактировать' mouseEnterDelay={0.6}>
            <div
              className='icon__wrapper__hover_enabled full_height'
              onClick={e => stopPropagation(onEdit, e)}
            >
              <div className='icon__edit' />
            </div>
          </Tooltip>
        )}
      </div>
    </>
  )
}

export default withRouter(CardListItem)
