export const POST_FILE = 'POST_FILE'
export const POST_FILE_SUCCEED = 'POST_FILE_SUCCEED'

export const POST_PHOTO = 'POST_PHOTO'
export const POST_PHOTO_SUCCEED = 'POST_PHOTO_SUCCEED'

export const COPY_PHOTO_TO_CURRENT_FILES = 'COPY_PHOTO_TO_CURRENT_FILES'
export const CHANGE_USER_PHOTO_SUCCEED = 'CHANGE_USER_PHOTO_SUCCEED'

export const DELETE_FILE = 'DELETE_FILE'
export const DELETE_FILE_FROM_NEW_LECTURE = 'DELETE_FILE_FROM_NEW_LECTURE'
export const CLEAR_FILES = 'CLEAR_FILES'

export const ADD_FILE_TO_NEW_LECTURE = 'ADD_FILE_TO_NEW_LECTURE'
export const ADD_FILE_TO_NEW_LECTURE_SUCCEED = 'ADD_FILE_TO_NEW_LECTURE_SUCCEED'

export const CHANGE_LECTURE_FILES_IN_NEW_EVENT = 'CHANGE_LECTURE_FILES_IN_NEW_EVENT'

export const DELETE_PHOTO = 'DELETE_PHOTO'

export const SET_PHOTO_LOADING = 'SET_PHOTO_LOADING'
