import React from 'react'
import moment from 'moment'
import { dateToString } from '../../utils/formatDate'

const VotingCardListItem = ({ name, dateTo, dateFrom, index, marker }) => {
  const getEndDate = () => dateTo ? `до ${dateToString(dateTo)}` : 'Без срока'
  const getDisplayedDate = () => {
    if (moment().isSameOrBefore(dateFrom)) return `с ${dateToString(dateFrom)}`
    return moment().isAfter(dateTo)
      ? `закрыт ${dateToString(dateTo)}`
      : getEndDate()
  }

  return (
    <>
      <div className='card_list__item_marker'>
        <div className={marker} />
      </div>
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item_name'>{`${name}`}</span>
      <span className='card_list__item_voting_date'>{getDisplayedDate()}</span>
    </>
  )
}

export default VotingCardListItem
