import { formatDateFromTwoFields } from '../formatDate'

const packEvent = values => {
  const formattedDate = formatDateFromTwoFields(values.startDate, values.startTime)
  const { groupWhitelistRead, startDate, startTime, ...formattedValues } = values

  return {
    ...formattedValues,
    title: values.title,
    description: values.description,
    place: values.place === '' ? undefined : values.place,
    tags: values.tags,
    date: formattedDate,
    group_whitelist_read: values.group_whitelist_read,
  }
}

export default packEvent
