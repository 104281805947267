import React, { useState } from 'react'
import { Input } from 'antd'

const MultiInputItem = ({ index, name, value, onChange, deleteItem, disabled }) => {
  const [isTouched, setIsTouched] = useState(false)

  const isErrorVisible = isTouched && (value.length > 120 || value.length === 0)

  const trimValue = () => onChange(`${name}[${index}]`, value.trim())

  const onFieldBlur = () => {
    setIsTouched(true)
    trimValue()
  }

  return (
    <>
      <div className='multi_input_item__block'>
        <span className='multi_input_item__index'>{`${index + 1}.`}</span>
        <Input
          disabled={disabled}
          name={name}
          value={value}
          style={{
            flex: 1,
            overflow: 'visible',
            border: isErrorVisible && '1px solid #CD4141',
            borderRadius: '4px',
            height: 'auto',
          }}
          placeholder={`Вариант ответа №${index + 1}`}
          onChange={e => onChange(`${name}[${index}]`, e.target.value)}
          suffix={!disabled && <div className='icon_delete__gray' onClick={deleteItem} />}
          onBlur={onFieldBlur}
        />
        {isErrorVisible
          && (
            <span className='validation_msg'>
              {isTouched && (value.length > 0
                ? `Максимальная длина – 120 символов (введено ${value.length})`
                : 'Минимальная длина - 1 символ')}
            </span>
          )}
      </div>
    </>
  )
}

export default MultiInputItem
