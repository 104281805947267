import React, { useState } from 'react'

import UsersCardListItem from './UsersCardListItem'
import EditUserForm from './forms/EditUser'
import AnimatedHeight from '../UI/AnimatedHeight'

const UserCard = ({ user, index, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false)

  const getItemActivityClass = () => {
    const defaultClass = 'card_list__item'
    return isOpen ? `${defaultClass}__active` : defaultClass
  }

  return (
    <React.Fragment key={user.id}>
      <UsersCardListItem
        user={user}
        index={index}
        onDelete={onDelete}
        onItemClick={() => setIsOpen(!isOpen)}
        getItemActivityClass={getItemActivityClass}
      />
      <AnimatedHeight condition={isOpen}>
        <EditUserForm user={user} onCancel={() => setIsOpen(false)} />
      </AnimatedHeight>
    </React.Fragment>
  )
}

export default UserCard
