export const SET_TOKEN = 'SET_TOKEN'

export const LOG_IN_USER = 'LOG_IN_USER'

export const SET_TOKEN_LOADING = 'SET_TOKEN_LOADING'

export const GET_ACCOUNT = 'GET_ACCOUNT'
export const GET_ACCOUNT_SUCCEED = 'GET_ACCOUNT_SUCCEED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
