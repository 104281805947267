import React from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'

import UnderlinedTitle from '../../UI/UnderlinedTitle'
import FadeTransition from '../../helpers/transition/FadeTransition'
import RadioGroup from '../../UI/input/RadioGroup'
import SimpleSelect from '../../UI/input/SimpleSelect'
import RangePicker from '../../UI/input/RangePicker'
import ValidationMsg from '../../UI/ValidationMsg'
import DatePick from '../../UI/input/DatePick'
import DateTimePick from '../../UI/input/DateTimePick'
import moment from 'moment'
import isDateBeforeToday from '../../../utils/isDateBeforeToday'
import Button from '../../UI/Button'
import { ErrorMessage } from 'formik'
import TextAreaSimple from '../../UI/input/TextAreaSimple'

const RightSideVoteForm = ({
  loading,
  values,
  setFieldTouched,
  setFieldValue,
  onSelectChange,
  eventsList,
  errors,
  touched,
  lecturesList,
}) => {
  const { Option } = Select
  const isEventLinkErrorVisible = touched.eventLink && errors.eventLink

  return (
    <div className='container_form__right'>
      <UnderlinedTitle title='НАСТРОЙКИ ВИДИМОСТИ ОПРОСА' />
      <FadeTransition loading={!loading}>
        <RadioGroup
          name='access'
          label='Доступ'
          value={values.access}
          setFieldTouched={setFieldTouched}
          onChange={setFieldValue}
          options={['Видно всем', 'Добавить к событию']}
        />
      </FadeTransition>

      <UnderlinedTitle title='ДОБАВЛЕНИЕ ОПРОСА В СОБЫТИЕ' />
      <FadeTransition loading={!loading}>
        <div>
          <SimpleSelect
            name='eventLink'
            label='Опрос в событии'
            value={values.eventLink.title}
            style={{ width: '100%' }}
            onChange={onSelectChange}
            setFieldTouched={setFieldTouched}
            disabled={values.access === 'Видно всем'}
            error={() => isEventLinkErrorVisible
              && <ValidationMsg>{errors.eventLink.id}</ValidationMsg>}
          >
            {eventsList.map(e => (
              <Option className='select_option' value={e.id} key={e.id}>{e.title}</Option>
            ))}
          </SimpleSelect>
          <SimpleSelect
            name='lectureLink'
            label='Опрос в докладе'
            value={values.lectureLink?.title}
            style={{ width: '100%' }}
            onChange={onSelectChange}
            setFieldTouched={setFieldTouched}
            disabled={values.eventLink.id === null || values.access === 'Видно всем'}
          >
            {lecturesList.map(l => (
              <Option value={l.id} key={l.id}>{l.title}</Option>))}
          </SimpleSelect>
        </div>
      </FadeTransition>

      <UnderlinedTitle title='НАСТРОЙКИ ВРЕМЕНИ ПУБЛИКАЦИИ' />
      <FadeTransition loading={!loading}>
        <div className='flex_container margin_bottom flex_container__wrap'>
          <DatePick
            showTime='HH:mm'
            label='Начало публикации'
            name='dateFrom'
            value={values.dateFrom}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
            format='DD MMM YYYY, HH:mm'
            extraStyle={{ width: '60%', maxWidth: '60%' }}
            disabledDate={isDateBeforeToday}
            error={errors.dateFrom && touched.dateFrom}
          >
            <ErrorMessage component={ValidationMsg} name='dateFrom' />
          </DatePick>
          <div className='flex_container full_width' style={{ alignItems: 'flex-end' }}>
            <DatePick
              allowClear
              showTime='HH:mm'
              label='Конец публикации'
              name='dateTo'
              value={values.dateTo}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              format='DD MMM YYYY, HH:mm'
              extraStyle={{ width: '60%', maxWidth: '60%' }}
              disabledDate={isDateBeforeToday}
            />
            <Button
              style={{ marginBottom: '7px' }}
              styles='simple_btn'
              title='Очистить'
              onClick={() => setFieldValue('dateTo', null)}
            />
          </div>
        </div>
      </FadeTransition>
    </div>
  )
}

const mapStateToProps = ({ events }) => {
  const lectures = (events.event?.id && events.event?.lectures)
    ? events.event.lectures.map(l => ({ id: l.id || null, title: l.title || 'Выберите' }))
    : []
  return {
    eventsList: events.list?.map(e => ({ id: e.id, title: e.title })) || [],
    lecturesList: lectures,
  }
}

export default connect(mapStateToProps)(RightSideVoteForm)
