const cropString = (string, num) => (string.length <= num ? string : string.substring(0, num))

export const cropStringAtCenter = string => {
  const strLength = string.length
  return strLength <= 30
    ? string
    : `${string.substring(0, 7)}...${string.substring(strLength - 8)}`
}

export default cropString
