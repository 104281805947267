import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { message } from 'antd'

import getResponse from './getResponse'
import isNoMessage from '../utils/isNoMessage'
import setTokenToStorage from '../utils/manageTokens'
import { content, auth } from '../utils/data/requestHeaders'
import { POST, GET } from '../utils/methods'
import { GET_ACCOUNT, GET_ACCOUNT_SUCCEED, LOG_IN_USER, SET_TOKEN, CHANGE_PASSWORD } from '../actions/account'

export function* logInUser({ username, password, callback }) {
  try {
    const response = yield call(() =>
      getResponse({
        method: POST,
        path: '/auth_employee',
        headers: content,
        body: { username, password },
      }))
    if (!response || !isNoMessage(response)) return null

    yield setTokenToStorage(response)
    yield put({ type: SET_TOKEN, token: response })
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

export function* getAccount() {
  try {
    const token = localStorage.getItem('access_token')
    if (!token || token === 'null') return null

    const response = yield call(() => getResponse({
      method: GET,
      path: '/account',
      headers: auth,
    }))

    if (isNoMessage(response))
      yield put({ type: GET_ACCOUNT_SUCCEED, account: response })
  } catch (e) { console.log(e.message) }
}

function* changePassword({ passwords, callback }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      headers: content,
      path: '/pass_change',
      body: passwords,
    }))
    const { message, ...token } = response

    if (message === 'OK') {
      yield setTokenToStorage(token)
      yield put({ type: SET_TOKEN, token })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) }
}

export default function* accountSagas() {
  yield takeLatest(LOG_IN_USER, logInUser)
  yield takeLatest(GET_ACCOUNT, getAccount)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}
