import React from 'react'

const UserPickItem = ({ user, containerType, onClick }) => {
  const getIcon = () => containerType === 'selected' ? 'icon__delete' : 'icon__plus__green'

  return (
    <div className='card_list__item__user__hover cursor_pointer' key={user?.id} onClick={onClick}>
      <span className='card_list__item__group_title' style={{ marginLeft: '10px' }}>
        {user?.name}
      </span>
      <span className='card_list__item_description'>
        {user?.description
          ? <span className='secondary_text'>{user?.description}</span>
          : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
      </span>
      <div className='icon__wrapper'><div className={getIcon()} /></div>
    </div>
  )
}

export default UserPickItem
