import * as _ from 'lodash'
import {
  DELETE_NOTIFICATION_SUCCEED,
  GET_NOTIFICATIONS_SUCCEED,
  POST_NOTIFICATION_SUCCEED,
  SET_NOTIFICATIONS_LOADING,
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
} from '../actions/notifications'

import translateString from '../utils/translateString'

export const notifications = (state = {}, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_LOADING: return { ...state, loading: true }

    case GET_NOTIFICATIONS_SUCCEED: {
      const formattedNotifications = action.notifications.map(n => {
        const formattedItem = {}
        _.forIn(n, (value, key) => {
          formattedItem[translateString.toCamelCase(key)] = value
        })
        return formattedItem
      })

      const list = state.list ? [...state.list, ...formattedNotifications] : formattedNotifications
      return { ...state, list, loading: false, count: action.count }
    }

    case POST_NOTIFICATION_SUCCEED: {
      const newList = state.list.length > 0
        ? [action.notification, ...state.list]
        : [action.notification]
      return { ...state, list: newList }
    }

    case DELETE_NOTIFICATION_SUCCEED: {
      const newList = state.list.filter(n => n.id !== action.notificationId)
      return { ...state, list: newList }
    }

    case HIDE_NOTIFICATION: {
      const newList = state.list.filter(n => n.id !== action.notificationId)
      return { ...state, list: newList }
    }

    case SHOW_NOTIFICATION: {
      const { notificationIndex, notification } = action
      const newList = state.list
      newList.splice(notificationIndex, 0, notification)

      return { ...state, list: newList }
    }

    default: return state
  }
}

export default notifications
