import React from 'react'
import { connect } from 'react-redux'
import { Field as FormikField } from 'formik'
import { Form } from 'antd'

import {
  POST_PHOTO,
  DELETE_PHOTO,
  COPY_PHOTO_TO_CURRENT_FILES,
  CHANGE_USER_PHOTO_SUCCEED,
} from '../../../actions/files'
import setAvatarStyle from '../../../utils/getStyle/getAvatarStyle'
import defaultAvatar from '../../../assets/images/default_avatar.jpg'
import { isFileValid } from './fileRestrictions'

const DEFAULT_PHOTO = { id: null, path: defaultAvatar }

class SimpleImageUploader extends React.Component {
  state = { isFileLoading: false }

  componentDidMount() {
    const { isNew, copyPhotoToCurrentFiles, photo } = this.props
    if (!isNew) copyPhotoToCurrentFiles(photo || {})
  }

  componentDidUpdate(prevProps) {
    const { isNew, photo, copyPhotoToCurrentFiles } = this.props
    if (!isNew && (prevProps.photo !== photo)) copyPhotoToCurrentFiles(photo || {})
  }

  onUploadChange = e => {
    const { postPhoto, isNew, userId } = this.props
    const file = e.target?.files[0]
    if (!file || !isFileValid(file)) return

    this.setState({ isFileLoading: true })
    const data = new FormData()
    data.append('file', file)
    data.append('title', file.name)
    data.append('max_width', '500')
    data.append('max_height', '500')

    postPhoto(data, isNew, userId, () => this.setState({ isFileLoading: false }))
  }

  render() {
    const {
      deletePhoto,
      userPhoto,
      isNew,
      label,
      updatePhoto,
      userId,
    } = this.props
    const { isFileLoading } = this.state

    const uploaderStyle = `input__upload_container ${isFileLoading ? 'loading_animation' : ''}`
    return (
      <Form.Item label={label}>
        <FormikField
          render={() => (
            <>
              <input
                type='file'
                accept='.png, .jpg, .jpeg'
                ref={ref => this.upload = ref}
                className='input__file_uploader'
                onClick={e => e.target.value = null}
                onChange={this.onUploadChange}
              />
              <div className={uploaderStyle}>
                <span className='input__upload_placeholder'>Загрузить фото</span>
                <div className='input__upload_button' onClick={() => this.upload.click()}>
                  Выбрать
                </div>
              </div>
              {(!isNew || userPhoto) && (
                <div className='photo_preview' style={setAvatarStyle(userPhoto)}>
                  <div
                    className='icon__delete'
                    onClick={() => isNew ? deletePhoto(isNew) : updatePhoto(userId)}
                  />
                </div>
              )}
            </>
          )}
        />
      </Form.Item>
    )
  }
}

const mapStateToProps = ({ files }, { isNew, photo, userId }) => {
  const getUserPhoto = () => {
    if (isNew) return files?.photo
    const fileInCurrentFiles = files?.currentFilesList?.find(file => file.userId === userId)
    return fileInCurrentFiles || photo
  }

  return ({ userPhoto: getUserPhoto(), loading: files?.loading || false })
}

const mapDispatchToProps = dispatch => ({
  deletePhoto: (isNew, userId) => dispatch({ type: DELETE_PHOTO, isNew, userId }),
  copyPhotoToCurrentFiles: (photo = {}) => dispatch({ type: COPY_PHOTO_TO_CURRENT_FILES, photo }),
  updatePhoto: userId =>
    dispatch({ type: CHANGE_USER_PHOTO_SUCCEED, photo: DEFAULT_PHOTO, userId }),
  postPhoto: (file, isNew, userId, callback) =>
    dispatch({ type: POST_PHOTO, file, isNew, userId, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SimpleImageUploader)
