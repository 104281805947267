const packUser = values => ({
  firstname: values.firstName,
  lastname: values.lastName,
  phone_number: values.phone_number,
  email: values.email,
  description: values.description,
  city: values.city.id,
  birthday: values.birthday.format('YYYY-MM-DD'),
  gender: values.sex === 'М' ? 'Мужчина' : 'Женщина',
})

export default packUser
