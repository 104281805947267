import React from 'react'
import { Tooltip } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import setAvatarStyle from '../../utils/getStyle/getAvatarStyle'
import stopPropagation from '../../utils/stopPropagation'

const UsersCardListItem = ({
  userPhoto,
  user,
  index,
  onDelete,
  onItemClick,
  getItemActivityClass,
}) => {
  const { id, name, description } = user

  return (
    <div
      className={getItemActivityClass()}
      onClick={e => stopPropagation(() => onItemClick(user), e)}
    >
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item_avatar' style={{ marginRight: '10px' }}>
        <div className='card_list__avatar' style={setAvatarStyle(userPhoto)} />
      </span>
      <span className='card_list__item_name'>
        {name === 'Имя не указано'
          ? <div className='main_text__inactive text__wrapper'>{name}</div>
          : name}
      </span>
      <span className='card_list__item__user_description'>
        {description
          ? <span className='secondary_text'>{description}</span>
          : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
      </span>
      {/*{onDelete && (*/}
      {/*  <Tooltip title='Удалить' mouseEnterDelay={0.6}>*/}
      {/*    <div*/}
      {/*      className='icon__wrapper full_height'*/}
      {/*      onClick={e => stopPropagation(() => onDelete({ id, name }), e)}*/}
      {/*    >*/}
      {/*      <div className='icon__delete' />*/}
      {/*    </div>*/}
      {/*  </Tooltip>*/}
      {/*)}*/}
    </div>
  )
}

const mapStateToProps = ({ users }, { user }) => ({
  userPhoto: users?.list?.find(u => u.id === user.id)?.photo,
})

export default withRouter(connect(mapStateToProps)(UsersCardListItem))
