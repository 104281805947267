import React from 'react'
import Notifications from './Notifications/index'

const RightBlock = ({ topBlock, bottomBlock }) => (
  <>
    {topBlock || <Notifications />}
    {bottomBlock}
  </>
)

export default RightBlock
