import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import * as _ from 'lodash'

import MainCard from '../../MainCard'
import { CLEAR_GROUP, GET_ACCESS_GROUP } from '../../../actions/users'
import UsersInGroupList from './UsersInGroupList'
import GroupInfo from './GroupInfo'

const GroupCard = ({ getGroup, group, loading, match, history, clearGroup }) => {
  useEffect(() => {
    getGroup(match.params.id)
    return clearGroup
  }, [getGroup, match])

  const onButtonClick = () => {
    history.push(`/groups/${match.params.id}/edit`)
  }

  return (
    <MainCard icon='icon__groups' title='Группа доступа' loading={loading}>
      <div className='flex_container full_height'>
        <GroupInfo group={group} />
        <UsersInGroupList users={_.uniqBy(group?.users, 'id')} onButtonClick={onButtonClick} />
      </div>
    </MainCard>
  )
}

const mapStateToProps = ({ users }) => ({
  group: users?.groups?.currentGroup,
  loading: users?.groups?.loading,
})

const mapDispatchToProps = dispatch => ({
  getGroup: id => dispatch({ type: GET_ACCESS_GROUP, id }),
  clearGroup: () => dispatch({ type: CLEAR_GROUP }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupCard))
