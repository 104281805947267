import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '../UI/Button'
import PopUpTransition from '../helpers/transition/PopUptransition'
import PasswordChangeModal from './PasswordChangeModal'

const UserPopUpInfo = ({ isExpanded, name, email, onLogOut, closePopUp }) => {
  const [isPasswordChangeModalVisible, setIsPasswordChangeModalVisible] = useState(false)
  const wrapperRef = useRef(null)

  const handleKeyDown = useCallback(e => e.keyCode === 27 && closePopUp(), [closePopUp])

  const handleClickOutside = event =>
    wrapperRef?.current && !wrapperRef?.current?.contains(event.target) && closePopUp()

  const showResetConfirmPassword = () => {
    closePopUp()
    setIsPasswordChangeModalVisible(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isExpanded])

  return (
    <>
      <PopUpTransition loading={isExpanded}>
        <div className='user_info_pop_up' ref={wrapperRef} onKeyDown={handleKeyDown}>
          <div className='user_info_pop_up__triangle' />
          <div className='labeled_field__block__bordered'>
            <span className='labeled_field__label'>Ф.И.О.</span>
            <div className='user_info_pop_up__name'>{name}</div>
          </div>
          <div className='labeled_field__block__bordered' style={{ border: 0 }}>
            <span className='labeled_field__label'>Email:</span>
            <div className='main_text__subtitle'>{email}</div>
          </div>
          <div className='flex_container flex_container__center'>
            <Button
              styles='simple_btn'
              title='Сменить пароль'
              onClick={showResetConfirmPassword}
            />
          </div>
          <Button
            title='Выйти'
            icon='icon__logout'
            styles='bordered_btn__red'
            style={{ margin: '0 auto', marginTop: '5px' }}
            onClick={onLogOut}
          />
        </div>
      </PopUpTransition>
      <PasswordChangeModal
        start={isPasswordChangeModalVisible}
        onClose={() => setIsPasswordChangeModalVisible(false)}
      />
    </>
  )
}

export default UserPopUpInfo
