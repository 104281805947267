export const OFFICES_LOADING = 'OFFICE_LOADING'

export const GET_OFFICES = 'GET_OFFICES'
export const GET_OFFICES_SUCCEED = 'GET_OFFICES_SUCCEED'
export const GET_OFFICES_FAILED = 'GET_OFFICES_FAILED'

export const SET_FLOORS_LOADING = 'SET_FLOORS_LOADING'
export const GET_OFFICE = 'GET_OFFICE'
export const GET_OFFICE_SUCCEED = 'GET_OFFICE_SUCCEED'
export const GET_OFFICE_FAILED = 'GET_OFFICE_FAILED'

export const DELETE_OFFICE = 'DELETE_OFFICE'
export const DELETE_OFFICE_SUCCEED = 'DELETE_OFFICE_SUCCEED'

export const GET_FLOORS = 'GET_FLOORS'
export const GET_FLOORS_SUCCEED = 'GET_FLOORS_SUCCEED'

export const SET_ROOMS_LOADING = 'SET_ROOMS_LOADING'
export const GET_ROOMS_LIST = 'GET_ROOMS_LIST'
export const GET_ROOMS_LIST_SUCCEED = 'GET_ROOMS_LIST_SUCCEED'

export const SET_TABLES_LOADING = 'SET_TABLES_LOADING'
export const GET_TABLES = 'GET_TABLES'
export const GET_TABLES_SUCCEED = 'GET_TABLES_SUCCEED'

export const CLEAN_TABLES = 'CLEAN_TABLES'
export const CLEAN_OFFICES_LIST = 'CLEAN_OFFICES_LIST'

export const CLEAN_OFFICE = 'CLEAN_OFFICE'

export const SET_TABLE_TAGS_LOADING = 'SET_TABLE_TAGS_LOADING'
export const GET_TABLE_TAGS = 'GET_TABLE_TAGS'
export const GET_TABLE_TAGS_SUCCEED = 'GET_TABLE_TAGS_SUCCEED'

export const POST_OFFICE = 'POST_OFFICE'
export const POST_OFFICE_SUCCEED = 'POST_OFFICE_SUCCEED'

export const PUT_OFFICE = 'PUT_OFFICE'

export const POST_ROOM = 'POST_ROOM'
export const POST_ROOM_SUCCEED = 'POST_ROOM_SUCCEED'

export const POST_TABLE = 'POST_TABLE'
export const POST_TABLE_SUCCEED = 'POST_TABLE_SUCCEED'

export const DELETE_TABLE = 'DELETE_TABLE'
export const DELETE_TABLE_SUCCEED = 'DELETE_TABLE_SUCCEED'

export const DELETE_ROOM = 'DELETE_ROOM'
export const DELETE_ROOM_SUCCEED = 'DELETE_ROOM_SUCCEED'

export const PUT_ROOM = 'PUT_ROOM'
export const PUT_ROOM_SUCCEED = 'PUT_ROOM_SUCCEED'

export const PUT_TABLE = 'PUT_TABLE'
export const PUT_TABLE_SUCCEED = 'PUT_TABLE_SUCCEED'

export const ACTIVATE_TABLE = 'ACTIVATE_TABLE'

export const SET_CURRENT_TABLE = 'SET_CURRENT_TABLE'
export const ACTIVATE_TABLE_SUCCEED = 'ACTIVATE_TABLE_SUCCEED'

export const GET_SEARCH_OFFICES_SUCCEED = 'GET_SEARCH_OFFICES_SUCCEED'
export const GET_SEARCH_OFFICES = 'GET_SEARCH_OFFICES'
