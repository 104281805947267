import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { message } from 'antd'

import getResponse from './getResponse'
import HOST from '../utils/data/connectionData'
import { auth, content } from '../utils/data/requestHeaders'
import { postLecture } from './lecturesSagas'
import { setDefaultCursorStyle, setWaitCursorStyle } from '../utils/cursorStyleSwitch'
import { POST_LECTURE } from '../actions/lectures'
import { GET, PUT, POST, DELETE } from '../utils/methods'
import isNoMessage from '../utils/isNoMessage'
import {
  CREATE_EVENT,
  DELETE_EVENT,
  DELETE_EVENT_FAILED,
  DELETE_EVENT_SUCCEED,
  GET_EVENT,
  GET_EVENT_FAILED,
  GET_EVENT_SUCCEED,
  GET_EVENTS_LIST,
  GET_EVENTS_LIST_FAILED,
  GET_EVENTS_LIST_SUCCEED,
  POST_EVENT,
  POST_EVENT_FAILED,
  POST_EVENT_SUCCEED,
  SAVE_EVENT,
  SAVE_EVENT_FAILED,
  SAVE_EVENT_SUCCEED,
  SET_LOADING,
} from '../actions/events'

export function* getEventsList() {
  try {
    yield put({ type: SET_LOADING })
    const events = yield call(() => getResponse({ method: GET, path: '/event', headers: auth }))
    yield put({ type: GET_EVENTS_LIST_SUCCEED, eventsList: events })
  } catch (e) {
    yield put({ type: GET_EVENTS_LIST_FAILED, message: e.message })
  }
}

export function* getEvent(action) {
  try {
    yield put({ type: SET_LOADING })
    const event = yield call(() => getResponse({
      method: GET,
      path: `/event?id=${action.eventId}`,
      headers: auth,
    }))

    yield isNoMessage(event) && put({ type: GET_EVENT_SUCCEED, event: event })
  } catch (e) {
    yield put({ type: GET_EVENT_FAILED, message: e.message })
  }
}

function* putEvent({ event, history }) {
  try {
    const response = yield call(() => getResponse({
      method: PUT,
      path: `/events/${event.id}`,
      headers: content,
      body: event,
    }))

    if (isNoMessage(response)) {
      yield put({ type: SAVE_EVENT_SUCCEED, event })
      yield history && history.push(`/events/${event.id}`)
      message.success('Событие сохранено')
    }
  } catch (e) {
    console.log(e.message)
    yield put({ type: SAVE_EVENT_FAILED, message: e.message })
  }
}

function* postEvent(action) {
  try {
    const event = yield call(() => getResponse({
      method: POST,
      path: '/event',
      headers: content,
      body: action.event,
    }))

    yield (isNoMessage(event)) && put({ type: POST_EVENT_SUCCEED, event })
    if (action.lecture) {
      const lecture = yield { ...action.lecture, event: event.id }
      yield put({ type: POST_LECTURE, lecture })
    }

    message.success('Событие создано')
    return event
  } catch (e) { yield put({ type: POST_EVENT_FAILED, message: e.message }) }
}

function* createEvent(action) {
  try {
    const { events } = yield select()
    const createdEvent = yield call(() => postEvent({ event: action.event }))
    const lectures = events.newEvent
      ? events.newEvent.lectures.map(l => {
        const newLecture = _.omit(l, 'id')
        newLecture.speaker = l.speaker.id
        return newLecture
      })
      : []

    const lecturesWithPapersId = lectures.map(l => {
      const files = l.papers.map(p => p.id)
      return { ...l, papers: files }
    })

    const lecturesWithEventId = lecturesWithPapersId.map(l => ({ ...l, event: createdEvent.id }))
    const lecturesToRequests = lecturesWithEventId.map(l => call(() => postLecture({ lecture: l })))

    yield all(lecturesToRequests)
    if (isNoMessage(createdEvent) && lecturesToRequests.every(lec => isNoMessage(lec)))
      yield action.history && action.history.push(`/events/${createdEvent.id}`)
  } catch (e) { console.log('FAIL', e.message) }
}

function* deleteEvent(action) {
  const { eventId } = action
  try {
    yield setWaitCursorStyle()
    yield call(fetch, `${HOST}/events/${eventId}`, {
      method: DELETE,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    })

    yield put({ type: DELETE_EVENT_SUCCEED, eventId })
    yield setDefaultCursorStyle()
  } catch (e) {
    yield put({ type: DELETE_EVENT_FAILED, message: e.message })
    yield setDefaultCursorStyle()
  }
}

export default function* eventsSagas() {
  yield takeLatest(CREATE_EVENT, createEvent)
  yield takeLatest(DELETE_EVENT, deleteEvent)
  yield takeLatest(GET_EVENT, getEvent)
  yield takeLatest(GET_EVENTS_LIST, getEventsList)
  yield takeLatest(POST_EVENT, postEvent)
  yield takeLatest(SAVE_EVENT, putEvent)
}
