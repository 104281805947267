import { call, put, takeLatest } from 'redux-saga/effects'

import { CLEAR_FILES } from '../actions/files'
import { POST, GET, PUT, DELETE } from '../utils/methods'
import getResponse from './getResponse'
import isNoMessage from '../utils/isNoMessage'
import { content, auth } from '../utils/data/requestHeaders'
import {
  GET_LECTURE,
  GET_LECTURE_SUCCEED,
  POST_LECTURE_SUCCEED,
  POST_LECTURE_FAILED,
  PUT_LECTURE_SUCCEED,
  PUT_LECTURE_FAILED,
  DELETE_LECTURE_SUCCEED,
  DELETE_LECTURE_FAILED,
  DELETE_LECTURE,
  POST_LECTURE,
  PUT_LECTURE,
} from '../actions/lectures'

function* getLecture(action) {
  try {
    const lecture = yield call(() => getResponse({
      method: GET,
      path: `/lecture?id=${action.lectureId}`,
      headers: auth,
    }))

    yield put({ type: GET_LECTURE_SUCCEED, lecture })
  } catch (e) { console.log(e.message) }
}

export function* postLecture(action) {
  try {
    const lecture = yield call(() => getResponse({
      method: POST,
      path: '/lecture',
      headers: content,
      body: action.lecture,
    }))

    if (isNoMessage(lecture)) {
      yield put({ type: POST_LECTURE_SUCCEED, lecture })
      yield put({ type: CLEAR_FILES })
    }
  } catch (e) {
    yield put({ type: POST_LECTURE_FAILED, message: e.message })
  }
}

function* putLecture(action) {
  try {
    const lecture = yield call(() => getResponse({
      method: PUT,
      path: `/lectures/${action.lecture.id}`,
      headers: content,
      body: action.lecture,
    }))

    yield isNoMessage(lecture) && put({ type: PUT_LECTURE_SUCCEED, lecture })
  } catch (e) {
    console.log(e.message)
    yield put({ type: PUT_LECTURE_FAILED, message: e.message })
  }
}

function* deleteLecture({ lectureId }) {
  try {
    const response = yield call(() => getResponse({
      method: DELETE,
      path: `/lectures/${lectureId}`,
      headers: content,
    }))

    yield isNoMessage(response) && put({ type: DELETE_LECTURE_SUCCEED, lectureId })
  } catch (e) {
    console.log(e.message)
    yield put({ type: DELETE_LECTURE_FAILED })
  }
}

export default function* lecturesSagas() {
  yield takeLatest(GET_LECTURE, getLecture)
  yield takeLatest(DELETE_LECTURE, deleteLecture)
  yield takeLatest(POST_LECTURE, postLecture)
  yield takeLatest(PUT_LECTURE, putLecture)
}
