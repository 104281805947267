export const gridLinesColor = '#394455'
export const gridLabelsColor = '#637288'

export const gridLinesOptions = {
  lineWidth: 0.5,
  color: gridLinesColor,
  zeroLineColor: gridLinesColor,
  zeroLineWidth: 0.5,
}

export const legendOptions = {
  legend: {
    display: false,
    labels: {
      usePointStyle: true,
      fontFamily: 'DIN Pro Light',
      fontColor: 'white',
      boxWidth: 6,
      padding: 14,
    },
    position: 'bottom',
  },
}

export const dataLabelsColor = {
  color: context => {
    const color = context.dataset.backgroundColor
    if (!color) return 'white'
    if (typeof color === 'string') return color
    const index = context.dataIndex
    return color[index]
  },
  font: { size: 10, family: 'DIN Pro' },
}
