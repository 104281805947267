import React, { useCallback, useEffect, useState } from 'react'
import { withFormik } from 'formik'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { GET_USERS } from '../../actions/users'
import SimpleInput from './input/SimpleInput'

const InnerForm = ({
  values,
  setFieldValue,
  getUsers,
  setFieldTouched,
  setIsExpanded,
  isExpanded,
  resetForm,
  large,
  setSearchString,
  searchString,
  onClose,
  filter,
  defaultExpanded,
  getAll,
  autofocus,
  extraStyle,
}) => {
  const [debounce, setDebounce] = useState(null)
  const [isFocused, setIsFocused] = useState(false)

  const getBorderStyle = () => isFocused && { border: '1px solid #0079C1' }

  const getStyle = () => {
    const basicStyle = `search_field${large ? '__large' : ''}`
    return `${basicStyle}${isExpanded || defaultExpanded ? '__active' : ''}`
  }

  const handleSearch = str => {
    setSearchString(str)
    if (str.length <= 1 && values.name.length <= str.length) return null
    debounce && clearTimeout(debounce)

    setDebounce(setTimeout(() => getAll
      ? getUsers({ searchString: str })
      : getUsers({ page: 1, searchString: str, filter }), 500))
  }

  const handleChange = async (name, val) => {
    await handleSearch(val)
    await setFieldValue(name, val)
  }

  const closeSearchField = async () => {
    await setIsExpanded(false)
    await resetForm()
    await onClose()
  }

  const handleClose = () => {
    (searchString === '' && values.name === '') ? setIsExpanded(false) : closeSearchField()
  }

  const handleKeyDown = useCallback(e => e.keyCode === 27 && handleClose(), [setIsExpanded])

  useEffect(() => {
    !defaultExpanded && document.addEventListener('keydown', handleKeyDown, false)
    return () => document.removeEventListener('keydown', handleKeyDown, false)
  }, [handleKeyDown])

  return (
    <div
      className={getStyle()}
      onKeyDown={() => !defaultExpanded && handleKeyDown}
      style={{ ...extraStyle, ...getBorderStyle() }}
    >
      <div className='icon__search' onClick={() => setIsExpanded(!isExpanded)} />
      {isExpanded || defaultExpanded
        ? (
          <div className='flex_container full_width'>
            <SimpleInput
              name='name'
              onChange={handleChange}
              placeholder='Начните вводить имя пользователя'
              setFieldTouched={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              style={{ width: '100%' }}
              value={values.name}
              autofocus={autofocus}
            />
            {!defaultExpanded && <div className='icon__close' onClick={handleClose} />}
          </div>
        )
        : <div className='search_field__label' onClick={() => setIsExpanded(true)}>Поиск</div>}
    </div>
  )
}

const ExpandingSearch = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ name: '' }),
})(InnerForm)

const mapStateToProps = ({ offices }) => ({ searchList: offices.searchList })

const mapDispatchToProps = dispatch => ({
  getUsers: ({ page, searchString, filter }) =>
    dispatch({ type: GET_USERS, searchString, page, filter }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpandingSearch))
