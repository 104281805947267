import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Header from './components/Header/Header'
import UserCard from './components/Users/index'
import GroupEditCard from './components/UserGroups/EditCard'

const NoSideBarRouter = () => (
  <>
    <Header />
    <div className='screen__no_grid'>
      <Switch>
        <Route path='/users' component={UserCard} />
        <Route exact path='/groups/new' render={props => <GroupEditCard {...props} isNew />} />
        <Route
          exact
          path='/groups/:id/edit'
          render={props => <GroupEditCard {...props} isNew={false} />}
        />
      </Switch>
    </div>
  </>
)

export default NoSideBarRouter
