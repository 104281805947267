export const POST_POLL = 'POST_POLL'
export const SAVE_POLL_SUCCEED = 'SAVE_POLL_SUCCEED'

export const GET_POLLS = 'GET_POLLS'
export const GET_POLLS_SUCCEED = 'GET_POLLS_SUCCEED'

export const GET_POLL = 'GET_POLL'
export const GET_POLL_SUCCEED = 'GET_POLL_SUCCEED'

export const GET_POLL_STATISTIC = 'GET_POLL_STATISTIC'
export const GET_POLL_STATISTIC_SUCCEED = 'GET_POLL_STATISTIC_SUCCEED'

export const POLLS_LOADING = 'POLLS_LOADING'

export const DELETE_POLL = 'DELETE_POLL'
export const DELETE_POLL_SUCCEED = 'DELETE_POLL_SUCCEED'

export const PUT_POLL = 'PUT_POLL'

export const CLEAN_VOTING = 'CLEAR_VOTING'

export const GET_APP_LINKS = 'GET_APP_LINKS'
export const GET_APP_LINKS_SUCCEED = 'GET_APP_LINKS_SUCCEED'
