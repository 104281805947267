import React from 'react'
import Modal from '../UI/modal/Modal'
import Button from '../UI/Button'

const ActivateModal = ({
  title,
  isShow,
  onClose,
  currentObj,
  serialNumberField,
  onActivate,
}) => {
  const style = { width: '350px', height: 'min-content' }

  const getBtnStyle = () => {
    switch (currentObj.status) {
      case 'activated': return { title: 'Отвязать', styles: 'rounded_btn__red' }
      case 'not_activated': return { title: 'Активировать', styles: 'rounded_btn__green' }
      default:
        return {
          title: 'Ожидание активации',
          styles: 'rounded_btn',
          style: { opacity: '.5' },
          hardLock: true,
        }
    }
  }

  const getStatus = () => {
    switch (currentObj.status) {
      case 'activated': return 'Активирован'
      case 'not_activated': return 'Не активирован'
      case 'waiting': return 'Ожидание'
      default: return 'Нет данных'
    }
  }

  const btnStyle = getBtnStyle()

  return (
    <Modal start={isShow} style={style} onClose={onClose}>
      <div className='flex_container__column full_height'>
        <span className='modal_card__title'>
          {`${title.toUpperCase()} ${currentObj?.title}`}
        </span>
        <div className='labeled_field__block'>
          <span className='labeled_field__label'>Статус:</span>
          <div className='main_text__subtitle'>{currentObj && getStatus()}</div>
        </div>
        {serialNumberField && (
          <div className='labeled_field__block' style={{ marginBottom: '45px' }}>
            <span className='labeled_field__label'>Серийный номер:</span>
            <div className='main_text__subtitle'>
              {currentObj && currentObj[serialNumberField]
                ? currentObj[serialNumberField]
                : 'Не назначен'}
            </div>
          </div>
        )}
        <div className='lecture_form__button_wrapper'>
          <Button
            {...btnStyle}
            delay={300}
            onClick={currentObj.status !== 'waiting' && onActivate}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ActivateModal
