import React from 'react'
import { Pie } from 'react-chartjs-2'
import { pieChartOptions, chartBackgroundColor } from './setups/pieChartSetups'
import ScrollTransition from '../helpers/transition/ScrollTransition'
import EmptyPlaceholder from '../UI/EmptyPlaceholder'

const PieChart = ({ source, colors }) => {
  const getChartData = () => ({
    datasets: [{
      data: source.map(data => data.value),
      backgroundColor: colors,
      hoverBackgroundColor: colors,
      borderColor: chartBackgroundColor,
      hoverBorderColor: chartBackgroundColor,
      borderWidth: source.some(data => data.value === '100.0') ? 0 : 1,
    }],
  })

  const legendBlockStyle = {
    width: '60%',
    height: 'auto',
    minHeight: '100%',
    marginLeft: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }

  const getMarkerColor = index => ({ backgroundColor: colors[index] })

  const data = getChartData()

  return (
    <div className='pie_chart__container'>
      {source?.length > 0 && (
        <>
          <div className='pie_chart__chart_container'>
            {source.some(item => item.value !== 0)
              ? <Pie data={data} options={pieChartOptions} />
              : (
                <div className='flex_container flex_container__center'>
                  <EmptyPlaceholder text='Нет данных' />
                </div>
              )}
          </div>
          <ScrollTransition loading style={legendBlockStyle}>
            <div className='pie_chart__legend_block__content'>
              {source.length > 0 && source.map((data, index) => (
                <div className='pie_chart__legend_item_block' key={index}>
                  <div className='round_marker' style={getMarkerColor(index)} />
                  <div className='pie_chart__legend_title'>{data.title}</div>
                  <div className='pie_chart__legend_value'>
                    {`${Number(data.value).toFixed(1)}%`}
                  </div>
                </div>
              ))}
            </div>
          </ScrollTransition>
        </>
      )}
    </div>
  )
}

export default PieChart
