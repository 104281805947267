export const contentHeader = (token = localStorage.getItem('access_token')) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

export const authHeader = (token = localStorage.getItem('access_token')) =>
  ({ Authorization: `Bearer ${token}` })

export const auth = 'auth'
export const content = 'content'
