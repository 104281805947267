import React from 'react'
import { connect } from 'react-redux'

import PresenterCard from './PresenterCard'
import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import ScrollTransition from '../helpers/transition/ScrollTransition'
import Button from '../UI/Button'
import SmallSpin from '../UI/Spin/SmallSpin'

const PresentersList = ({
  presenters,
  onListScroll,
  onActivateBtnClick,
  onDelete,
  onPresenterStop,
  onCreateScreenClick,
  loading,
  compact,
}) => (
  <div className='container__right_side' style={{ paddingLeft: 0, overflow: 'visible' }}>
    <div className='button_container__add_presenter'>
      <Button
        styles='bordered_btn__flex__end'
        icon='icon__add_lecture__rounded'
        title='Создать экран'
        onClick={onCreateScreenClick}
      />
    </div>
    <ScrollTransition loading onScroll={onListScroll}>
      <div className='card_list' style={{ padding: '3px 17px 3px 3px' }}>
        {presenters?.map((p, index) => (
          <PresenterCard
            key={p.id}
            presenter={p}
            index={index}
            onActivateBtnClick={onActivateBtnClick}
            onDelete={onDelete}
            onPresenterStop={onPresenterStop}
            compact={compact}
          />
        ))}
        {loading && <SmallSpin />}
        {(!presenters || presenters?.length === 0)
          && <EmptyPlaceholder text='Список экранов пуст' />}
      </div>
    </ScrollTransition>
  </div>
)

const mapStateToProps = ({ presenters }) => ({
  presenters: presenters.list,
  loading: presenters.loading,
})

export default connect(mapStateToProps)(PresentersList)
