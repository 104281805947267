import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import setAvatarStyle from '../../utils/getStyle/getAvatarStyle'
import { removeTokenFromStorage } from '../../utils/manageTokens'
import UserPopUpInfo from './UserPopUpInfo'

const UserInfo = ({ name, email, history, firstName, photo }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const logoClassNames = 'flex_container flex_container__center user_info__inner'

  const logOut = () => removeTokenFromStorage(() => history.push('/auth'))

  const getArrowStyle = () => ({ transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)' })

  const titlePlaceholderStyle = { height: '16px', width: '40%', backgroundColor: '#242B37', marginRight: '7%' }

  return (
    <div className='user_info'>
      <div className={logoClassNames} onClick={() => setIsExpanded(!isExpanded)}>
        {firstName
          ? <span className='user_name'>{firstName}</span>
          : <div style={titlePlaceholderStyle} />}

        {photo
          ? (
            <span className='card_list__item_avatar'>
              <div className='card_list__avatar' style={setAvatarStyle(photo)} />
            </span>
          )
          : (
            <span className='card_list__item_avatar'>
              <div className='card_list__avatar' />
            </span>
          )}
        <div className='expand_arrow' style={getArrowStyle()} />
      </div>
      <UserPopUpInfo
        name={name}
        email={email}
        closePopUp={() => setIsExpanded(false)}
        isExpanded={isExpanded}
        onLogOut={logOut}
      />
    </div>
  )
}

const mapStateToProps = ({ account }) => {
  const user = account?.account
  if (user) {
    const { photo, firstname, middlename, lastname } = user
    return {
      photo: { id: photo?.id, path: photo?.path },
      firstName: firstname || '',
      name: `${lastname} ${firstname}${middlename ? ` ${middlename}` : ''}`,
      email: user.email || 'Не указан',
    }
  }
  return {
    photo: null,
    firstName: '',
    name: 'Не указано',
    email: 'Не указан',
  }
}

export default withRouter(connect(mapStateToProps)(UserInfo))
