import React from 'react'
import Button from '../Button'
import Modal from './Modal'

const isEqual = (prevProps, nextProps) => {
  if (nextProps.id !== prevProps.id) return false
  if (nextProps.id === null) return true
}

const ConfirmationModal = ({
  start,
  onClose,
  onConfirm,
  text,
  children,
  title = 'ПОДТВЕРЖДЕНИЕ',
  confirmBtnText = 'Ок',
  style,
}) => (
  <>
    {start && (
      <Modal extraClassName='modal_confirmation' start={start} onClose={onClose} style={style}>
        <span className='large_btn__title__center border_bottom'>{title}</span>
        {text && (
          <span
            className='main_text margin_top'
            style={{ marginBottom: '50px', wordBreak: 'break-word' }}
          >
            {text}
          </span>
        )}
        {children}
        <div className='modal__button_wrapper text__break_all'>
          <Button styles='simple_btn' title='Отмена' onClick={onClose} />
          <Button
            styles='bordered_btn__save_lecture'
            title={confirmBtnText}
            onClick={onConfirm}
            delay={300}
          />
        </div>
      </Modal>
    )}
  </>
)

export default React.memo(ConfirmationModal, isEqual)
