import moment from 'moment'
import React from 'react'
import { withRouter } from 'react-router-dom'

import ScrollTransition from '../../helpers/transition/ScrollTransition'
import CardListItem from '../../helpers/lists/CardListItem'
import EventsCardListItem from './EventsCardListItem'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'

const EventsList = ({ list, onEventDelete, history, loading }) => {
  const getMarkerStyle = date => {
    const basic = 'round_marker'
    if (moment().isAfter(moment(date), 'day')) return `yellow_${basic}`
    return `blue_${basic}`
  }

  const redirectToEventEdit = id => history.push(`/events/${id}/edit`)

  const redirectToEventCard = id => history.push(`/events/${id}`)

  return (
    <ScrollTransition loading={!loading}>
      <div className='card_list'>
        {list?.map((e, index) => (
          <CardListItem
            key={e.id}
            style={{ transitionDelay: `${0.2 * index}s` }}
            onDelete={() => onEventDelete(e)}
            onEdit={() => redirectToEventEdit(e?.id)}
            onCardClick={() => redirectToEventCard(e?.id)}
          >
            <EventsCardListItem event={e} index={index} marker={getMarkerStyle(e.date)} />
          </CardListItem>
        ))}
        {(!list || list.length === 0) && <EmptyPlaceholder text='Список событий пуст' />}
      </div>
    </ScrollTransition>
  )
}

export default withRouter(EventsList)
