import React from 'react'
import * as Yup from 'yup'
import moment from 'moment'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { ErrorMessage, withFormik } from 'formik'

import Modal from '../../../UI/modal/Modal'
import ValidationMsg from '../../../UI/ValidationMsg'
import SelectWithSearch from '../../../UI/input/SelectWithSearch'
import getFullName from '../../../../utils/getFullName'
import SimpleFileUploader from '../fileUploader/SimpleFileUploader'
import TagPicker from '../../../UI/input/TagPicker'
import DateTimePick from '../../../UI/input/DateTimePick'
import SimpleInput from '../../../UI/input/SimpleInput'
import Button from '../../../UI/Button'
import ScrollTransition from '../../../helpers/transition/ScrollTransition'
import { formatDateFromTwoFields } from '../../../../utils/formatDate'
import { getMaxLengthMsg } from '../../../../utils/validation'
import { CLEAR_FILES } from '../../../../actions/files'
import { GET_USERS } from '../../../../actions/users'
import {
  ADD_LECTURE_TO_NEW_EVENT,
  CHANGE_LECTURE_IN_NEW_EVENT,
  CLEAR_LECTURE_NEW_PAPERS,
  CLEAR_NEW_LECTURE,
  HANDLE_NEW_LECTURE_CHANGE,
  POST_LECTURE, PUT_LECTURE,
} from '../../../../actions/lectures'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Минимальная длина - 3 символа')
    .max(120, field => getMaxLengthMsg(120, field))
    .required('Обязательное поле'),
  place: Yup.string()
    .max(120, field => getMaxLengthMsg(120, field)),
})

class InnerLectureForm extends React.Component {
  modalStyle = {
    height: '82%',
    maxHeight: 'min-content',
    width: '400px',
    minHeight: '500px',
    minWidth: '400px',
  }

  componentDidMount() {
    this.props.getUsers()
  }

  componentWillUnmount() {
    this.props.clearCurrentFiles()
    this.props.clearNewLecture()
  }

  getStyle = (height = '35px', width = '100%', margin = '4px') =>
    ({ width, height, marginBottom: margin })

  onChange = (fieldName, value) => {
    const { isNew, handleNewLectureChange, setFieldValue } = this.props
    isNew ? handleNewLectureChange(fieldName, value) : setFieldValue(fieldName, value)
  }

  getBaseLectureAttrs = () => {
    const { title, tags, place, startDate, startTime } = this.props.values
    const formattedDate = formatDateFromTwoFields(startDate, startTime)
    return {
      title: title,
      tags: tags || [],
      date: formattedDate,
      place: place,
    }
  }

  packSpeaker = speaker => ({
    speaker: {
      id: speaker?.id || null,
      name: speaker?.name || '',
      photo: { path: speaker?.photo.path || null },
    },
  })

  getPapersList = () => this.props.lectureNewPapers
    ? [...this.props.values.papers, ...this.props.lectureNewPapers]
    : this.props.values.papers

  putLectureInNewEvent = lectureAttrs => {
    const { changeLectureInNewEvent, values, setActiveLecture } = this.props
    const { speaker } = values

    changeLectureInNewEvent({
      ...lectureAttrs,
      ...this.packSpeaker(speaker),
      papers: this.getPapersList(),
    })

    setActiveLecture(null)
  }

  postLectureInNewEvent = baseLectureAttrs => {
    const {
      addLectureToNewEvent,
      setActiveLecture,
      clearCurrentFiles,
      values,
      newLecturePapers,
    } = this.props
    const { papers, speaker } = values

    addLectureToNewEvent({
      ...baseLectureAttrs,
      ...this.packSpeaker(speaker),
      papers: papers || [],
      newPapers: newLecturePapers || [],
    })

    setActiveLecture(null)
    clearCurrentFiles()
  }

  onModalClose = () => {
    const {
      lecture,
      isNew,
      newObject,
      setActiveLecture,
      clearNewLecture,
      clearLectureNewPapers,
      clearCurrentFiles,
    } = this.props

    setActiveLecture(null)
    clearNewLecture()
    isNew ? clearCurrentFiles() : clearLectureNewPapers(lecture.id, newObject)
  }

  getPapersIdList = () => {
    const newPapersId = this.props.isNew
      ? this.props.newLecturePapers?.map(p => p.id) || []
      : this.props.lectureNewPapers?.map(p => p.id) || []
    const oldPapers = this.props.values.papers?.length ? this.props.values.papers : []

    return [...oldPapers, ...newPapersId]
  }

  saveLecture = () => {
    const {
      event,
      isNew,
      lecture,
      newObject,
      setActiveLecture,
      setTouched,
      values,
      postLecture,
      putLecture,
    } = this.props
    const { speaker } = values

    setTouched({ title: true, place: true })
    if (!ValidationSchema.isValidSync(values)) return
    const baseLectureAttrs = this.getBaseLectureAttrs()

    if (newObject && isNew) {
      this.postLectureInNewEvent(baseLectureAttrs)
      return
    }

    let lectureAttrs = { ...baseLectureAttrs, id: lecture?.id }

    if (newObject && !isNew) {
      this.putLectureInNewEvent(lectureAttrs)
      return
    }

    lectureAttrs = {
      ...lectureAttrs,
      event: event.id,
      speaker: speaker.id || null,
      papers: this.getPapersIdList(),
    }

    isNew ? postLecture({ ...lectureAttrs }) : putLecture({ ...lectureAttrs })
    setActiveLecture(null)
  }

  getErrorCondition = field => this.props.errors[field] && this.props.touched[field]

  getNewPapersArr = () => {
    const { isNew, newLecturePapers, lectureNewPapers } = this.props
    if (isNew) return newLecturePapers?.length > 0 ? newLecturePapers : []
    return lectureNewPapers?.length > 0 ? lectureNewPapers : []
  }

  render() {
    const {
      isNew,
      lecturePapers,
      newLecturePapers,
      newObject,
      setActiveLecture,
      setFieldTouched,
      values,
      users,
      getUsers,
      start,
    } = this.props
    return (
      <Modal start={!!start} onClose={this.onModalClose} style={this.modalStyle}>
        <span className='large_btn__title__center border_bottom'>{isNew ? 'НОВЫЙ ' : ''}ДОКЛАД</span>
        <ScrollTransition loading style={{ flex: 1, width: 'calc(100% + 10px)' }}>
          <div className='flex_container__column' style={{ padding: '10px 10px 0 2px' }}>
            <SimpleInput
              name='title'
              label='Название доклада*'
              value={values.title}
              setFieldTouched={setFieldTouched}
              onChange={this.onChange}
              placeholder='Введите название доклада'
              error={this.getErrorCondition('title')}
            >
              <ErrorMessage component={ValidationMsg} name='title' />
            </SimpleInput>
            <TagPicker
              fieldName='tags'
              label='Тематические теги'
              tags={values.tags}
              onConfirm={this.onChange}
            />
            <DateTimePick
              dateFieldName='startDate'
              timeFieldName='startTime'
              label='Время начала'
              dateValue={values.startDate}
              timeValue={values.startTime}
              onChange={this.onChange}
              onBlur={setFieldTouched}
            />
            <SelectWithSearch
              targetField='name'
              idField='id'
              fieldsToReturn={['photo']}
              name='speaker'
              label='Kем'
              source={users}
              value={values.speaker}
              style={{ width: '100%' }}
              setFieldTouched={setFieldTouched}
              onChange={this.onChange}
              onSearch={getUsers}
              toShownString={getFullName}
              placeholder='Выберите пользователя'
            />
            <SimpleInput
              name='place'
              label='Офис/Кабинет'
              value={values.place}
              style={this.getStyle('35px', '100%')}
              setFieldTouched={setFieldTouched}
              onChange={this.onChange}
              placeholder='Место проведения'
              error={this.getErrorCondition('place')}
            >
              <ErrorMessage component={ValidationMsg} name='place' />
            </SimpleInput>
            <SimpleFileUploader
              isNew={isNew}
              newObject={newObject}
              label='Материалы'
              lectureId={values.id}
              papers={isNew ? values.papers : lecturePapers}
              newPapers={this.getNewPapersArr()}
            />
          </div>
        </ScrollTransition>
        <div className='button_container__content_right' style={{ marginTop: '10px' }}>
          <Button
            styles='simple_btn'
            title='Отмена'
            onClick={() => setActiveLecture(null)}
          />
          <Button
            styles='bordered_btn__save_lecture'
            title='Сохранить доклад'
            onClick={this.saveLecture}
          />
        </div>
      </Modal>
    )
  }
}

const EditLectureModal = withFormik({
  enableReinitialize: true,
  mapPropsToValues({
    lecture,
    isNew,
    lectureTags,
    lecturePapers,
    newLecture,
    currentFiles,
    newObject,
  }) {
    if (isNew) {
      const dataMoment = moment()
      const isNewAndNotEmpty = (!_.isEmpty(newLecture) && newLecture)
      return {
        title: isNewAndNotEmpty ? newLecture.title : '',
        speaker: isNewAndNotEmpty
          ? {
            id: newLecture.speaker?.id || null,
            name: newLecture.speaker?.name || '',
            photo: { path: newLecture.speaker?.photo?.path || null },
          }
          : { id: null, name: '', photo: { path: null } },
        tags: isNewAndNotEmpty ? newLecture?.tags : [],
        place: isNewAndNotEmpty ? newLecture?.place : '',
        startDate: isNewAndNotEmpty ? moment(newLecture.startDate) : dataMoment,
        startTime: isNewAndNotEmpty ? moment(newLecture.startTime) : dataMoment,
        papers: isNewAndNotEmpty ? newLecture?.papers : [],
      }
    }

    const dataMoment = moment(lecture?.date)
    const newPapers = () => {
      if (lecturePapers) return (newObject) ? lecturePapers : lecturePapers.map(p => p?.id)
      return []
    }
    return {
      id: lecture?.id,
      title: lecture?.title,
      speaker: {
        id: lecture?.speaker?.id || null,
        name: lecture?.speaker?.name || '',
        photo: { path: lecture?.speaker?.photo?.path || null },
      },
      tags: lectureTags,
      place: newObject ? lecture.place : lecture?.place?.title || '',
      startDate: dataMoment,
      startTime: dataMoment,
      papers: newPapers(),
    }
  },
  validationSchema: ValidationSchema,
})(InnerLectureForm)

const mapStateToProps = ({ events, files, users }, ownProps) => {
  const { lecture, activeItem, isNew } = ownProps
  return {
    isNew,
    activeItem,
    users: users?.list,
    event: events?.event,
    lectureTags: lecture?.tags,
    lecturePapers: lecture?.papers,
    lectureNewPapers: lecture?.newPapers,
    newLecture: events?.newLecture,
    currentFiles: files?.currentFiles,
    newLecturePapers: events?.newLecture?.newPapers,
  }
}

const mapDispatchToProps = dispatch => ({
  addLectureToNewEvent: lecture => dispatch({ type: ADD_LECTURE_TO_NEW_EVENT, lecture: lecture }),
  changeLectureInNewEvent: lecture => dispatch({ type: CHANGE_LECTURE_IN_NEW_EVENT, lecture }),
  postLecture: lecture => dispatch({ type: POST_LECTURE, lecture }),
  putLecture: lecture => dispatch({ type: PUT_LECTURE, lecture }),
  clearCurrentFiles: () => dispatch({ type: CLEAR_FILES }),
  clearNewLecture: () => dispatch({ type: CLEAR_NEW_LECTURE }),
  getUsers: (searchString, page) => dispatch({ type: GET_USERS, searchString, page }),
  handleNewLectureChange: (field, value) =>
    dispatch({ type: HANDLE_NEW_LECTURE_CHANGE, field, value }),
  clearLectureNewPapers: (lectureId, newObject) =>
    dispatch({ type: CLEAR_LECTURE_NEW_PAPERS, lectureId, newObject }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditLectureModal)
