import React from 'react'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import getExtremeScaleValues from './helpers/getExtremeScaleValues'

import {
  verticalBarChartOptions,
  verticalBarChartThickness,
  verticalBarChartBorderWidth,
} from './setups/verticalBarChartSetups'

const VerticalBarChart = ({ source, colors }) => {
  const getCroppingLength = source => {
    const { length } = source

    switch (true) {
      case length < 2: return 13
      case length >= 2 && length < 4: return 9
      case length >= 4 && length < 8: return 6
      default: return 4
    }
  }

  const getChartDataObject = () => {
    const labelsLength = getCroppingLength(source)
    const labelsArr = source.map(({ title }) =>
      title.length > labelsLength ? `${title.substring(0, labelsLength)}...` : title)
    const valuesArr = source.map(data => data.value)

    const chartData = {
      labels: labelsArr,
      datasets: [{
        barThickness: verticalBarChartThickness,
        borderWidth: verticalBarChartBorderWidth,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        data: valuesArr,
      }],
    }

    return chartData
  }

  return (
    <div className='chart_vertical_bar__wrapper'>
      <Bar
        data={getChartDataObject()}
        options={verticalBarChartOptions(getExtremeScaleValues(source))}
      />
    </div>
  )
}

export default VerticalBarChart
