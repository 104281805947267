import React from 'react'
import ScreensPopUp from './ScreensPopUp'
import FadeTransition from '../../helpers/transition/FadeTransition'

const OnAirSwitch = ({
  chartOnAir,
  name,
  activePresenter,
  switchActivePopUp,
  onSwitchClick,
  onScreenSelect,
  setSwitchActivePopUp,
}) => {
  const getBtnStyle = () => {
    const btnStyle = {
      bgBasicStyle: 'on_air_switch__btn',
      dotBasicStyle: 'on_air_switch__btn_circle',
      titleBasicStyle: 'on_air_switch__title',
    }
    if ((chartOnAir === name) && activePresenter) return {
      bgBasicStyle: btnStyle.bgBasicStyle + '__active',
      dotBasicStyle: btnStyle.dotBasicStyle + '__active',
      titleBasicStyle: btnStyle.titleBasicStyle + '__active',
    }
    if (switchActivePopUp === name) return {
      bgBasicStyle: btnStyle.bgBasicStyle + '__focused',
      dotBasicStyle: btnStyle.dotBasicStyle + '__focused',
      titleBasicStyle: btnStyle.titleBasicStyle + '__focused',
    }
    return btnStyle
  }

  const btnStyle = getBtnStyle()

  return (
    <div className='on_air_switch__block'>
      <div className={btnStyle.titleBasicStyle}>В Эфире</div>
      <div className={btnStyle.bgBasicStyle} onClick={onSwitchClick}>
        <div className={btnStyle.dotBasicStyle} />
      </div>
      <FadeTransition loading={switchActivePopUp === name}>
        <ScreensPopUp
          isVisible={switchActivePopUp === name}
          setSwitchActivePopUp={setSwitchActivePopUp}
          activePresenter={activePresenter}
          setActivePresenter={onScreenSelect}
        />
      </FadeTransition>
    </div>
  )
}

export default OnAirSwitch
