import React from 'react'
import { Tooltip } from 'antd'

const PresenterCardListItem = ({
  presenter,
  index,
  onActivateBtnClick,
  onPresenterStop,
  compact,
}) => {
  const { title, description, status } = presenter

  const btnStyle = {
    style() {
      const basicStyle = 'card_list__item__presenter_status'
      switch (status) {
        case 'activated': return `${basicStyle}__active`
        case 'waiting': return `${basicStyle}__waiting`
        default: return `${basicStyle}`
      }
    },
    title() {
      switch (status) {
        case 'activated': return 'Активирован'
        case 'waiting': return 'Ожидание'
        default: return 'Не активирован'
      }
    },
  }

  const onActivate = e => e.stopPropagation(onActivateBtnClick(presenter), e)

  const onStop = e => e.stopPropagation(onPresenterStop(presenter), e)

  return (
    <>
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item_screen_name'>{title}</span>
      <span className='card_list__item__presenter_description'>
        {description || <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
      </span>

      <div className='flex_container' style={{ marginRight: '10px' }}>
        <div className='card_list__item__presenter_stop_btn'>
          {presenter.on_air && (
            <div className='rounded_btn__red' onClick={onStop}>Стоп</div>)}
        </div>

        <div
          className='card_list__item__presenter_status_block'
          style={{ minWidth: compact ? '12px' : null }}
        >
          {compact
            ? (
              <Tooltip title={btnStyle.title()}>
                <div
                  style={{ width: '12px', borderRadius: '13px', height: '12px', padding: '6px' }}
                  className={btnStyle.style()}
                  onClick={onActivate}
                />
              </Tooltip>
            )
            : (
              <div className={btnStyle.style()} onClick={onActivate}>
                {btnStyle.title()}
              </div>
            )}
        </div>
      </div>
    </>
  )
}

export default PresenterCardListItem
