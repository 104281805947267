export const POST_LECTURE = 'POST_LECTURE'
export const POST_LECTURE_SUCCEED = 'POST_LECTURE_SUCCEED'
export const POST_LECTURE_FAILED = 'POST_LECTURE_FAILED'

export const PUT_LECTURE = 'PUT_LECTURE'
export const PUT_LECTURE_SUCCEED = 'PUT_LECTURE_SUCCEED'
export const PUT_LECTURE_FAILED = 'PUT_LECTURE_FAILED'

export const DELETE_LECTURE = 'DELETE_LECTURE'
export const DELETE_LECTURE_SUCCEED = 'DELETE_LECTURE_SUCCEED'
export const DELETE_LECTURE_FAILED = 'DELETE_LECTURE_FAILED'

export const ADD_LECTURE_TO_NEW_EVENT = 'ADD_LECTURE_TO_NEW_EVENT'
export const CHANGE_LECTURE_IN_NEW_EVENT = 'CHANGE_LECTURE_IN_NEW_EVENT'

export const CLEAR_LECTURES = 'CLEAR_LECTURES'

export const HANDLE_NEW_LECTURE_CHANGE = 'HANDLE_NEW_LECTURE_CHANGE'
export const CLEAR_NEW_LECTURE = 'CLEAR_NEW_LECTURE'

export const GET_LECTURE = 'GET_LECTURE'
export const GET_LECTURE_SUCCEED = 'GET_LECTURE_SUCCEED'

export const CLEAR_LECTURE_NEW_PAPERS = 'CLEAR_LECTURE_NEW_PAPERS'
