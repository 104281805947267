import React from 'react'

import stopPropagation from '../../../utils/stopPropagation'

const UserInGroupItem = ({ index, user }) => (
  <div className='card_list__item__user__no_hover' onClick={e => stopPropagation(() => null, e)}>
    <span className='card_list__item_id'>{`${index + 1}.`}</span>
    <span className='card_list__item__group_title'>{user?.name}</span>
    <span className='card_list__item_description'>
      {user?.description
        ? <span className='secondary_text'>{user?.description}</span>
        : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
    </span>
  </div>
)

export default UserInGroupItem
