import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { ErrorMessage, Form as FormikForm, withFormik } from 'formik'

import citiesSource from '../../../assets/cities'
import DatePick from '../../UI/input/DatePick'
import generalCitiesSource from '../../../assets/generalCities'
import getFullCityName from '../../../utils/getFullCityName'
import getStyle from '../../../utils/getStyle/getStyleUserForm'
import packUser from '../../../utils/objects/packUser'
import RadioGroup from '../../UI/input/RadioGroup'
import ScrollTransition from '../../helpers/transition/ScrollTransition'
import SelectWithSearch from '../../UI/input/SelectWithSearch'
import SimpleImageUploader from './SimpleImageUploader'
import SimpleInput from '../../UI/input/SimpleInput'
import TextAreaSimple from '../../UI/input/TextAreaSimple'
import ValidationMsg from '../../UI/ValidationMsg'
import ValidationSchema from './userValidationSchema'
import { CLEAR_FILES } from '../../../actions/files'
import { CREATE_USER, DELETE_USER, GET_USERS } from '../../../actions/users'
import { setDefaultCursorStyle, setWaitCursorStyle } from '../../../utils/cursorStyleSwitch'

const InnerForm = ({ setFieldValue, setFieldTouched, values, touched, errors }) => {
  const [citiesList, setCitiesList] = useState(generalCitiesSource)

  const halfWidthStyle = { maxWidth: '47%' }

  const getCities = searchVal => {
    if (searchVal.length < 3) {
      setCitiesList(generalCitiesSource)
      return null
    }
    setCitiesList(citiesSource.filter(city =>
      city.name.toLowerCase().includes(searchVal.toLowerCase())))
  }

  const onPhoneFieldFocus = () =>
    !values.phone_number ? setFieldValue('phone_number', '+') : null

  const onPhoneFieldBlur = () =>
    values.phone_number === '+' ? setFieldValue('phone_number', '') : null

  const getErrorCondition = field => errors[field] && touched[field]

  return (
    <div className='container__left_side__new_user_form'>
      <ScrollTransition loading style={{ display: 'flex', flexDirection: 'column' }}>
        <FormikForm>
          <SimpleInput
            name='firstName'
            label='Имя*'
            placeholder='Имя пользователя'
            value={values.firstName}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('firstName')}
          >
            <ErrorMessage component={ValidationMsg} name='firstName' />
          </SimpleInput>
          <SimpleInput
            name='lastName'
            label='Фамилия*'
            placeholder='Фамилия пользователя'
            value={values.lastName}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('lastName')}
          >
            <ErrorMessage component={ValidationMsg} name='lastName' />
          </SimpleInput>

          <div className='flex_container flex_container__space_between'>
            <div className='flex_container__flex_1 radio_group__horizontal' style={halfWidthStyle}>
              <DatePick
                label='Дата рождения*'
                name='birthday'
                value={values.birthday}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                format='DD.MM.YYYY'
                disabledDate={current => current && current > moment().endOf('day')}
                error={getErrorCondition('birthday')}
              >
                <ErrorMessage component={ValidationMsg} name='birthday' />
              </DatePick>
            </div>
            <div className='flex_container__flex_1 radio_group__horizontal' style={halfWidthStyle}>
              <RadioGroup
                name='sex'
                label='Пол'
                value={values.sex}
                setFieldTouched={setFieldTouched}
                onChange={setFieldValue}
                options={['М', 'Ж']}
              />
            </div>
          </div>
          <SelectWithSearch
            label='Город*'
            name='city'
            value={values.city}
            placeholder='Начните вводить название города'
            onChange={setFieldValue}
            onSearch={getCities}
            setFieldTouched={setFieldTouched}
            source={citiesList}
            targetField='name'
            idField='id'
            style={{ width: '100%' }}
            toShownString={getFullCityName}
            error={getErrorCondition('city')}
          >
            {getErrorCondition('city') && <ValidationMsg>{errors.city.id}</ValidationMsg> }
          </SelectWithSearch>

          <SimpleInput
            name='email'
            label='Email*'
            placeholder='Адрес электронной почты'
            value={values.email}
            style={getStyle()}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('email')}
          >
            <ErrorMessage component={ValidationMsg} name='email' />
          </SimpleInput>
          <SimpleInput
            name='phone_number'
            label='Телефон*'
            value={values.phone_number}
            placeholder='+***********'
            style={getStyle()}
            onChange={setFieldValue}
            onFocus={onPhoneFieldFocus}
            onBlur={onPhoneFieldBlur}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('phone_number')}
          >
            <ErrorMessage component={ValidationMsg} name='phone_number' />
          </SimpleInput>
          <TextAreaSimple
            name='description'
            label='Должность/Описание'
            placeholder='Описание пользователя'
            value={values.description}
            style={{ height: '34px' }}
            setFieldTouched={setFieldTouched}
            onChange={setFieldValue}
            error={getErrorCondition('description')}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </TextAreaSimple>
          <SimpleImageUploader label='Фото' isNew />
          <div className='flex_container flex_container__flex_1'>
            <div className='button_container__content_right' style={{ alignSelf: 'flex-end' }}>
              <button type='submit' className='rounded_btn__save_event'>
                <span className='large_btn__title'>Создать пользователя</span>
              </button>
            </div>
          </div>
        </FormikForm>
      </ScrollTransition>
    </div>
  )
}

const NewUser = withFormik({
  mapPropsToValues: () => ({
    description: '',
    email: '',
    firstName: '',
    lastName: '',
    phone_number: '',
    sex: 'М',
    birthday: null,
    city: { id: null },
  }),
  validationSchema: ValidationSchema,
  handleSubmit: async (values, { props, resetForm }) => {
    await setWaitCursorStyle()
    await props.createUser(packUser(values), props.userPhoto, () => {
      setDefaultCursorStyle()
      resetForm()
      props.clearPhoto()
    })
  },
})(InnerForm)

const mapStateToProps = ({ files, users }) =>
  ({ userPhoto: files.photo, users: users.list, loading: users.loading, count: users.count })

const mapDispatchToProps = dispatch => ({
  clearPhoto: () => dispatch({ type: CLEAR_FILES }),
  createUser: (user, photo, callback) => dispatch({ type: CREATE_USER, user, photo, callback }),
  deleteUser: userId => dispatch({ type: DELETE_USER, userId }),
  getUsers: page => dispatch({ type: GET_USERS, page }),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewUser)
