import React from 'react'
import { Tag } from 'antd'

import DisabledInputBlock from '../../UI/DisabledInputBlock'
import ScrollTransition from '../../helpers/transition/ScrollTransition'

const GroupInfo = ({ group = {} }) => {
  const { global_read, global_write, global_manage, title, description } = group
  const rights = [
    { title: 'Чтение', status: global_read },
    { title: 'Изменение', status: global_write },
    { title: 'Менеджмент', status: global_manage }]

  const isRightsEnabled = global_read || global_write || global_manage

  return (
    <div className='container__left_side__user_group_form'>
      <ScrollTransition loading>
        <div style={{ paddingRight: '22px' }}>
          <DisabledInputBlock title='Название' subtitle={title || 'Не указано'} />
          <DisabledInputBlock title='Описание' subtitle={description || 'Не указано'} />
          <DisabledInputBlock title='Права доступа'>
            {isRightsEnabled
              ? (
                <div className='info__block__display'>
                  {rights.map(r => r.status && <Tag key={r.title}>{r.title}</Tag>)}
                </div>
              )
              : <span className='main_text__white'>Не указано</span>}
          </DisabledInputBlock>
        </div>
      </ScrollTransition>
    </div>
  )
}

export default GroupInfo
