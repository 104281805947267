import React from 'react'
import { withRouter, Prompt } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { Tooltip } from 'antd'

import Button from '../UI/Button'
import chartsCategories from '../charts/helpers/chartsCategories'
import checkIfGrouped from '../charts/helpers/checkIfGrouped'
import Header from '../Header/Header'
import QR from '../UI/icons/QR'
import RightBlock from '../RightBlock'
import ScreensCard from '../Screens/ScreensCardSideBar/ScreensCard'
import SeparatedCardTitle from '../helpers/cards/SeparatedCardTitle'
import SmallChartCard from '../helpers/cards/SmallChartCard'
import ConfirmationModal from '../UI/modal/ConfirmationModal'
import ModalQRTranslation from './ModalQRTranslation'
import { formatDate } from '../../utils/formatDate'
import { isDateBeforeNow } from '../../utils/isDateBeforeToday'
import { GET_POLL_STATISTIC, CLEAN_VOTING, PUT_POLL } from '../../actions/poll'
import {
  GET_PRESENTERS,
  START_PRESENTER_SESSION,
  STOP_PRESENTER_SESSION,
  CHANGE_CHART_ON_PRESENTER, CLEAR_PRESENTS,
} from '../../actions/presenters'


const DEFAULT_CHART_ON_AIR = { name: null, type: null, content: undefined }

const GROUPED_CHART_TYPES = ['bar', 'horizontal_bar']

class VotingResult extends React.PureComponent {
  state = {
    chartOnAir: DEFAULT_CHART_ON_AIR,
    activePresenter: null,
    switchActivePopUp: null,
    isLeaveConfirmShow: false,
    qrTranslation: false,
    stopVotingConfirmVisible: false,
    isStoppedNow: false,
  }

  closeText = 'Вы уверены, что хотите покинуть страницу? Трансляция будет остановлена.'

  componentDidMount() {
    const { getPollStatistic, match, getPresenters, stopPresenterSession } = this.props

    const previousActivePresenter = localStorage.getItem('last_presenter')
    previousActivePresenter && stopPresenterSession(previousActivePresenter)

    getPollStatistic(match.params.id)
    getPresenters()
    this.setListeners()
    localStorage.removeItem('last_presenter')
  }

  componentDidUpdate(prevProps, prevState) {
    const { activePresenter } = this.state
    if (prevState.activePresenter !== activePresenter)
      activePresenter
        ? localStorage.setItem('last_presenter', activePresenter)
        : localStorage.removeItem('last_presenter')
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.showReloadConfirm)
    window.removeEventListener('unload', this.stopTranslating)
    this.unlisten()
  }

  setListeners = () => {
    window.addEventListener('beforeunload', this.showReloadConfirm)
    window.addEventListener('unload', this.stopTranslating)
    this.unlisten = this.props.history.listen(this.stopTranslating)
  }

  showReloadConfirm = e => {
    e.preventDefault()
    if (!this.state.activePresenter) return
    e.returnValue = this.closeText
  }

  stopTranslating = () => {
    const { activePresenter } = this.state
    const { cleanVoting, cleanPresenters, stopPresenterSession } = this.props

    cleanVoting()
    cleanPresenters()
    activePresenter && stopPresenterSession(activePresenter)
  }

  onTranslationErrorCallback = () => this.setState({ chartOnAir: DEFAULT_CHART_ON_AIR })

  setChartOnAir = ({ name, type, presenter, content = undefined }) => {
    const { chartOnAir } = this.state
    const isTheSameChart = (name === chartOnAir.name && type === chartOnAir.type)

    const nextChartOnAir = isTheSameChart ? DEFAULT_CHART_ON_AIR : { name, type, content }
    this.setState({ chartOnAir: nextChartOnAir }, () => this.onAirHandle(presenter))
  }

  packStatistic = presenter => {
    const { match } = this.props
    const { chartOnAir, activePresenter } = this.state

    return ({
      poll: match.params.id,
      presenter: presenter || activePresenter,
      graph_data: { ...chartOnAir, grouped: checkIfGrouped(chartOnAir.name) },
    })
  }

  showChartOnPresenter = presenter => {
    const { activePresenter } = this.state
    const { startPresenterSession, changeChartOnPresenter } = this.props

    const statisticObj = this.packStatistic(presenter)

    activePresenter
      ? changeChartOnPresenter(statisticObj, this.onTranslationErrorCallback)
      : startPresenterSession(statisticObj, this.onTranslationErrorCallback)
  }

  onAirHandle = presenter => {
    const { chartOnAir, activePresenter } = this.state
    const { stopPresenterSession } = this.props

    chartOnAir.name
      ? this.showChartOnPresenter(presenter)
      : this.setState({ activePresenter: null }, () => stopPresenterSession(activePresenter))
  }

  changeActivePresenter = id => {
    if (id === this.state.activePresenter) {
      this.setState({ activePresenter: null }, () => this.props.stopPresenterSession(id))
      return
    }
    const prevPresenter = this.state.activePresenter
    this.setState({ activePresenter: id },
      () => prevPresenter && this.props.stopPresenterSession(prevPresenter))
  }

  getBasicSmallChartCardSetups = () => {
    const { chartOnAir, activePresenter, switchActivePopUp } = this.state
    const { loading } = this.props

    return ({
      loading,
      chartOnAir,
      activePresenter,
      switchActivePopUp,
      setChartOnAir: this.setChartOnAir,
      setSwitchActivePopUp: name => this.setState({ switchActivePopUp: name }),
      setActivePresenter: id => this.setState({ activePresenter: id }),
    })
  }

  getQRModalSetups = () => {
    const { activePresenter, switchActivePopUp, chartOnAir } = this.state

    return ({
      name: 'QR',
      start: this.state.qrTranslation,
      onClose: () => this.toggleQRModal(false),
      activePresenter,
      switchActivePopUp,
      chartOnAir,
      setChartOnAir: this.setChartOnAir,
      setSwitchActivePopUp: name => this.setState({ switchActivePopUp: name }),
      setActivePresenter: id => this.setState({ activePresenter: id }),
    })
  }

  onSucceedStopVoting = () =>
    this.setState({ isStoppedNow: true, stopVotingConfirmVisible: false })

  stopVoting = () => {
    const { putPoll, currentPoll } = this.props
    const poll = { id: currentPoll.id, date_to: formatDate(moment()) }
    putPoll(poll, this.onSucceedStopVoting)
  }

  switchStopVotingConfirmVisibility = val => this.setState({ stopVotingConfirmVisible: val })

  getStopVotingConfirmText = () => {
    const { currentPoll } = this.props
    const basic = `Завершить голосование "${currentPoll.title}" сейчас`
    const dateTo = currentPoll.date_to
      && ', вместо ' + moment(currentPoll.date_to).format('HH:mm DD MMM YYYY')

    return `${basic}${dateTo || ''}?`
  }

  getChartSource = category => {
    const { categorized } = this.props?.currentPoll
    return categorized ? categorized[chartsCategories.indexOf(category)] : {}
  }

  toggleQRModal = val => this.setState({ qrTranslation: val })

  renderQRModalIcon = () => (
    <Tooltip title='Транслировать ссылки на приложение'>
      <div style={{ marginLeft: '20px' }} onClick={() => this.toggleQRModal(true)}><QR /></div>
    </Tooltip>
  )

  getSuffixBtn = () => {
    const finishDate = this.props.currentPoll?.date_to
    const isPollFinished = isDateBeforeNow(finishDate) || this.state.isStoppedNow
    return (
      isPollFinished
        ? <span className='indicator__voting_is_finished'>Голосование завершено</span>
        : (
          <Button
            styles='btn__stop_voting'
            title='Завершить досрочно'
            onClick={() => this.switchStopVotingConfirmVisibility(true)}
            delay={300}
          />
        ))
  }

  render() {
    const { loading, currentPoll, screens } = this.props
    const { chartOnAir, activePresenter, stopVotingConfirmVisible } = this.state

    return (
      <>
        {(chartOnAir?.name || activePresenter) && <Prompt message={this.closeText} />}
        <Header />
        <div style={{ maxHeight: '100%', minHeight: '1px' }}>
          <div className='screen__two_sidebars'>
            <div className='main__card_view'>
              <div className='flex_container__column' style={{ flex: 1 }}>
                <SeparatedCardTitle
                  icon='icon__voting'
                  title={currentPoll.title || 'Название не указано'}
                  suffix={this.getSuffixBtn()}
                  loading={loading}
                >
                  {this.renderQRModalIcon()}
                </SeparatedCardTitle>
                <div className='flex_container' style={{ flex: 1 }}>
                  <SmallChartCard
                    {...this.getBasicSmallChartCardSetups()}
                    title='Распределение по ответам'
                    name='answer_statistic'
                    types={['pie', 'bar', 'horizontal_bar']}
                    cropYLabels
                    source={currentPoll}
                    style={{ flex: 1, marginRight: '30px' }}
                  />
                  <SmallChartCard
                    {...this.getBasicSmallChartCardSetups()}
                    title='Статистика по полу'
                    name='gender_statistic'
                    types={GROUPED_CHART_TYPES}
                    grouped
                    cropYLabels
                    source={this.getChartSource('gender')}
                    style={{ flex: 1 }}
                  />
                </div>
              </div>
              <div className='flex_container' style={{ marginTop: '30px', flex: 1 }}>
                <SmallChartCard
                  {...this.getBasicSmallChartCardSetups()}
                  title='Распределение по регионам'
                  name='district_statistic'
                  types={GROUPED_CHART_TYPES}
                  grouped
                  cropYLabels
                  source={this.getChartSource('district')}
                  style={{ flex: 1, marginRight: '30px' }}
                />
                <SmallChartCard
                  {...this.getBasicSmallChartCardSetups()}
                  title='Статистика по возрасту'
                  name='age_statistic'
                  types={GROUPED_CHART_TYPES}
                  grouped
                  source={this.getChartSource('birthday')}
                  style={{ flex: 1 }}
                />
              </div>
            </div>
            <RightBlock
              bottomBlock={(
                <ScreensCard
                  screens={screens}
                  activePresenter={this.state.activePresenter}
                  setActivePresenter={id => this.changeActivePresenter(id)}
                  setDefaultChartOnAir={this.onTranslationErrorCallback}
                />
              )}
            />
          </div>
          <ModalQRTranslation {...this.getQRModalSetups()} />
          <ConfirmationModal
            start={stopVotingConfirmVisible}
            onClose={() => this.switchStopVotingConfirmVisibility(false)}
            onConfirm={this.stopVoting}
            style={{ minWidth: '400px' }}
            text={this.getStopVotingConfirmText()}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ poll, presenters }) => ({
  currentPoll: poll?.poll || {},
  loading: poll?.loading,
  screens: presenters?.list?.filter(p => (!p.on_air && p.status === 'activated') && p),
})

const mapDispatchToProps = dispatch => ({
  putPoll: (poll, callback) => dispatch({ type: PUT_POLL, poll, callback }),
  stopPresenterSession: presenter => dispatch({ type: STOP_PRESENTER_SESSION, presenter }),
  getPollStatistic: pollId => dispatch({ type: GET_POLL_STATISTIC, pollId }),
  getPresenters: () => dispatch({ type: GET_PRESENTERS, getAll: true }),
  cleanVoting: () => dispatch({ type: CLEAN_VOTING }),
  cleanPresenters: () => dispatch({ type: CLEAR_PRESENTS }),
  startPresenterSession: (payload, errorCallback) =>
    dispatch({ type: START_PRESENTER_SESSION, payload, errorCallback }),
  changeChartOnPresenter: (payload, errorCallback) =>
    dispatch({ type: CHANGE_CHART_ON_PRESENTER, payload, errorCallback }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VotingResult))
