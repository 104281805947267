import React from 'react'
import { CSSTransition } from 'react-transition-group'

const LeftScrollTransition = ({ children, loading }) => (
  <CSSTransition
    in={loading}
    classNames='slide'
    timeout={600}
    appear
    mountOnEnter
    unmountOnExit
  >
    {children}
  </CSSTransition>
)

export default LeftScrollTransition
