import * as _ from 'lodash'
import getFullName from '../utils/getFullName'
import {
  CREATE_USER_SUCCEED,
  DELETE_GROUP_SUCCEED,
  DELETE_USER_SUCCEED,
  GET_ACCESS_GROUP_SUCCEED,
  GET_ACCESS_GROUPS_SUCCEED,
  GET_USERS_SUCCEED,
  PUT_USER_SUCCEED,
  SET_ACCESS_GROUPS_LOADING,
  USERS_LOADING,
  CLEAR_GROUP,
} from '../actions/users'
import { sortListByName } from '../utils/sort'

const addNameField = user => ({ ...user, name: getFullName(user) })

export const users = (state = {}, action) => {
  switch (action.type) {
    case USERS_LOADING: return { ...state, loading: true }

    case GET_USERS_SUCCEED: {
      const usersWithNameField = action.users.map(u => addNameField(u))
      if (!action.page)
        return { ...state, list: usersWithNameField, loading: false }

      const shouldRewrite = !(state.list && action.page !== 1)
      const newUsers = shouldRewrite
        ? usersWithNameField
        : _.uniqBy([...state.list, ...usersWithNameField], 'id')
      return { ...state, list: newUsers, count: action.count, loading: false }
    }

    case CREATE_USER_SUCCEED: {
      const { user } = action
      const userWithNameField = addNameField(user)
      const newList = state.list ? [userWithNameField, ...state.list] : [userWithNameField]
      return { ...state, list: newList }
    }

    case DELETE_USER_SUCCEED: {
      const newList = state.list.filter(u => u.id !== action.userId)
      return { ...state, list: newList }
    }

    case PUT_USER_SUCCEED: {
      const { user } = action
      const filteredUsers = state.list.filter(u => u.id !== user.id)
      const userWithNameField = addNameField(user)
      const newList = sortListByName([...filteredUsers, userWithNameField])

      return { ...state, list: newList }
    }

    case GET_ACCESS_GROUPS_SUCCEED: {
      const { groups } = action
      return { ...state, groups: { ...state.groups, list: groups, loading: false } }
    }

    case SET_ACCESS_GROUPS_LOADING: return { ...state, groups: { ...state.groups, loading: true } }

    case GET_ACCESS_GROUP_SUCCEED: {
      const usersWithNameField = action.group.users.map(user => addNameField(user))

      return {
        ...state,
        groups: {
          ...state.groups,
          currentGroup: { ...action.group, users: usersWithNameField },
          loading: false,
        },
      }
    }

    case DELETE_GROUP_SUCCEED: {
      const filteredGroupList = state.groups.list.filter(group => group.id !== action.id)
      return {
        ...state,
        groups: { ...state.groups, list: filteredGroupList },
      }
    }

    case CLEAR_GROUP: return { ...state, groups: {} }

    default: return state
  }
}

export default users
