import React from 'react'
import { connect } from 'react-redux'
import { Tag } from 'antd'
import { withRouter } from 'react-router-dom'
import { Scrollbar } from 'react-scrollbars-custom'

import Button from '../../../UI/Button'
import DisabledInputBlock from '../../../UI/DisabledInputBlock'
import LecturesList from './lectures/LecturesList'
import MainCard from '../../../MainCard'
import { dateToString } from '../../../../utils/formatDate'
import { GET_EVENT, CLEAR_EVENT } from '../../../../actions/events'

class EventCard extends React.Component {
  componentDidMount() {
    const eventId = this.props.match.params.id
    this.props.getEvent(eventId)
  }

  componentWillUnmount() {
    this.props.clearEvent()
  }

  redirectToEventEdit = id => this.props.history.push(`/events/${id}/edit`)

  renderTags = tags => (tags && tags.length > 0)
    ? (
      <div className='info__block__display'>
        {tags?.map((t, index) => <Tag key={`tag-event-${index}`}>{t}</Tag>)}
      </div>
    )
    : <div className='secondary_text'>Для этого события теги не установлены</div>

  renderWhitelist = whitelist => (whitelist && whitelist.length > 0)
    ? (
      <div className='info__block__display'>
        {whitelist.map(g => <Tag key={`group-${g.id}`}>{g.title}</Tag>)}
      </div>
    )
    : <span className='secondary_text'>Не указано</span>

  render() {
    const { event, loading } = this.props

    return (
      <MainCard icon='icon__big_calendar' title='СОБЫТИЕ' loading={loading}>
        <div className='flex_container full_height'>
          <div className='container__left_side' style={{ paddingRight: '16px' }}>
            <Scrollbar>
              <div style={{ paddingRight: '22px' }}>
                <DisabledInputBlock title='Название' innerText={event.title} />
                <DisabledInputBlock title='Описание' innerText={event.description} />
                <DisabledInputBlock title='Тематические теги'>
                  {this.renderTags(event?.tags)}
                </DisabledInputBlock>
                <DisabledInputBlock title='Время начала' innerText={dateToString(event.date)} />
                <DisabledInputBlock title='Офис/Кабинет'>
                  {event.place?.title
                    ? <div className='secondary_text__white'>{event.place.title}</div>
                    : <div className='secondary_text'>Место не установлено</div>}
                </DisabledInputBlock>
                <DisabledInputBlock title='Могут просматривать'>
                  {this.renderWhitelist(event?.groupWhitelistRead)}
                </DisabledInputBlock>
              </div>
            </Scrollbar>
          </div>

          <div className='container__right_side'>
            <LecturesList />
            <div className='button_container'>
              <Button
                icon='icon__edit icon__wrapper'
                styles='bordered_btn'
                title='Редактировать'
                onClick={() => this.redirectToEventEdit(event?.id)}
              />
            </div>
          </div>
        </div>
      </MainCard>
    )
  }
}

const mapStateToProps = ({ events }) => ({
  loading: events.loading || false,
  event: events.event || {},
})

const mapDispatchToProps = dispatch => ({
  getEvent: eventId => dispatch({ type: GET_EVENT, eventId }),
  clearEvent: () => dispatch({ type: CLEAR_EVENT }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventCard))
