export const NAVIGATION = [
  {
    title: 'Экраны',
    address: '/screens',
  },
  {
    title: 'Голосования',
    address: '/voting',
  },
  // {
  //   title: 'Бронирования',
  //   address: '/reservation',
  // },
  {
    title: 'События',
    address: '/events',
  },
  // {
  //   title: 'Навигация',
  //   address: '/navigation',
  // },
  {
    title: 'Группы',
    address: '/groups',
  },
  {
    title: 'Пользователи',
    address: '/users',
  },
  // {
  //   title: 'Уведомления',
  //   address: '/notifications',
  // },
]
