import React from 'react'
import FilterItem from '../../helpers/FilterItem'

const VotingFilter = ({ setStyle, onFilterTabChange, name }) => (
  <div className='filter__tabs'>
    <FilterItem
      className={setStyle('all')}
      htmlFor='allPolls'
      name={name}
      value='all'
      id='allPolls'
      onChange={onFilterTabChange}
    >
      Все
    </FilterItem>

    <FilterItem
      className={setStyle('active')}
      htmlFor='activePolls'
      name={name}
      value='active'
      id='activePolls'
      onChange={onFilterTabChange}
    >
      <div className='green_round_marker round_marker_margin' />
      Активные
    </FilterItem>

    <FilterItem
      className={setStyle('future')}
      htmlFor='futurePolls'
      name={name}
      value='future'
      id='futurePolls'
      onChange={onFilterTabChange}
    >
      <div className='blue_round_marker round_marker_margin' />
      Предстоящие
    </FilterItem>

    <FilterItem
      className={setStyle('completed')}
      htmlFor='completedPolls'
      name={name}
      value='completed'
      id='completedPolls'
      onChange={onFilterTabChange}
    >
      <div className='yellow_round_marker round_marker_margin' />
      Завершенные
    </FilterItem>
  </div>
)

export default VotingFilter
