import { message } from 'antd'
import { takeLatest, call, put, takeEvery } from 'redux-saga/effects'

import { content, auth } from '../utils/data/requestHeaders'
import getResponse from './getResponse'
import HOST from '../utils/data/connectionData'
import {
  CREATE_USER,
  CREATE_USER_SUCCEED,
  DELETE_USER,
  DELETE_USER_SUCCEED,
  GET_USERS,
  GET_USERS_SUCCEED,
  PUT_USER,
  PUT_USER_SUCCEED,
  USERS_LOADING,
  GET_ACCESS_GROUPS,
  GET_ACCESS_GROUPS_SUCCEED,
  SET_ACCESS_GROUPS_LOADING,
  GET_ACCESS_GROUP,
  GET_ACCESS_GROUP_SUCCEED,
  PUT_USERS_IN_ACCESS_GROUP,
  POST_GROUP,
  DELETE_GROUP,
  DELETE_GROUP_SUCCEED,
  UPDATE_GROUP,
  RESET_PASSWORD,
} from '../actions/users'
import { GET, PUT, POST, DELETE } from '../utils/methods'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import nextPaginationStart from '../utils/nextPaginationStart'
import isNoMessage from '../utils/isNoMessage'
import defaultAvatar from '../assets/images/default_avatar.jpg'
import { setDefaultCursorStyle } from '../utils/cursorStyleSwitch'

function* putUser(action) {
  try {
    const userWithPhotoId = yield { ...action.user, photo: action.photo ? action.photo.id : null }
    const user = yield call(() => getResponse({
      method: PUT,
      path: `/accounts/${action.userId}`,
      headers: content,
      body: userWithPhotoId,
    }))

    if (isNoMessage(user)) {
      message.success('Изменения сохранены')
      yield put({ type: PUT_USER_SUCCEED, user })
      yield action.callback && action.callback()
    }
  } catch (e) { console.log(e.message) }
}

function* createUser({ user, photo, callback }) {
  try {
    const accountId = yield call(() => getResponse({
      method: POST,
      path: '/register_employee',
      headers: content,
      body: { username: user.email },
    }))

    if (!isNoMessage(accountId)) {
      setDefaultCursorStyle()
      return null
    }

    const userAttrs = photo ? { ...user, photo: photo.id } : user
    const account = yield call(() => getResponse({
      method: PUT,
      path: `/accounts/${accountId.account}`,
      headers: content,
      body: userAttrs,
    }))

    if (!isNoMessage(account)) {
      setDefaultCursorStyle()
      return null
    }

    message.success('Пользователь создан')
    account.photo = {
      id: photo ? photo.id : null,
      path: photo ? photo.path : defaultAvatar,
    }
    yield put({ type: CREATE_USER_SUCCEED, user: { ...account } })
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* getUsers({ searchString, page, filter, callback }) {
  try {
    yield put({ type: USERS_LOADING })
    let path = '/accounts_list'
    if (page && (!searchString || searchString === ''))
      path = `${path}?start=${nextPaginationStart(page)}&limit=${ITEMS_ON_PAGE}${filter
        ? `&include_not_activated=${filter}`
        : ''}`

    else path = searchString
      ? `${path}?${page ? `start=${nextPaginationStart(page)}&limit=${ITEMS_ON_PAGE}&` : ''}search=${searchString}`
      : path

    const users = yield call(() => getResponse({ method: GET, path: path, headers: auth }))
    yield put({ type: GET_USERS_SUCCEED, users: users.results, count: users.count, page })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

// TODO: Check if request working correctly
function* deleteUser({ userId, callback }) {
  try {
    const response = yield call(fetch, `${HOST}/accounts/${userId}`, {
      method: DELETE,
      headers: content,
    })
    if (isNoMessage(response)) {
      yield put({ type: DELETE_USER_SUCCEED, userId })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) }
}

function* getGroupsList() {
  try {
    yield put({ type: SET_ACCESS_GROUPS_LOADING })
    const groups = yield call(() => getResponse({ method: GET, path: '/groups', headers: auth }))
    yield put({ type: GET_ACCESS_GROUPS_SUCCEED, groups })
  } catch (e) { console.log(e.message) }
}

function* getGroup({ id }) {
  try {
    yield put({ type: SET_ACCESS_GROUPS_LOADING })
    const group = yield call(() =>
      getResponse({ method: GET, path: `/groups?id=${id}`, headers: auth }))
    yield put({ type: GET_ACCESS_GROUP_SUCCEED, group })
  } catch (e) { console.log(e.message) }
}

function* putUsersInGroup({ id, users }) {
  try {
    const response = yield call(() => getResponse({
      method: PUT,
      path: '/groups/update',
      headers: content,
      body: { id, users },
    }))
    return response
  } catch (e) { console.log(e.messagge) }
}

function* putMainInfoInGroup({ id, mainInfo }) {
  try {
    const response = yield call(() => getResponse({
      method: PUT,
      path: `/group/${id}`,
      headers: content,
      body: mainInfo,
    }))
    return response
  } catch (e) { console.log(e.message) }
}

function* postNewGroup({ group, history }) {
  try {
    const resGroupInfo = yield call(() => getResponse({
      method: POST,
      path: '/groups',
      body: group,
    }))

    const usersList = yield {
      id: resGroupInfo.id,
      users: group.users,
    }

    const resUsers = yield call(() => putUsersInGroup(usersList))
    if (!(resUsers.message || resUsers.msg || resGroupInfo.msg || resGroupInfo.message))
      yield history.push(`/groups/${resGroupInfo.id}`)
  } catch (e) { console.log(e.message) }
}

function* deleteGroup({ id, callback }) {
  const res = yield getResponse({
    method: DELETE,
    path: `/group/${id}`,
    headers: auth,
  })

  yield (isNoMessage(res) && callback) && callback()
  yield put({ type: DELETE_GROUP_SUCCEED, id })
  message.success('Группа доступа удалена')
}

function* updateGroup({ id, mainInfo, usersInfo, callback }) {
  try {
    const mainInfoRes = yield Boolean(mainInfo)
      && call(() => putMainInfoInGroup({ id, mainInfo }))
    const usersListRes = yield Boolean(usersInfo)
      && call(() => putUsersInGroup({ id, users: usersInfo }))

    yield (isNoMessage(mainInfoRes) && isNoMessage(usersListRes) && callback) && callback()
  } catch (e) { console.log(e.message) }
}

function* resetUserPassword({ userId, userEmail }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/pass_reset',
      headers: content,
      body: userId,
    }))
    response.message === 'OK'
      && message.success(`Пользователь получит письмо с новым паролем на адрес ${userEmail}`)
  } catch (e) {
    console.log(e.message)
  }
}

export default function* filesSagas() {
  yield takeLatest(CREATE_USER, createUser)
  yield takeLatest(DELETE_GROUP, deleteGroup)
  yield takeLatest(DELETE_USER, deleteUser)
  yield takeLatest(GET_ACCESS_GROUP, getGroup)
  yield takeLatest(GET_ACCESS_GROUPS, getGroupsList)
  yield takeEvery(GET_USERS, getUsers)
  yield takeLatest(POST_GROUP, postNewGroup)
  yield takeLatest(PUT_USER, putUser)
  yield takeLatest(PUT_USERS_IN_ACCESS_GROUP, putUsersInGroup)
  yield takeLatest(UPDATE_GROUP, updateGroup)
  yield takeEvery(RESET_PASSWORD, resetUserPassword)
}
