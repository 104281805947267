import { TODAY } from '../formatDate'

const filterPolls = (list, type) => {
  let filteredList = []

  switch (type) {
    case 'all': {
      filteredList = list
      break
    }
    case 'active': {
      filteredList = list.filter(p =>
        TODAY.isAfter(p.date_from, 'minute') && (TODAY.isBefore(p.date_to, 'minute') || !p.date_to))
      break
    }
    case 'future': {
      filteredList = list.filter(p =>
        TODAY.isBefore(p.date_from, 'minute') && TODAY.isBefore(p.date_from, 'minute'))
      break
    }
    case 'completed': {
      filteredList = list.filter(p =>
        TODAY.isAfter(p.date_to, 'minute') && TODAY.isAfter(p.date_from, 'minute'))
      break
    }
    default:
      break
  }
  return filteredList
}

export default filterPolls
