import * as _ from 'lodash'
import {
  DELETE_EVENT_SUCCEED,
  GET_EVENT_SUCCEED,
  GET_EVENTS_LIST_SUCCEED,
  POST_EVENT_SUCCEED,
  SAVE_EVENT_SUCCEED,
  SET_LOADING,
  CLEAR_EVENT,
} from '../actions/events'

import {
  ADD_LECTURE_TO_NEW_EVENT,
  CHANGE_LECTURE_IN_NEW_EVENT,
  CLEAR_LECTURES,
  CLEAR_NEW_LECTURE,
  DELETE_LECTURE_SUCCEED,
  HANDLE_NEW_LECTURE_CHANGE,
  POST_LECTURE_SUCCEED,
  PUT_LECTURE_SUCCEED,
  GET_LECTURE_SUCCEED,
  CLEAR_LECTURE_NEW_PAPERS,
} from '../actions/lectures'
import {
  ADD_FILE_TO_NEW_LECTURE_SUCCEED,
  DELETE_FILE,
  DELETE_FILE_FROM_NEW_LECTURE,
  POST_FILE_SUCCEED
} from '../actions/files'
import deleteTagsId from '../utils/deleteTagsId'
import { sortListByDay, sortListByHour, sortListByMinute } from '../utils/sort'
import getFullName from '../utils/getFullName'
import translateString from '../utils/translateString'
import defaultAvatar from '../assets/images/default_avatar.jpg'

const addUserName = lec => (
  lec.speaker
    ? {
      ...lec,
      speaker: { ...lec.speaker, name: getFullName(lec.speaker) } }
    : lec
)

const prepareLectureToSaving = lec => {
  const lectureWithoutTagsId = deleteTagsId(lec)
  return lectureWithoutTagsId.speaker ? addUserName(lectureWithoutTagsId) : lectureWithoutTagsId
}

const prepareEventToSaving = event => {
  const { lectures } = event
  let newLectures = lectures
  if (lectures) {
    newLectures = lectures.map(l => prepareLectureToSaving(l))
    sortListByHour(newLectures)
  }
  const eventWithoutTagsId = deleteTagsId(event)

  const formattedEvent = {}
  _.forIn(eventWithoutTagsId, (value, key) => {
    formattedEvent[translateString.toCamelCase(key)] = value
  })

  return { ...formattedEvent, lectures: newLectures }
}


const events = (state = {}, action) => {
  switch (action.type) {
    case SET_LOADING: return { ...state, loading: true }

    case GET_LECTURE_SUCCEED: return { ...state, lecture: action.lecture }

    case GET_EVENT_SUCCEED:
      return { ...state, event: prepareEventToSaving(action.event), loading: false }

    case GET_EVENTS_LIST_SUCCEED:
      return { ...state, list: action.eventsList, loading: false }

    case SAVE_EVENT_SUCCEED: {
      const newEvent = prepareEventToSaving(action.event)
      return { ...state, event: newEvent }
    }

    case POST_EVENT_SUCCEED: {
      const newEvent = prepareEventToSaving(action.event)
      return { ...state, event: newEvent, newLecture: {} }
    }

    case POST_LECTURE_SUCCEED: {
      const { lecture } = action
      const lectureWithoutTagsId = lecture?.tags ? deleteTagsId(lecture) : lecture
      const lectureWithSpeakerName = addUserName(lectureWithoutTagsId)
      const newLecturesList = state.event.lectures
        ? [...state.event.lectures, lectureWithSpeakerName]
        : [lectureWithSpeakerName]
      const sortedLectures = sortListByMinute(newLecturesList)

      return { ...state, event: { ...state.event, lectures: sortedLectures }, newLecture: {} }
    }

    case PUT_LECTURE_SUCCEED: {
      const { lecture } = action
      const lectureWithoutTagsId = lecture?.tags ? deleteTagsId(lecture) : lecture
      const lectureWithSpeakerName = addUserName(lectureWithoutTagsId)

      const filteredLecture = state.event.lectures.filter(l => l.id !== lecture.id)
      const sortedLectures = sortListByMinute([...filteredLecture, lectureWithSpeakerName])

      return { ...state, event: { ...state.event, lectures: sortedLectures } }
    }

    case DELETE_LECTURE_SUCCEED: {
      const newLecturesList = state.event.lectures.filter(l => l.id !== action.lectureId)
      return { ...state, event: { ...state.event, lectures: newLecturesList } }
    }

    case DELETE_EVENT_SUCCEED:
      return { ...state, list: state.list.filter(e => e.id !== action.eventId) }

    case HANDLE_NEW_LECTURE_CHANGE: {
      const { value, field } = action
      return {
        ...state,
        newLecture: (state.newLecture)
          ? {
            ...state.newLecture,
            [field]: (field === 'speaker')
              ? {
                id: value.id,
                name: value.name,
                photo: { path: value.photo ? value.photo.path : defaultAvatar } }
              : value }
          : { [field]: value },
      }
    }

    case ADD_LECTURE_TO_NEW_EVENT: {
      const lecturesList = state.newEvent ? state.newEvent.lectures : []
      const { lecture } = action
      lecture.id = lecturesList.length + 1
      return {
        ...state,
        newLecture: {},
        newEvent: { lectures: sortListByMinute([...lecturesList, lecture]) },
      }
    }

    case POST_FILE_SUCCEED: {
      const { file, lectureId, newObject } = action
      const targetObj = newObject ? 'newEvent' : 'event'

      const filteredLectures = state[targetObj].lectures.filter(l => l.id !== lectureId)
      const lecture = state[targetObj].lectures.find(l => l.id === lectureId)
      lecture.newPapers = lecture.newPapers ? [...lecture.newPapers, file] : [file]

      return {
        ...state,
        [targetObj]: {
          ...state[targetObj],
          lectures: sortListByMinute([...filteredLectures, lecture]),
        },
      }
    }

    case CLEAR_LECTURE_NEW_PAPERS: {
      const { lectureId, newObject } = action
      const targetObj = newObject ? 'newEvent' : 'event'

      const filteredLectures = state[targetObj].lectures.filter(l => l.id !== lectureId)
      const lecture = state[targetObj].lectures.find(l => l.id === lectureId)
      lecture.newPapers = []

      return {
        ...state,
        [targetObj]: {
          ...state[targetObj],
          lectures: sortListByMinute([...filteredLectures, lecture]),
        },
      }
    }

    case CHANGE_LECTURE_IN_NEW_EVENT: {
      const { lecture } = action
      const filteredLectures = state.newEvent.lectures.filter(l => l.id !== lecture.id)
      return { ...state, newEvent: { lectures: sortListByMinute([...filteredLectures, lecture]) } }
    }

    case ADD_FILE_TO_NEW_LECTURE_SUCCEED: {
      const { file } = action
      const newPapers = state.newLecture?.newPapers ? [...state.newLecture.newPapers, file] : [file]

      return {
        ...state,
        newLecture: {
          ...state.newLecture,
          newPapers,
        },
      }
    }

    case DELETE_FILE_FROM_NEW_LECTURE: {
      const { fileId } = action
      const papers = state.newLecture.papers?.filter(f => f.id !== fileId)
      const newPapers = state.newLecture.newPapers?.filter(f => f.id !== fileId)
      return {
        ...state,
        newLecture: {
          ...state.newLecture,
          papers,
          newPapers,
        },
      }
    }

    case DELETE_FILE: {
      const { fileId, lectureId, newObject } = action
      const targetObj = newObject ? 'newEvent' : 'event'

      const filteredLectures = state[targetObj].lectures.filter(l => l.id !== lectureId)
      const lecture = state[targetObj].lectures.find(l => l.id === lectureId)
      lecture.papers = lecture.papers?.filter(p => p.id !== fileId)
      lecture.newPapers = lecture.newPapers?.filter(p => p.id !== fileId)

      return {
        ...state,
        [targetObj]: {
          ...state[targetObj],
          lectures: sortListByMinute([...filteredLectures, lecture]),
        },
      }
    }

    case CLEAR_LECTURES: return { ...state, newEvent: { lectures: [] } }
    case CLEAR_NEW_LECTURE: return { ...state, newLecture: {} }
    case CLEAR_EVENT: return { ...state, event: {} }

    default: return state
  }
}

export default events
