import React from 'react'

export const Button = ({ icon, title, styles, onClick, style, delay, id }) => {
  let debounce = null

  const delayedExecution = func => {
    if (debounce) clearTimeout(debounce)
    debounce = setTimeout(func, delay)
  }

  return (
    <div
      id={id}
      className={styles}
      style={style}
      onClick={delay ? () => delayedExecution(onClick) : onClick}
    >
      {icon && <div className={icon} />}
      <span className='large_btn__title'>{title}</span>
    </div>
  )
}

export default Button
