import React from 'react'
import { Tag, Input, Tooltip, Form, message } from 'antd'
import '../../../utils/data/messageConfig'

import 'antd/dist/antd.css'

class TagPicker extends React.Component {
  state = {
    tags: [],
    inputVisible: false,
    inputValue: '',
    isInputError: false,
  }

  defaultInputProps = { inputVisible: false, inputValue: '', isInputError: false }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.tags === undefined || nextProps.tags === [])
      return { tags: [] }
    if (nextProps.tags !== prevState.tags && nextProps.tags !== undefined)
      return { tags: nextProps.tags }
    return { tags: prevState.tags }
  }

  handleClose = removedTag => {
    const tags = this.state.tags.filter(tag => tag !== removedTag)
    this.props.onConfirm('tags', tags)
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value })
  }

  handleInputConfirm = () => {
    const { inputValue, tags } = this.state

    if (!inputValue) {
      this.setState({ ...this.defaultInputProps })
      return null
    }

    if (inputValue?.length > 40 || inputValue?.length < 2) {
      message.error('Длина тега от 2 до 40 символов')
      this.setState({ isInputError: true })
      return null
    }

    if (inputValue && tags.findIndex(t => t === inputValue) !== -1) {
      message.error('Этот тег уже добавлен')
      this.setState({ isInputError: true })
      return null
    }

    const newTags = [...tags, inputValue]
    this.setState({ ...this.defaultInputProps }, () => this.props.onConfirm('tags', newTags))
  }

  renderTags = () => {
    const tagElem = tagName => (
      tagName && (
        <Tag key={tagName}>
          <div>{tagName.length > 16 ? `${tagName.slice(0, 16)}...` : tagName}</div>
          <div className='icon__close' onClick={() => this.handleClose(tagName)} />
        </Tag>
      )
    )

    return (
      this.state.tags.map(tag =>
        (tag?.length > 16
          ? <Tooltip title={tag} key={tag}>{tagElem(tag)}</Tooltip>
          : tagElem(tag)
        ))
    )
  }

  saveInputRef = input => { this.input = input }

  render() {
    const { label, children } = this.props
    const { inputVisible, inputValue, isInputError } = this.state

    return (
      <Form.Item label={label}>
        {this.renderTags()}
        {inputVisible
          ? (
            <Input
              ref={this.saveInputRef}
              type='text'
              size='small'
              className='add_tag__input'
              value={inputValue}
              style={{ border: isInputError && '1px solid #CD4141' }}
              onChange={this.handleInputChange}
              onBlur={this.handleInputConfirm}
              onPressEnter={this.handleInputConfirm}
            />
          )
          : (
            <Tag onClick={this.showInput} className='add_tag_btn'>
              <div className='icon__add_tag_btn' />
            </Tag>
          )}
        <div className='validation_block margin_top__small'>{children}</div>
      </Form.Item>
    )
  }
}

export default TagPicker
