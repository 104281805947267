import { call, put, select, takeEvery } from 'redux-saga/effects'
import getResponse from './getResponse'
import nextPaginationStart from '../utils/nextPaginationStart'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import { GET, POST, DELETE } from '../utils/methods'
import { auth, content } from '../utils/data/requestHeaders'
import { formatObjectFieldsToCamelCase } from '../utils/translateString'
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCEED,
  SET_NOTIFICATIONS_LOADING,
  POST_NOTIFICATION,
  POST_NOTIFICATION_SUCCEED,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCEED,
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
} from '../actions/notifications'
import { setDefaultCursorStyle, setWaitCursorStyle } from '../utils/cursorStyleSwitch'

function* getNotifications({ page }) {
  try {
    yield put({ type: SET_NOTIFICATIONS_LOADING })
    const path = `/feed?start=${nextPaginationStart(page)}&limit=${ITEMS_ON_PAGE}`
    const response = yield call(() => getResponse({ method: GET, path, headers: auth }))
    const notifications = formatObjectFieldsToCamelCase(response.results)

    yield put({ type: GET_NOTIFICATIONS_SUCCEED, notifications, count: response.count })
  } catch (e) { console.log(e.message) }
}

function* postNotification({ notification, callback }) {
  try {
    const response = yield call(() =>
      getResponse({ method: POST, path: '/notification', headers: content, body: notification }))
    yield put({ type: POST_NOTIFICATION_SUCCEED, notification: response })
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* deleteNotification({ notificationId, callback }) {
  try {
    yield setWaitCursorStyle()
    const { notifications } = yield select()
    const notificationIndex = yield notifications.list.findIndex(n => n.id === notificationId)
    const notification = yield notifications.list[notificationIndex]
    yield put({ type: HIDE_NOTIFICATION, notificationId })

    const response = yield call(() => getResponse({
      method: DELETE,
      path: `/notifications/${notificationId}`,
      headers: content,
    }))

    yield (!response.message && !response.msg)
      ? put({ type: DELETE_NOTIFICATION_SUCCEED, notificationId })
      : put({ type: SHOW_NOTIFICATION, notificationIndex, notification })

    yield setDefaultCursorStyle()
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}


export default function* notificationsSagas() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications)
  yield takeEvery(POST_NOTIFICATION, postNotification)
  yield takeEvery(DELETE_NOTIFICATION, deleteNotification)
}
