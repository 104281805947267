import {
  OFFICES_LOADING,
  GET_OFFICES_SUCCEED,
  GET_OFFICE_SUCCEED,
  GET_FLOORS_SUCCEED,
  GET_OFFICE_FAILED,
  GET_OFFICES_FAILED,
  CLEAN_OFFICES_LIST,
  SET_TABLES_LOADING,
  GET_TABLES_SUCCEED,
  CLEAN_TABLES,
  DELETE_OFFICE_SUCCEED,
  GET_ROOMS_LIST_SUCCEED,
  SET_ROOMS_LOADING,
  CLEAN_OFFICE,
  SET_FLOORS_LOADING,
  GET_TABLE_TAGS_SUCCEED,
  POST_OFFICE_SUCCEED,
  POST_ROOM_SUCCEED,
  POST_TABLE_SUCCEED,
  DELETE_TABLE_SUCCEED,
  DELETE_ROOM_SUCCEED,
  PUT_ROOM_SUCCEED,
  PUT_TABLE_SUCCEED,
  SET_CURRENT_TABLE,
  ACTIVATE_TABLE_SUCCEED,
  GET_SEARCH_OFFICES_SUCCEED,
  SET_TABLE_TAGS_LOADING,
} from '../actions/offices'
import { sortListByTitle } from '../utils/sort'

export const offices = (state = {}, action) => {
  switch (action.type) {
    case OFFICES_LOADING: return { ...state, loading: true }

    case GET_OFFICES_SUCCEED: {
      const newOffices = (state.list && action.page !== 1)
        ? [...state.list, ...action.list]
        : action.list

      return { ...state, list: newOffices, count: action.count, loading: false }
    }

    case GET_SEARCH_OFFICES_SUCCEED: {
      return { ...state, searchList: action.list }
    }

    case GET_OFFICES_FAILED: return { ...state, loading: false, list: [] }

    case GET_OFFICE_SUCCEED: {
      return action.editMode
        ? {
          ...state,
          office: { ...action.office, floors: state.floors.list },
          loading: false,
          floors: {},
        }
        : { ...state, office: action.office, loading: false }
    }

    case GET_OFFICE_FAILED:
      return { ...state, office: {}, floors: [], loading: false }

    case SET_TABLES_LOADING: return { ...state, tables: { ...state.tables, loading: true } }

    case GET_TABLES_SUCCEED: {
      const { editMode, list, roomId, page, count } = action
      if (editMode) {
        const targetRoomIndex = state.office.rooms.findIndex(r => r.id === roomId)
        const newRoom = state.office.rooms[targetRoomIndex]
        newRoom.tables = sortListByTitle(list)
        const newRooms = state.office.rooms
        newRooms[targetRoomIndex] = newRoom

        return { ...state, office: { ...state.office, rooms: newRooms } }
      }

      const shouldRewrite = !(state.tables.list && (page && page !== 1))
      const newTables = shouldRewrite ? list : [...state.tables.list, ...list]

      return { ...state, tables: { ...state.tables, loading: false, list: newTables, count } }
    }

    case SET_ROOMS_LOADING: return { ...state, rooms: { ...state.rooms, loading: true } }

    case GET_ROOMS_LIST_SUCCEED: {
      const { page, list, count } = action
      const isRoomsExist = (state.rooms.list && page !== 1)
      const newRooms = isRoomsExist ? [...state.rooms.list, ...list] : list

      return { ...state, rooms: { ...state.rooms, list: newRooms, count, loading: false } }
    }

    case DELETE_OFFICE_SUCCEED: {
      const newOffices = state.list.filter(o => o.id !== action.officeId)
      return { ...state, list: newOffices }
    }

    case SET_FLOORS_LOADING: return { ...state, floors: { ...state.floors, loading: true } }

    case GET_FLOORS_SUCCEED:
      return { ...state, floors: { ...state.floors, list: action.floors, loading: false } }

    case SET_TABLE_TAGS_LOADING: return { ...state, tableTags: { loading: true } }

    case GET_TABLE_TAGS_SUCCEED:
      return { ...state, tableTags: { list: action.tagsList, loading: false } }

    case POST_OFFICE_SUCCEED: return { ...state, office: { ...state.office, ...action.newOffice } }

    case POST_ROOM_SUCCEED: {
      const { office } = state
      const isRoomsExist = (office.rooms && office.rooms.length > 0)
      const newList = isRoomsExist ? [action.newRoom, ...office.rooms] : [action.newRoom]

      return { ...state, office: { ...office, rooms: newList } }
    }

    case POST_TABLE_SUCCEED: {
      const { office } = state
      const { newTable } = action

      const targetRoomIndex = office.rooms.findIndex(r => r.id === newTable.room)
      const newRoom = office.rooms[targetRoomIndex]
      const isTablesExist = newRoom.tables && newRoom.tables.length > 0
      newRoom.tables = isTablesExist ? [newTable, ...newRoom.tables] : [newTable]
      newRoom.capacity += 1

      const newRooms = office.rooms
      newRooms[targetRoomIndex] = newRoom

      return { ...state, office: { ...office, rooms: newRooms } }
    }

    case CLEAN_OFFICES_LIST: return { ...state, list: [] }

    case CLEAN_TABLES: return { ...state, tables: {} }

    case CLEAN_OFFICE: return { ...state, tables: {}, rooms: {}, floors: {}, office: {} }

    case DELETE_TABLE_SUCCEED: {
      const { office } = state
      const targetRoomIndex = office.rooms.findIndex(r => r.id === action.roomId)
      const newRoom = office.rooms[targetRoomIndex]

      newRoom.tables = newRoom.tables.filter(t => t.id !== action.tableId)
      newRoom.capacity -= 1
      const newRooms = office.rooms
      newRooms[targetRoomIndex] = newRoom

      return { ...state, office: { ...office, rooms: newRooms } }
    }

    case DELETE_ROOM_SUCCEED: {
      const { office } = state
      const newRooms = office.rooms.filter(r => r.id !== action.roomId)

      return { ...state, office: { ...office, rooms: newRooms } }
    }

    case PUT_ROOM_SUCCEED: {
      const { office } = state
      const targetRoomIndex = office.rooms.findIndex(r => r.id === action.newRoom.id)
      const newList = office.rooms
      newList[targetRoomIndex] = action.newRoom

      return { ...state, office: { ...office, rooms: newList } }
    }

    case PUT_TABLE_SUCCEED: {
      const { office } = state
      const { roomId, newTable } = action

      const newList = office.rooms
      const targetRoomIndex = newList.findIndex(r => r.id === roomId)
      const newRoom = newList[targetRoomIndex]
      const newTables = newRoom.tables.filter(t => t.id !== newTable.id)
      newRoom.tables = sortListByTitle([...newTables, newTable])
      newList[targetRoomIndex] = newRoom

      return { ...state, office: { ...office, rooms: newList } }
    }

    case SET_CURRENT_TABLE: return { ...state, currentTable: action.table }

    case ACTIVATE_TABLE_SUCCEED: {
      const { office } = state
      const { roomId, table } = action

      const newList = office.rooms
      const targetRoomIndex = newList.findIndex(r => r.id === roomId)
      const newRoom = newList[targetRoomIndex]
      const newTables = newRoom.tables.filter(t => t.id !== table.id)

      newRoom.tables = sortListByTitle([...newTables, table])
      newList[targetRoomIndex] = newRoom
      return { ...state, office: { ...office, rooms: newList }, currentTable: action.table }
    }

    default: return state
  }
}

export default offices
