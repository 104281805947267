import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import getChartsColor from './chartsColors'
import getExtremeScaleValues from './helpers/getExtremeScaleValues'

import {
  horizontalBarChartOptions,
  horizontalBarChartThickness,
  horizontalBarChartBorderWidth,
} from './setups/horizontalBarChartSetups'

const HorizontalBarChart = ({ source, cropYLabels }) => {
  const getChartDataObject = () => {
    const labelsArr = source.map(data => data.title)
    const valuesArr = source.map(data => data.value)
    const colors = getChartsColor(valuesArr.length)

    const chartData = {
      labels: labelsArr,
      datasets: [{
        barThickness: horizontalBarChartThickness,
        borderWidth: horizontalBarChartBorderWidth,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        data: valuesArr,
      }],
    }

    return chartData
  }

  return (
    <div className='chart_horizontal_bar__wrapper'>
      <HorizontalBar
        data={getChartDataObject()}
        options={horizontalBarChartOptions(getExtremeScaleValues(source), cropYLabels)}
      />
    </div>
  )
}

export default HorizontalBarChart
