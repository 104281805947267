import React from 'react'

const QR = ({ style }) => (
  <div className='icon__qr' style={style}>
    <svg id='bold' enableBackground='new 0 0 24 24' height='512' viewBox='0 0 24 24' width='512' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path d='m23 8.696c-.553 0-1-.448-1-1v-4.74c0-.527-.429-.956-.955-.956h-4.74c-.553 0-1-.448-1-1s.447-1 1-1h4.74c1.63 0 2.955 1.326 2.955 2.956v4.74c0 .552-.447 1-1 1z'/>
      </g>
      <g>
        <path d='m1 8.696c-.553 0-1-.448-1-1v-4.74c0-1.63 1.325-2.956 2.955-2.956h4.74c.553 0 1 .448 1 1s-.447 1-1 1h-4.74c-.526 0-.955.429-.955.956v4.74c0 .552-.447 1-1 1z'/>
      </g>
      <g>
        <path d='m21.045 24h-4.74c-.553 0-1-.448-1-1s.447-1 1-1h4.74c.526 0 .955-.429.955-.956v-4.74c0-.552.447-1 1-1s1 .448 1 1v4.74c0 1.63-1.325 2.956-2.955 2.956z'/>
      </g>
      <g>
        <path d='m7.695 24h-4.74c-1.63 0-2.955-1.326-2.955-2.956v-4.74c0-.552.447-1 1-1s1 .448 1 1v4.74c0 .527.429.956.955.956h4.74c.553 0 1 .448 1 1s-.447 1-1 1z'/>
      </g>
      <g>
        <path d='m9.478 11.159h-4.978c-.553 0-1-.448-1-1v-5.659c0-.552.447-1 1-1h4.978c.553 0 1 .448 1 1v5.659c0 .552-.448 1-1 1zm-3.978-2h2.978v-3.659h-2.978z'/>
      </g>
      <g>
        <path d='m19.5 20.5h-4.75c-.553 0-1-.448-1-1v-3.182c0-.552.447-1 1-1s1 .448 1 1v2.182h2.75v-3h-1.182c-.553 0-1-.448-1-1s.447-1 1-1h2.182c.553 0 1 .448 1 1v5c0 .552-.447 1-1 1z'/>
      </g>
      <g>
        <path d='m19.5 9.591h-4.091c-.553 0-1-.448-1-1v-4.091c0-.552.447-1 1-1h4.091c.553 0 1 .448 1 1v4.091c0 .552-.447 1-1 1zm-3.091-2h2.091v-2.091h-2.091z'/>
      </g>
      <g>
        <path d='m12.478 14.136h-7.978c-.553 0-1-.448-1-1s.447-1 1-1h6.978v-6.727c0-.552.447-1 1-1s1 .448 1 1v7.727c0 .552-.448 1-1 1z'/>
      </g>
      <g>
        <path d='m15.25 13.886c-.553 0-1-.448-1-1v-1.363c0-.552.447-1 1-1h4.5c.553 0 1 .448 1 1s-.447 1-1 1h-3.5v.363c0 .552-.447 1-1 1z'/>
      </g>
      <g>
        <path d='m12.022 19.201c-.553 0-1-.448-1-1v-2.272c0-.552.447-1 1-1s1 .448 1 1v2.272c0 .552-.447 1-1 1z'/>
      </g>
      <g>
        <path d='m9.25 20.5h-4.75c-.553 0-1-.448-1-1v-3.636c0-.552.447-1 1-1h4.75c.553 0 1 .448 1 1v3.636c0 .552-.447 1-1 1zm-3.75-2h2.75v-1.636h-2.75z'/>
      </g>
    </svg>
  </div>
)

export default QR
