import React, { useState } from 'react'
import CardListItem from '../helpers/lists/CardListItem'
import PresenterCardListItem from './PresenterCardListItem'
import PresenterForm from './forms/EditPresenter'
import AnimatedHeight from '../UI/AnimatedHeight'

const PresenterCard = ({
  presenter,
  index,
  onActivateBtnClick,
  id,
  onDelete,
  onPresenterStop,
  compact,
}) => {
  const [isFormShow, setIsFormShow] = useState(false)

  return (
    <React.Fragment key={id}>
      <CardListItem
        key={presenter.id}
        onDelete={() => onDelete(presenter)}
        onEdit={() => setIsFormShow(!isFormShow)}
        onCardClick={() => setIsFormShow(!isFormShow)}
      >
        <PresenterCardListItem
          presenter={presenter}
          index={index}
          compact={compact}
          onPresenterStop={onPresenterStop}
          onActivateBtnClick={onActivateBtnClick}
        />
      </CardListItem>
      <AnimatedHeight condition={isFormShow}>
        <PresenterForm
          presenter={presenter}
          isFormShow={isFormShow}
          onCancel={() => setIsFormShow(false)}
        />
      </AnimatedHeight>
    </React.Fragment>
  )
}

export default PresenterCard
