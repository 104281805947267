import React from 'react'
import { CSSTransition } from 'react-transition-group'

const UnderlinedTitle = ({ title, extraStyle, loading }) => (
  <CSSTransition in={!loading} appear mountOnEnter classNames='underlined' timeout={500}>
    <div className={`underlined_title__block ${extraStyle || ''}`}>
      <CSSTransition in={!loading} appear mountOnEnter classNames='underlined_title' timeout={500}>
        <span className='secondary_text_subtitle'>{title}</span>
      </CSSTransition>
    </div>
  </CSSTransition>
)

export default UnderlinedTitle
