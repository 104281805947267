import React from 'react'

import BigCardTitle from './helpers/cards/BigCardTitle'
import LargeSpin from './UI/Spin/LargeSpin'
import FadeTransition from './helpers/transition/FadeTransition'

const MainCard = ({
  style,
  loading,
  title,
  subtitle,
  icon,
  children,
  filter,
  btn,
  separatedBtn,
  tagsFilter,
  topBtn,
  innerContainerStyle,
}) => (
  <div className='main' id='main' style={style}>
    <FadeTransition loading>
      <div>
        <div className='title_block'>
          <BigCardTitle icon={icon} title={title} subtitle={subtitle} topBtn={topBtn} />
          {(filter || btn) && (
            <div className='title_block__subtitle'>
              {filter}
              {btn}
            </div>
          )}
          <div style={{ marginRight: '17px' }}>{separatedBtn}</div>
        </div>
        {tagsFilter}
      </div>
    </FadeTransition>

    {loading
      ? <LargeSpin />
      : <div className='event_form' style={innerContainerStyle}>{children}</div>}
  </div>
)

export default MainCard
