import React, { useEffect, useState } from 'react'
import { Scrollbar } from 'react-scrollbars-custom'

import ScreenListItem from './ScreenListItem'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import FadeTransition from '../../helpers/transition/FadeTransition'

const ScreenList = ({
  list,
  fieldToShow,
  subtitleField,
  activePresenter,
  setActivePresenter,
  customOnCancel,
  isVisible,
  setDefaultChartOnAir,
}) => {
  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    !isVisible && setSelectedItem(null)
  }, [isVisible])

  return (
    <FadeTransition loading={Boolean(list)}>
      <Scrollbar>
        {list
          ? list.map(item => (
            <ScreenListItem
              key={item.id}
              item={item}
              activeItem={activePresenter}
              selectedItem={selectedItem}
              fieldToShow={fieldToShow}
              subtitleField={subtitleField}
              handleClick={setActivePresenter}
              setSelectedItem={setSelectedItem}
              customOnCancel={customOnCancel}
              setDefaultChartOnAir={setDefaultChartOnAir}
            />
          ))
          : <EmptyPlaceholder text='Список экранов пуст' />}
      </Scrollbar>
    </FadeTransition>
  )
}

export default ScreenList
