import React from 'react'
import getFileTypeIcon from '../../../../utils/getFileTypeIcon'

const UploadedFilesList = ({ file, icons, onDelete, lectureId, hideDelete }) => (
  <div className='files_list__item'>
    <div style={{ maxWidth: '32px' }}>
      <div className={getFileTypeIcon(file.title)} />
    </div>
    <div className='input__uploaded_file'>
      <p className='input__file_name'>{file.title}</p>
      <p className='input__file_info'>{`${file.size}`}</p>
    </div>
    {(icons && (
      <>
        <a
          download
          target='_blank'
          rel='noopener noreferrer'
          href={file.path}
          className='icon__wrapper'
        >
          <div className='icon__download' />
        </a>
        {!hideDelete && (
          <div className='icon__wrapper' onClick={() => onDelete(file.id, lectureId)}>
            <div className='icon__delete' />
          </div>
        )}
      </>
    ))}
  </div>
)

export default UploadedFilesList
