import React from 'react'

const DisabledInputBlock = ({ title, subtitle, innerText, children, style }) => (
  <div className='info__block' style={style}>
    <span className='label margin_bottom text__break_word'>{title}</span>
    <span className='main_text__white text__break_word'>{subtitle}</span>
    <span className='secondary_text__white text__break_word'>{innerText}</span>
    {children}
  </div>
)

export default DisabledInputBlock
