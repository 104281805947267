import {
  gridLabelsColor,
  gridLinesColor,
  gridLinesOptions,
  legendOptions,
  dataLabelsColor,
} from './globalSetups'

const groupedBarChartOptions = maxScaleVal => ({
  ...legendOptions,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      offset: true,
      padding: 0,
      gridLines: { ...gridLinesOptions, display: false },
      ticks: {
        fontColor: gridLabelsColor,
        stacked: true,
        fontFamily: 'DIN Pro',
        callback: value => value.length >= 10 ? `${value.substring(0, 9)}...` : value,
      },
    }],
    yAxes: [{
      gridLines: { ...gridLinesOptions, zeroLineColor: gridLinesColor },
      ticks: {
        beginAtZero: true,
        suggestedMax: maxScaleVal,
        fontFamily: 'DIN Pro Light',
        fontColor: gridLabelsColor,
      },
    }],
  },
  plugins: {
    datalabels: {
      ...dataLabelsColor,
      align: 'top',
      display: true,
      anchor: 'end',
    },
  },
})

export default groupedBarChartOptions
