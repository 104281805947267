import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import MainCard from '../MainCard'
import List from './List'
import Button from '../UI/Button'
import { GET_ACCESS_GROUPS, DELETE_GROUP } from '../../actions/users'
import ConfirmationModal from '../UI/modal/ConfirmationModal'

const UsersGroupList = ({ getGroupsList, groups, loading, history, deleteGroup }) => {
  const [isConfirmShow, setIsConfirmShow] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({ id: null, title: '' })

  const createButtonBlock = () => (
    <div className='button_container__content_right'>
      <Button
        styles='bordered_btn__flex__end'
        icon='icon__add_lecture__rounded'
        title='Добавить группу'
        onClick={() => history.push('groups/new')}
      />
    </div>
  )

  useEffect(() => {
    getGroupsList()
  }, [])

  const onDeleteIconClick = ({ id, title }) => {
    setIsConfirmShow(true)
    setSelectedGroup({ id, title })
  }

  const onConfirm = () => {
    deleteGroup(selectedGroup.id, () => setIsConfirmShow(false))
  }

  return (
    <>
      <MainCard
        icon='icon__groups'
        title='Доступы пользователей'
        btn={createButtonBlock()}
        loading={loading}
      >
        <List groups={groups} onDelete={onDeleteIconClick} />
      </MainCard>
      {isConfirmShow && (
        <ConfirmationModal
          start={isConfirmShow}
          text={`Удалить группу "${selectedGroup.title}"?`}
          onConfirm={onConfirm}
          onClose={() => setIsConfirmShow(false)}
        />
      )}
    </>
  )
}

const mapStateToProps = ({ users }) => ({
  groups: users?.groups?.list,
  loading: users?.groups?.loading,
})

const mapDispatchToProps = dispatch => ({
  getGroupsList: () => dispatch({ type: GET_ACCESS_GROUPS }),
  deleteGroup: (id, callback) => dispatch({ type: DELETE_GROUP, id, callback }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersGroupList))
