import React from 'react'
import { Route, Switch } from 'react-router-dom'

import EditEventForm from './card/edit/index'
import EventCard from './card/view'
import List from './list/index'

const EventsRouter = () => (
  <Switch>
    <Route path='/events' exact component={List} />
    <Route path='/events/new' exact render={() => <EditEventForm newObject />} />
    <Route path='/events/:id/edit' render={() => <EditEventForm newObject={false} />} />
    <Route path='/events/:id' component={EventCard} />
  </Switch>
)

export default EventsRouter
