import getExtension from './getExtension'

const getFileTypeIcon = fileName => {
  const extension = getExtension(fileName)
  switch (extension) {
    case 'jpg': return 'icon__jpg'
    case 'png': return 'icon__png'
    case 'doc':
    case 'docx': return 'icon__doc'
    case 'ppt': return 'icon__ppt'
    case 'txt': return 'icon__txt'
    case 'xls':
    case 'xlsx': return 'icon__xls'
    case 'zip': return 'icon__zip'
    case 'rar': return 'icon__rar'
    default: return 'icon__file_preview'
  }
}

export default getFileTypeIcon
