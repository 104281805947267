import { call, put, select, takeLatest } from 'redux-saga/effects'
import { message } from 'antd'
import {
  POST_POLL,
  SAVE_POLL_SUCCEED,
  GET_POLLS,
  GET_POLLS_SUCCEED,
  GET_POLL,
  GET_POLL_SUCCEED,
  POLLS_LOADING,
  DELETE_POLL,
  DELETE_POLL_SUCCEED,
  PUT_POLL,
  GET_POLL_STATISTIC,
  GET_POLL_STATISTIC_SUCCEED,
  GET_APP_LINKS, GET_APP_LINKS_SUCCEED,
} from '../actions/poll'
import formPoll from '../utils/objects/formPoll'
import getResponse from './getResponse'
import HOST from '../utils/data/connectionData'
import { content, auth } from '../utils/data/requestHeaders'
import { GET, POST, PUT, DELETE } from '../utils/methods'
import { getEventsList, getEvent } from './eventsSagas'
import nextPaginationStart from '../utils/nextPaginationStart'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import { getCategoriesString } from '../components/charts/helpers/chartsCategories'
import isNoMessage from '../utils/isNoMessage'

function* getPolls({ page, filter }) {
  try {
    yield put({ type: POLLS_LOADING })
    const startPosition = nextPaginationStart(page)
    // const path = `/poll?start=${startPosition}&limit=${ITEMS_ON_PAGE}`
    const path = `/poll`
    const polls = yield call(() => getResponse({ method: GET, path: path, headers: auth }))
    yield put({ type: GET_POLLS_SUCCEED, list: polls.results })
  } catch (e) { console.log(e.message) }
}

function* postPoll({ poll, callback }) {
  try {
    const newPoll = yield call(() => getResponse({
      method: POST,
      path: '/poll',
      headers: content,
      body: poll,
    }))

    if (isNoMessage(newPoll)) {
      yield put({ type: SAVE_POLL_SUCCEED, poll: newPoll })
      message.success('Голосование сохранено')
      yield callback && callback(`/voting/${newPoll.id}`)
    }
  } catch (e) { console.log(e.message) }
}

function* putPoll({ poll, callback }) {
  try {
    const { id, ...generalInfo } = poll
    const response = yield call(() => getResponse({
      method: PUT,
      path: `/polls/${id}`,
      headers: content,
      body: generalInfo,
    }))

    if (isNoMessage(response)) {
      message.success('Изменения сохранены')
      yield put({ type: SAVE_POLL_SUCCEED, poll: response })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) }
}

function* getFormattedPoll(poll) {
  try {
    yield call(() => getEventsList())
    yield poll.event && call(() => getEvent({ eventId: poll.event.id }))
    const state = yield select()
    const formedPoll = yield formPoll({ poll, events: state.events })
    yield put({ type: GET_POLL_SUCCEED, poll: formedPoll })
  } catch (e) { console.log(e.message) }
}

function* getPoll(action) {
  try {
    yield put({ type: POLLS_LOADING })
    const poll = yield call(() => getResponse({
      method: GET,
      path: `/poll?id=${action.pollId}`,
      headers: auth,
    }))

    if (action.formatted) yield call(() => getFormattedPoll(poll))
    else yield put({ type: GET_POLL_SUCCEED, poll })
  } catch (e) { console.log(e.message) }
}

function* getPollStatistic(action) {
  try {
    yield put({ type: POLLS_LOADING })
    const poll = yield call(() => getResponse({
      method: GET,
      path: `/poll/statistics?id=${action.pollId}${getCategoriesString()}`,
      headers: auth,
    }))
    yield put({ type: GET_POLL_STATISTIC_SUCCEED, poll })
  } catch (e) { console.log(e.message) }
}

function* deletePoll({ pollId, callback }) {
  try {
    yield call(fetch, `${HOST}/polls/${pollId}`, {
      method: DELETE,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    })
    yield callback && callback()
    yield put({ type: DELETE_POLL_SUCCEED, pollId })
  } catch (e) { console.log(e.message) }
}

function* getAppLinks() {
  try {
    const links = yield call(() => getResponse({
      method: GET,
      path: '/app_links',
      headers: auth,
    }))

    yield isNoMessage(links) && put({ type: GET_APP_LINKS_SUCCEED, links })
  } catch (e) { console.log(e.message) }
}

export default function* pollSagas() {
  yield takeLatest(POST_POLL, postPoll)
  yield takeLatest(GET_POLLS, getPolls)
  yield takeLatest(DELETE_POLL, deletePoll)
  yield takeLatest(GET_POLL, getPoll)
  yield takeLatest(GET_POLL_STATISTIC, getPollStatistic)
  yield takeLatest(PUT_POLL, putPoll)
  yield takeLatest(GET_APP_LINKS, getAppLinks)
}
