export const USERS_LOADING = 'USERS_LOADING'

export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCEED = 'CREATE_USER_SUCCEED'

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCEED = 'GET_USERS_SUCCEED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCEED = 'DELETE_USER_SUCCEED'

export const PUT_USER = 'PUT_USER'
export const PUT_USER_SUCCEED = 'PUT_USER_SUCCEED'

export const GET_ACCESS_GROUPS = 'GET_ACCESS_GROUPS'
export const GET_ACCESS_GROUPS_SUCCEED = 'GET_ACCESS_GROUPS_SUCCEED'

export const SET_ACCESS_GROUPS_LOADING = 'SET_ACCESS_GROUPS_LOADING'

export const GET_ACCESS_GROUP = 'GET_ACCESS_GROUP'
export const GET_ACCESS_GROUP_SUCCEED = 'GET_ACCESS_GROUP_SUCCEED'

export const PUT_USERS_IN_ACCESS_GROUP = 'PUT_USERS_IN_ACCESS_GROUP'

export const POST_GROUP = 'POST_GROUP'

export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCEED = 'DELETE_GROUP_SUCCEED'

export const CLEAR_GROUP = 'CLEAR_GROUP'

export const UPDATE_GROUP = 'UPDATE_GROUP'

export const RESET_PASSWORD = 'RESET_PASSWORD'
