export const SET_LOADING = 'SET_LOADING'

export const GET_EVENTS_LIST = 'GET_EVENTS_LIST'
export const GET_EVENTS_LIST_SUCCEED = 'GET_EVENTS_LIST_SUCCEED'
export const GET_EVENTS_LIST_FAILED = 'GET_EVENTS_LIST_FAILED'

export const SAVE_EVENT = 'SAVE_EVENT'
export const SAVE_EVENT_SUCCEED = 'SAVE_EVENT_SUCCEED'
export const SAVE_EVENT_FAILED = 'SAVE_EVENT_FAILED'

export const POST_EVENT = 'POST_EVENT'
export const POST_EVENT_SUCCEED = 'POST_EVENT_SUCCEED'
export const POST_EVENT_FAILED = 'POST_EVENT_FAILED'

export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_FAILED = 'GET_EVENT_FAILED'
export const GET_EVENT_SUCCEED = 'GET_EVENT_SUCCEED'

export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_EVENT_SUCCEED = 'DELETE_EVENT_SUCCEED'
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED'

export const CREATE_EVENT = 'CREATE_EVENT'
export const CLEAR_EVENT = 'CLEAR_EVENT'
