import { combineReducers } from 'redux'
import account from './account'
import events from './events'
import files from './files'
import offices from './offices'
import poll from './poll'
import users from './users'
import booking from './booking'
import presenters from './presenters'
import notifications from './notifications'

export default combineReducers({
  events,
  files,
  users,
  poll,
  account,
  offices,
  booking,
  presenters,
  notifications,
})
