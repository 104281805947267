import React, { useState } from 'react'
import { FieldArray } from 'formik'
import { Form } from 'antd'
import MultiInputItem from './MultiInputItem'
import ValidationMsg from '../ValidationMsg'

const MultiInput = ({ name, label, value, onChange, children, disable, errors, touched }) => {
  const [list, setList] = useState(value)

  const deleteOption = i => {
    const newList = list
    newList.splice(i, 1)
    onChange(name, newList)
  }

  const addOption = () => onChange(`${name}[${value.length}]`, '')

  const addOptionBtn = () => (
    <div className='multi_input_item__block'>
      <div className='icon__plus' />
      <div className='multi_input__new_item' onClick={addOption}>
        <span className='secondary_text__inactive'>Добавить вариант</span>
      </div>
    </div>
  )

  if (value !== list) setList(value)

  const isErrorsTypeOfArray = Array.isArray(errors)

  return (
    <Form.Item label={label} style={{ flex: 1, marginBottom: '4px' }}>
      <div style={{ flex: 1, maxHeight: '100%', minHeight: '100%' }}>
        <div className='validation_block'>
          <FieldArray
            name={name}
            render={() => (
              <div className='multi_input__block'>
                {list.map((v, i) => (
                  <MultiInputItem
                    deleteItem={() => deleteOption(i)}
                    index={i}
                    key={`multi-input-${i}`}
                    name={name}
                    onChange={onChange}
                    value={value[i]}
                    disabled={disable}
                  />
                ))}
                {!disable && addOptionBtn()}
              </div>
            )}
          />
          {isErrorsTypeOfArray && touched.answers && value.filter(String).length === 0
            && (
              <div className='validation_block margin_top__small'>
                <ValidationMsg>Необходим хотя бы один вариант ответа</ValidationMsg>
              </div>
            )}
          {!isErrorsTypeOfArray
            && <div className='validation_block margin_top__small'>{children}</div>}
        </div>
      </div>
    </Form.Item>
  )
}

export default MultiInput
