import React from 'react'
import { Form as FormikForm, withFormik } from 'formik'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import moment from 'moment'

import LeftSideVoteForm from './LeftSideVoteForm'
import RightSideVoteForm from './RightSideVoteForm'
import ScrollTransition from '../../helpers/transition/ScrollTransition'
import Button from '../../UI/Button'
import { GET_EVENT, GET_EVENTS_LIST } from '../../../actions/events'
import { GET_POLL, POST_POLL, PUT_POLL } from '../../../actions/poll'
import packPoll from '../../../utils/objects/packPoll'
import { getMaxLengthMsg } from '../../../utils/validation'
import defaultSelect from '../../../utils/data/defaultSelect'
import cropString from '../../../utils/cropString'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Название должно быть больше 2 символов')
    .max(120, field => getMaxLengthMsg(120, field))
    .required('Обязательное поле'),
  description: Yup.string()
    .max(120, field => getMaxLengthMsg(120, field)),
  answers: Yup.array()
    .compact()
    .of(Yup.string()
      .trim()
      .min(1, 'Минимальная длина - 1 символ')
      .max(120, field => getMaxLengthMsg(120, field)))
    .min(1, 'Необходим хотя бы один вариант ответа')
    .required('Обязательное поле'),
  eventLink: Yup.mixed().when('access', {
    is: 'Добавить к событию',
    then: Yup.object().shape({
      id: Yup.string().nullable().required('Не выбрано связаное событие'),
      title: Yup.string(),
    }),
  }),
  dateFrom: Yup.object()
    .nullable('Обязательное поле')
    .required('Обязательное поле'),
})


const InnerForm = ({
  loading,
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  newPoll,
  history,
  getEvent,
  setTouched,
  postPoll,
  putPoll,
  poll,
  eventsList,
  lecturesList,
}) => {
  const setObjectInValues = (field, id = null, title = 'Выберите') => {
    setFieldValue(`${field}.id`, id)
    setFieldValue(`${field}.title`, title)
  }

  const onSelectChange = (field, value) => {
    const array = (field === 'lectureLink') ? lecturesList : eventsList

    const { title } = array.find(e => e.id === value)

    setObjectInValues(field, value, title.length > 60 ? cropString(title, 60) : title)
    if (field === 'eventLink') {
      getEvent(value)
      setObjectInValues('lectureLink')
    }
  }

  const saveVoting = () => {
    setTouched({
      title: true,
      description: true,
      answers: true,
      dateFrom: true,
      dateTo: true,
      eventLink: values.access === 'Добавить к событию',
    })
    if (!ValidationSchema.isValidSync(values)) return null

    const packedPoll = packPoll(values)

    newPoll
      ? postPoll(packedPoll, history.push)
      : putPoll({ ...packedPoll, id: poll.id }, () => history.push(`/voting/${poll.id}`))
  }

  return (
    <>
      <ScrollTransition loading>
        <div className='flex_container__column' style={{ flex: 1, overflow: 'auto' }}>
          <FormikForm>
            <div className='basic_form'>
              <LeftSideVoteForm
                loading={loading}
                values={values}
                errors={errors}
                touched={touched}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                shouldDisableAnswersEditing={!newPoll}
              />
              <RightSideVoteForm
                loading={loading}
                errors={errors}
                touched={touched}
                values={values}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                onSelectChange={onSelectChange}
              />
            </div>
          </FormikForm>
        </div>
      </ScrollTransition>
      <div className='btn_container__vote_form'>
        <Button
          styles='btn_solid'
          title='Сохранить голосование'
          onClick={saveVoting}
        />
      </div>
    </>
  )
}

const Poll = withFormik({
  enableReinitialize: false,
  mapPropsToValues({ newPoll, poll = {} }) {
    if (newPoll)
      return {
        title: '',
        description: '',
        access: 'Видно всем',
        answers: [],
        eventLink: defaultSelect,
        lectureLink: defaultSelect,
        dateFrom: moment(),
        dateTo: null,
      }

    const {
      title = '',
      description = '',
      access = 'Видно всем',
      answers = [],
      lecture = defaultSelect,
      event = defaultSelect,
      date_from = null,
      date_to = null,
    } = poll

    return {
      title: title,
      description: description,
      access: access,
      answers: answers,
      eventLink: event || defaultSelect,
      lectureLink: lecture || defaultSelect,
      dateFrom: date_from,
      dateTo: date_to,
    }
  },
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ poll = {}, events = {} }) => {
  const lectures = (events.event?.id && events.event?.lectures)
    ? events.event.lectures.map(l => ({ id: l.id || null, title: l.title || 'Выберите' }))
    : []

  return {
    eventsList: events?.list?.map(e => ({ id: e.id, title: e.title })) || [],
    lecturesList: lectures,
    poll: poll.poll,
    loading: poll.loading,
    lecture: events.lecture,
  }
}

const mapDispatchToProps = dispatch => ({
  getEvent: eventId => dispatch({ type: GET_EVENT, eventId }),
  postPoll: (poll, callback) => dispatch({ type: POST_POLL, poll, callback }),
  putPoll: (poll, callback) => dispatch({ type: PUT_POLL, poll, callback }),
  getPoll: (pollId, formatted) => dispatch({ type: GET_POLL, pollId, formatted }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Poll))
