import * as Yup from 'yup'
import { phoneRegExp } from '../../../utils/data/regExp'
import { getMaxLengthMsg } from '../../../utils/validation'

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Минимальная длина имени – 2 символа')
    .max(50, field => getMaxLengthMsg(50, field))
    .required('Обязательное поле'),
  lastName: Yup.string()
    .min(2, 'Минимальная длина фамилии – 2 символа')
    .max(50, field => getMaxLengthMsg(50, field))
    .required('Обязательное поле'),
  phone_number: Yup.string()
    .matches(phoneRegExp, 'Введите номер телефона от 10 до 13 символов')
    .required('Обязательное поле'),
  email: Yup.string()
    .email('Введите валидный адрес электронной почты')
    .required('Обязательное поле'),
  city: Yup.object().shape({
    id: Yup.string().nullable().required('Обязательное поле'),
  }),
  description: Yup.string().max(140, field => getMaxLengthMsg(140, field)),
  birthday: Yup.object().nullable().required('Обязательное поле'),
})

export default ValidationSchema
