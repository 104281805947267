import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import QRCode from 'qrcode.react'

import Modal from '../UI/modal/Modal'
import Button from '../UI/Button'
import ScreensPopUp from './helpers/ScreensPopUp'
import FadeTransition from '../helpers/transition/FadeTransition'
import { GET_APP_LINKS } from '../../actions/poll'

import apple from '../../assets/images/apple.svg'
import android from '../../assets/images/android.svg'
import LargeSpin from '../UI/Spin/LargeSpin'

const ModalQRTranslation = ({
  start,
  onClose,
  name,
  switchActivePopUp,
  setSwitchActivePopUp,
  activePresenter,
  setChartOnAir,
  setActivePresenter,
  chartOnAir,
  getAppLinks,
  appLinks,
}) => {
  const [isOnAir, setIsOnAir] = useState(false)

  useEffect(() => { getAppLinks() }, [getAppLinks])

  const platforms = ['ios', 'android']

  const getQRSetups = type => ({
    value: appLinks[`${type}_link`],
    size: 128,
    level: 'Q',
    bgColor: '#FFFFFF',
    fgColor: '#191F28',
    includeMargin: true,
    renderAs: 'svg',
    imageSettings: {
      src: type === 'ios' ? apple : android,
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    },
  })

  const handleStopTranslation = async () => {
    await setChartOnAir({ name: null, type: null })
    await setIsOnAir(false)
  }

  const handleCloseModal = async () => {
    if (isOnAir && activePresenter) await handleStopTranslation()
    await setSwitchActivePopUp(null)
    await onClose()
  }

  const onScreenSelect = async id => {
    await setChartOnAir({ name, type: name, presenter: id, content: appLinks })
    await setActivePresenter(id)
    await setSwitchActivePopUp(null)
  }

  const cancelPresenterSelect = () => {
    setIsOnAir(false)
    setSwitchActivePopUp(null)
  }

  const handleStartTranslation = () => {
    setIsOnAir(true)
    if (activePresenter) {
      onScreenSelect(activePresenter)
      return
    }
    setSwitchActivePopUp(name)
  }

  const handleBtnClick = () => (switchActivePopUp === name)
    ? cancelPresenterSelect()
    : handleStartTranslation()

  const shouldRecolorBorder = () => chartOnAir.name === name && activePresenter

  return (
    <Modal
      start={start}
      onClose={handleCloseModal}
      extraClassName={`modal__app_qr${shouldRecolorBorder() ? '__active' : ''}`}
    >
      <span className='large_btn__title__center border_bottom'>ССЫЛКИ НА ПРИЛОЖЕНИЯ</span>
      <div className='container__app_qr'>
        {appLinks
          ? platforms.map(platform => (
            <div
              key={platform}
              className='flex_container__center flex_container__column'
              style={{ maxWidth: 'fit-content' }}
            >
              <QRCode {...getQRSetups(platform)} />
              <span className='secondary_text' style={{ marginTop: '8px' }}>{platform}</span>
            </div>
          ))
          : <LargeSpin />}
      </div>
      <div
        className='button_container__content_right'
        style={{ maxHeight: '35px', position: 'relative' }}
      >
        <Button
          delay={300}
          styles={`rounded_btn${shouldRecolorBorder() ? '__red' : '__green'}`}
          title={shouldRecolorBorder() ? 'Завершить трансляцию' : 'Начать трансляцию'}
          onClick={isOnAir && activePresenter ? handleStopTranslation : handleBtnClick}
        />
        <FadeTransition loading={switchActivePopUp === name}>
          <ScreensPopUp
            setSwitchActivePopUp={setSwitchActivePopUp}
            activePresenter={activePresenter}
            setActivePresenter={onScreenSelect}
            customOnCancel={() => setSwitchActivePopUp(null)}
            customTopOffset
          />
        </FadeTransition>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ poll }) => ({
  appLinks: poll.appLinks,
})

const mapDispatchToProps = dispatch => ({
  getAppLinks: () => dispatch({ type: GET_APP_LINKS }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalQRTranslation)
