import React from 'react'
import { withRouter } from 'react-router-dom'

import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import UsersGroupListItem from './ListItem'
import ScrollTransition from '../helpers/transition/ScrollTransition'

const UsersGroupList = ({ groups, history, onDelete }) => (
  groups
    ? (
      <ScrollTransition loading>
        <div className='card_list'>
          {groups.map((group, index) => (
            <UsersGroupListItem
              key={group.id}
              index={index}
              group={group}
              onClick={() => history.push(`groups/${group.id}`)}
              onDelete={() => onDelete({ id: group.id, title: group.title })}
              hideDeleteIcon={group.pre_defined}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список групп пуст' />)

export default withRouter(UsersGroupList)
