import React, { useCallback, useEffect } from 'react'
import * as Yup from 'yup'
import { animated, useSpring } from 'react-spring'
import { ErrorMessage, withFormik } from 'formik'

import TextAreaSimple from '../UI/input/TextAreaSimple'
import FadeTransition from '../helpers/transition/FadeTransition'
import ValidationMsg from '../UI/ValidationMsg'

const ValidationSchema = Yup.object().shape({
  text: Yup.string()
    .min(2, 'Текст не менее 2 символов')
    .max(500, 'Текст не более 500 символов'),
})

const InnerForm = ({
  expand,
  onSend,
  setFieldValue,
  setFieldTouched,
  resetForm,
  setTouched,
  values,
  closeInput,
  errors,
  touched,
}) => {
  const postNotification = async () => {
    await setTouched({ text: true })
    await ValidationSchema.isValidSync(values) && onSend(values.text, resetForm)
  }

  const onEnter = e => {
    e.preventDefault()
    postNotification()
  }

  const handleKeyDown = useCallback(e => {
    if (e.keyCode === 13) expand && onEnter(e)
    if (e.keyCode === 27) expand && closeInput()
  }, [onEnter])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    return () => document.removeEventListener('keydown', handleKeyDown, false)
  }, [handleKeyDown])

  const inputStyle = useSpring({ height: expand ? 80 : 0 })

  const getStyle = value => value.length > 2 ? 'btn__send__active' : 'btn__send__inactive'
  const transitionStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  }

  return (
    <animated.div className='container__slide_input' style={inputStyle}>
      <FadeTransition loading={expand} style={transitionStyle}>
        <div className='container__slide_input__wrapper'>
          <TextAreaSimple
            name='text'
            autofocus
            value={values.text}
            style={{ height: '50px' }}
            setFieldTouched={setFieldTouched}
            onChange={setFieldValue}
            placeholder='Начните вводить текст уведомления'
            error={errors.text && touched.text}
          />
          <ErrorMessage component={ValidationMsg} name='text' />
          <div
            className={getStyle(values.text)}
            onClick={postNotification}
            onKeyDown={handleKeyDown}
          >
            <div className='icon__send' />
          </div>
        </div>
      </FadeTransition>
    </animated.div>
  )
}

const ExpandingInput = withFormik({
  validationSchema: ValidationSchema,
  mapPropsToValues: () => ({ text: '' }),
})(InnerForm)

export default ExpandingInput
