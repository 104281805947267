import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import BigCardTitle from '../helpers/cards/BigCardTitle'
import TimeLine from './TimeLine'
import {
  DELETE_NOTIFICATION, DELETE_NOTIFICATION_SUCCEED,
  GET_NOTIFICATIONS,
  POST_NOTIFICATION,
} from '../../actions/notifications'
import ScrollTransition from '../helpers/transition/ScrollTransition'
import LargeSpin from '../UI/Spin/LargeSpin'
import { ITEMS_ON_PAGE } from '../../utils/data/constants'
import SmallSpin from '../UI/Spin/SmallSpin'
import ExpandingInput from './ExpandingInput'
import ConfirmationModal from '../UI/modal/ConfirmationModal'

const Index = ({
  loading,
  list,
  count,
  getNotifications,
  postNotification,
  deleteNotification,
}) => {
  const [page, setPage] = useState(1)
  const [isInputVisible, setIsInputVisible] = useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [deletedNotification, setDeletedNotification] = useState(null)

  const shouldLoadMore = () => {
    const isAllFetched = (count <= page * ITEMS_ON_PAGE)
    return (!loading && !isAllFetched)
  }

  const isAllItemsVisible = () => {
    const innerHeight = document.getElementById('inner-container')
    const outerHeight = document.getElementById('outer-container')

    return (innerHeight <= outerHeight)
  }

  const loadMoreNotifications = () => {
    isAllItemsVisible() && setPage(page + 1)
  }

  const onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    if (!shouldLoadMore()) return null

    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 1)
    if (isScrollAtTheBottom) setPage(page + 1)
  }

  const openDeletionModal = ({ id, text }) => {
    setIsConfirmationVisible(true)
    setDeletedNotification({ id, text })
  }

  const closeDeletionModal = () => {
    setIsConfirmationVisible(false)
    setDeletedNotification(null)
  }

  const confirmDeletion = () => {
    deleteNotification(deletedNotification.id, closeDeletionModal)
  }

  useEffect(() => {
    page > 1 && getNotifications(page)
  }, [page])

  const checkHeight = () => {
    const outer = document.getElementById('outer-container')
    const inner = document.getElementById('inner-container')

    if ((inner.clientHeight < outer.scrollHeight) && shouldLoadMore()) setPage(page + 1)
  }

  const onSend = (text, callback) => text.length > 2
    ? postNotification({ type: 'system', text: text }, callback)
    : null

  return (
    <>
      <div className='card__top' id='notifications'>
        <div className='title_block'>
          <BigCardTitle icon='icon__notifications' title='УВЕДОМЛЕНИЯ' />
          <div
            className='btn__add_notification'
            onClick={() => setIsInputVisible(!isInputVisible)}
          />
        </div>
        <div className='flex_container full_height' id='outer-container'>
          {loading && page === 1
            ? <LargeSpin />
            : (
              <ScrollTransition loading={list.length} onScroll={onListScroll} transition={false}>
                <div className='flex_container__column' id='inner-container'>
                  <TimeLine
                    checkHeight={checkHeight}
                    list={list}
                    loadMoreNotifications={loadMoreNotifications}
                    count={count}
                    isAllItemsVisible={isAllItemsVisible}
                    deleteNotification={openDeletionModal}
                  />
                  {loading && <SmallSpin />}
                </div>
              </ScrollTransition>
            )}
        </div>
        <ExpandingInput
          expand={isInputVisible}
          onSend={onSend}
          closeInput={() => setIsInputVisible(false)}
        />
      </div>
      <ConfirmationModal
        start={isConfirmationVisible}
        text={`Удалить уведомление: ${deletedNotification?.text}?`}
        onClose={closeDeletionModal}
        onConfirm={confirmDeletion}
      />
    </>
  )
}

const mapStateToProps = ({ notifications }) => ({
  list: notifications?.list || [],
  loading: notifications?.loading,
  count: notifications.count,
})

const mapDispatchToProps = dispatch => ({
  getNotifications: page => dispatch({ type: GET_NOTIFICATIONS, page }),
  postNotification: (notification, callback) =>
    dispatch({ type: POST_NOTIFICATION, notification, callback }),
  deleteNotification: (notificationId, callback) =>
    dispatch({ type: DELETE_NOTIFICATION, notificationId, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Index)
