import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withFormik, Form as FormikForm, ErrorMessage } from 'formik'

import Button from '../../UI/Button'
import citiesSource from '../../../assets/cities'
import DatePick from '../../UI/input/DatePick'
import generalCitiesSource from '../../../assets/generalCities'
import getFullCityName from '../../../utils/getFullCityName'
import getStyle from '../../../utils/getStyle/getStyleUserForm'
import packUser from '../../../utils/objects/packUser'
import RadioGroup from '../../UI/input/RadioGroup'
import SelectWithSearch from '../../UI/input/SelectWithSearch'
import SimpleImageUploader from './SimpleImageUploader'
import SimpleInput from '../../UI/input/SimpleInput'
import TextAreaSimple from '../../UI/input/TextAreaSimple'
import ValidationMsg from '../../UI/ValidationMsg'
import ValidationSchema from './userValidationSchema'
import { PUT_USER, RESET_PASSWORD } from '../../../actions/users'

const InnerForm = ({
  values,
  user,
  setFieldValue,
  setFieldTouched,
  touched,
  errors,
  userPhoto,
  resetPassword,
  putUser,
  onCancel,
  setTouched,
}) => {
  const [citiesList, setCitiesList] = useState(generalCitiesSource)

  const halfWidthStyle = { maxWidth: '47%' }

  const getCities = searchVal => {
    if (searchVal.length < 3) {
      setCitiesList(generalCitiesSource)
      return null
    }
    setCitiesList(citiesSource.filter(city =>
      city.name.toLowerCase().includes(searchVal.toLowerCase())))
  }

  const getErrorCondition = field => errors[field] && touched[field]

  const resetUserPassword = () => resetPassword({ account: user.id }, user.email)

  const saveUser = async () => {
    await setTouched({
      firstName: true,
      lastName: true,
      phone_number: true,
      email: true,
      city: true,
      birthday: true,
      description: true,
    })

    if (!ValidationSchema.isValidSync(values)) return null

    putUser(packUser(values), userPhoto, user.id, onCancel)
  }

  const onPhoneFieldFocus = () =>
    !values.phone_number ? setFieldValue('phone_number', '+') : null

  const onPhoneFieldBlur = () =>
    values.phone_number === '+' ? setFieldValue('phone_number', '') : null

  const renderGrantRightsBtn = () => (
    <div className='btn__reset_password' onClick={resetUserPassword}>
      {user?.has_cp_access
        ? 'Сбросить пароль пользователя'
        : 'Предоставить доступ к панели оператора'}
    </div>
  )

  return (
    <div className='user_form__edit'>
      {renderGrantRightsBtn()}
      <FormikForm>
        <div className='container_form__left'>
          <SimpleInput
            name='firstName'
            label='Имя*'
            value={values.firstName}
            placeholder='Имя пользователя'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('firstName')}
          >
            <ErrorMessage component={ValidationMsg} name='firstName' />
          </SimpleInput>
          <SimpleInput
            name='lastName'
            label='Фамилия*'
            value={values.lastName}
            onChange={setFieldValue}
            placeholder='Фамилия пользователя'
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('lastName')}
          >
            <ErrorMessage component={ValidationMsg} name='lastName' />
          </SimpleInput>
          <div className='flex_container flex_container__space_between'>
            <div className='flex_container__flex_1 radio_group__horizontal' style={halfWidthStyle}>
              <DatePick
                label='Дата рождения*'
                name='birthday'
                value={values.birthday}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                format='DD.MM.YYYY'
                disabledDate={current => current && current > moment().endOf('day')}
                error={getErrorCondition('birthday')}
              >
                <ErrorMessage component={ValidationMsg} name='birthday' />
              </DatePick>
            </div>
            <div className='flex_container__flex_1 radio_group__horizontal' style={halfWidthStyle}>
              <RadioGroup
                name='sex'
                label='Пол'
                value={values.sex}
                setFieldTouched={setFieldTouched}
                onChange={setFieldValue}
                options={['М', 'Ж']}
              />
            </div>
          </div>
          <TextAreaSimple
            name='description'
            label='Должность/Описание'
            value={values.description}
            style={{ height: '110px' }}
            setFieldTouched={setFieldTouched}
            onChange={setFieldValue}
            error={getErrorCondition('description')}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </TextAreaSimple>
        </div>

        <div className='container_form__right'>
          <SimpleInput
            name='phone_number'
            label='Телефон*'
            value={values.phone_number}
            placeholder='+***********'
            style={getStyle()}
            onChange={setFieldValue}
            onFocus={onPhoneFieldFocus}
            onBlur={onPhoneFieldBlur}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('phone_number')}
          >
            <ErrorMessage component={ValidationMsg} name='phone_number' />
          </SimpleInput>
          <SimpleInput
            name='email'
            label='Email*'
            value={values.email}
            style={{ ...getStyle(), marginTop: 0 }}
            placeholder='Адрес электронной почты'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('email')}
          >
            <ErrorMessage component={ValidationMsg} name='email' />
          </SimpleInput>

          <SelectWithSearch
            label='Город*'
            name='city'
            value={values.city}
            placeholder='Начните вводить название города'
            onChange={setFieldValue}
            onSearch={getCities}
            setFieldTouched={setFieldTouched}
            source={citiesList}
            targetField='name'
            idField='id'
            style={{ width: '100%', marginBottom: '2px' }}
            toShownString={getFullCityName}
            error={getErrorCondition('city')}
          >
            {touched.city && errors.city && <ValidationMsg>{errors.city.id}</ValidationMsg> }
          </SelectWithSearch>

          <SimpleImageUploader label='Фото' photo={userPhoto} userId={user.id} />

          <div className='button_container__content_right'>
            <div className='lecture_form__button_wrapper'>
              <Button styles='simple_btn' title='Отмена' onClick={onCancel} />
              <Button
                delay={700}
                styles='rounded_btn__save_event'
                title='Сохранить'
                onClick={saveUser}
              />
            </div>
          </div>
        </div>
      </FormikForm>
    </div>
  )
}


const EditUserForm = withFormik({
  enableReinitialize: true,
  validationSchema: ValidationSchema,
  mapPropsToValues({ user }) {
    const getCityName = () => citiesSource?.find(city => city.id === user.city).name
    return ({
      firstName: user.firstname || '',
      lastName: user.lastname || '',
      phone_number: user.phone_number || '',
      email: user.email || '',
      description: user.description || '',
      sex: user.gender ? user.gender.substring(0, 1) : null,
      birthday: user.birthday ? moment(user.birthday) : null,
      city: user.city ? { id: user.city, name: getCityName() } : { id: null },
      isEmailConfirmed: user.isEmailConfirmed || false,
    })
  },
})(InnerForm)

const mapStateToProps = ({ files }, { user }) => {
  const userPhoto = (function () {
    const fileInCurrentFiles = files?.currentFilesList?.find(file => file.userId === user.id)
    return fileInCurrentFiles || user.photo
  }())

  return ({ userPhoto })
}

const mapDispatchToProps = dispatch => ({
  putUser: (values, photo, userId, callback) =>
    dispatch({ type: PUT_USER, user: values, photo, userId, callback }),
  resetPassword: (userId, userEmail) => dispatch({ type: RESET_PASSWORD, userId, userEmail }),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm)
