import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import getResponse from './getResponse'
import nextPaginationStart from '../utils/nextPaginationStart'
import { auth, content } from '../utils/data/requestHeaders'
import { GET, PUT, POST, DELETE } from '../utils/methods'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import {
  GET_PRESENTERS,
  GET_PRESENTERS_SUCCEED,
  SET_PRESENTERS_LOADING,
  PUT_PRESENTER,
  PUT_PRESENTER_SUCCEED,
  ACTIVATE_PRESENTER,
  ACTIVATE_PRESENTER_SUCCEED,
  START_PRESENTER_SESSION,
  STOP_PRESENTER_SESSION,
  CHANGE_CHART_ON_PRESENTER,
  STOP_PRESENTER_SESSION_SUCCEED,
  POST_PRESENTER,
  POST_PRESENTER_SUCCEED,
  DELETE_PRESENTER,
  DELETE_PRESENTER_SUCCEED,
} from '../actions/presenters'
import isNoMessage from '../utils/isNoMessage'

function* getPresenters({ page = 1, getAll }) {
  try {
    yield put({ type: SET_PRESENTERS_LOADING })
    let path = '/presenter'
    if (page && !getAll) {
      const startPosition = nextPaginationStart(page)
      path = `${path}?start=${startPosition}&limit=${ITEMS_ON_PAGE}`
    }

    const presenters = yield call(() => getResponse({ method: GET, path: path, headers: auth }))
    yield put({
      type: GET_PRESENTERS_SUCCEED,
      list: presenters.results,
      count: presenters.count,
      page: page,
    })
  } catch (e) { console.log(e.message) }
}

function* putPresenter({ presenter, callback }) {
  try {
    const path = `/presenters/${presenter.id}`
    const { id, ...presenterObj } = presenter

    yield call(() => getResponse({ method: PUT, path: path, headers: content, body: presenterObj }))
    yield put({ type: PUT_PRESENTER_SUCCEED, presenter })
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* postPresenter({ presenter, callback }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/presenter',
      headers: content,
      body: presenter,
    }))
    yield put({ type: POST_PRESENTER_SUCCEED, presenter: response })
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* activatePresenter({ presenterId, callback }) {
  try {
    const newPresenter = yield call(() => getResponse({
      method: POST,
      path: '/presenter/activate',
      headers: content,
      body: { presenter: presenterId },
    }))

    yield put({ type: ACTIVATE_PRESENTER_SUCCEED, newPresenter })
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* startPresenterSession({ payload, errorCallback }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/presenter_session',
      body: payload,
    }))

    if (!isNoMessage(response)) yield errorCallback && errorCallback()
  } catch (e) { console.log(e.message) }
}

function* stopPresenterSession({ presenter, errorCallback }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/presenter_stop',
      body: { presenter },
    }))

    yield put({ type: STOP_PRESENTER_SESSION_SUCCEED, presenter: response })

    if (!isNoMessage(response)) yield errorCallback && errorCallback()
  } catch (e) { console.log(e.message) }
}

function* changeChartOnPresenter({ payload, errorCallback }) {
  try {
    yield call(() => stopPresenterSession({ presenter: payload.presenter, errorCallback }))
    yield call(() => startPresenterSession({ payload, errorCallback }))
  } catch (e) {
    console.log(e.message)
    yield errorCallback && errorCallback()
  }
}

function* deletePresenter({ presenterId, callback }) {
  try {
    const response = yield call(() => getResponse({
      method: DELETE,
      path: `/presenters/${presenterId}`,
      headers: auth,
    }))

    yield isNoMessage(response) && put({
      type: DELETE_PRESENTER_SUCCEED,
      presenterId,
    })

    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

export default function* pollSagas() {
  yield takeLatest(GET_PRESENTERS, getPresenters)
  yield takeLatest(PUT_PRESENTER, putPresenter)
  yield takeLatest(ACTIVATE_PRESENTER, activatePresenter)
  yield takeLatest(START_PRESENTER_SESSION, startPresenterSession)
  yield takeLatest(STOP_PRESENTER_SESSION, stopPresenterSession)
  yield takeLatest(CHANGE_CHART_ON_PRESENTER, changeChartOnPresenter)
  yield takeLatest(POST_PRESENTER, postPresenter)
  yield takeLatest(DELETE_PRESENTER, deletePresenter)
}
