import React from 'react'
import { Field as FormikField } from 'formik'
import { Form, Input } from 'antd'

const SimpleInput = ({
  name,
  label,
  value,
  style,
  onChange,
  setFieldTouched,
  children,
  placeholder,
  onEnter,
  onBlur,
  onFocus,
  autofocus,
  suffix,
  error,
  disabled,
}) => (
  <Form.Item label={label}>
    <div className='validation_block'>
      <FormikField
        render={() => (
          <Input
            name={name}
            value={value}
            style={{ ...style, border: error && '1px solid #CD4141' }}
            onChange={e => onChange(name, e.target.value)}
            onBlur={() => {
              onBlur && onBlur()
              setFieldTouched(name, true)
            }}
            placeholder={placeholder}
            onPressEnter={onEnter}
            autoFocus={autofocus}
            suffix={suffix}
            onFocus={onFocus}
            disabled={disabled}
          />
        )}
      />
      <div className='validation_block margin_top__small'>{children}</div>
    </div>
  </Form.Item>
)

export default SimpleInput
