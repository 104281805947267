import React from 'react'

import FadeTransition from '../transition/FadeTransition'

const SeparatedCardTitle = ({ title, icon, loading, suffix, children }) => (
  <div className='main_title__card_view'>
    <div className='flex_container__align_items__center full_width'>
      <div className={icon} />
      <FadeTransition loading={Boolean(!loading && title)}>
        <div className='flex_container flex_container__space_between full_width'>
          <span className='large_btn__title text__wrapper'>{title?.toUpperCase()}</span>
          {!loading && suffix}
        </div>
      </FadeTransition>
      {!loading && children}
    </div>
  </div>
)

export default SeparatedCardTitle
