import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Button from '../UI/Button'
import CardListItem from '../helpers/lists/CardListItem'
import filterPolls from '../../utils/filters/filterPolls'
import getTimeMarkerStyle from '../../utils/getStyle/getTimeMarkerStyle'
import MainCard from '../MainCard'
import ScrollTransition from '../helpers/transition/ScrollTransition'
import VotingCardListItem from './VotingCardListItem'
import VotingFilter from './helpers/VotingFilter'
import { GET_POLLS, DELETE_POLL } from '../../actions/poll'
import { ITEMS_ON_PAGE } from '../../utils/data/constants'
import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import Notifications from '../Notifications/index'
import ConfirmationModal from '../UI/modal/ConfirmationModal'

const DEFAULT_VOTING = { id: null, title: '' }

class List extends React.Component {
  state = {
    page: 1,
    filter: 'all',
    filteredList: [],
    pollsList: [],
    isConfirmShow: false,
    selectedVoting: DEFAULT_VOTING,
  }

  componentDidMount() {
    this.props.getPolls(this.state.page)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      pollsList: nextProps.list,
      filteredList: filterPolls(nextProps.list, prevState.filter),
    }
  }

  // onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
  //   const { loading, count } = this.props
  //   const isAllFetched = count <= (this.state.page * ITEMS_ON_PAGE)
  //
  //   if (loading || isAllFetched) return null
  //
  //   const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 1)
  //   if (isScrollAtTheBottom)
  //     this.setState(prevState => ({ pages: prevState.pages + 1 }),
  //       () => this.props.getPolls(this.state.page, this.state.filter))
  // }

  setFilterTabStyle = radioItem => {
    const defaultClass = 'filter__tab'
    return (radioItem === this.state.filter) ? `${defaultClass}__active` : defaultClass
  }

  handleFilter = e => {
    const { value } = e.target
    this.setState(prevState => ({
      ...prevState,
      filter: value,
      filteredList: filterPolls(prevState.pollsList, value),
    }))
  }

  pollsFilterBlock = () => (
    <VotingFilter
      setStyle={this.setFilterTabStyle}
      onFilterTabChange={this.handleFilter}
      name='poll-filter'
    />
  )

  createButtonBlock = () => (
    <Button
      styles='bordered_btn'
      icon='icon__add_lecture__rounded'
      title='Добавить голосование'
      onClick={() => this.props.history.push('voting/new')}
    />
  )

  showConfirm = (id, title) => this.setState({ selectedVoting: { id, title }, isConfirmShow: true })

  closeConfirm = () => this.setState({ isConfirmShow: false, selectedVoting: DEFAULT_VOTING })

  render() {
    const { loading, deletePoll, history } = this.props
    const { filteredList, isConfirmShow, selectedVoting } = this.state
    return (
      <>
        <MainCard
          icon='icon__voting'
          title='Голосования'
          filter={this.pollsFilterBlock()}
          btn={this.createButtonBlock()}
          loading={loading}
        >
          <ScrollTransition loading={!loading}>
            <div className='card_list'>
              {filteredList?.length > 0
                ? filteredList.map((p, index) => (
                  <CardListItem
                    key={p.id}
                    onDelete={() => this.showConfirm(p.id, p.title)}
                    onEdit={() => history.push(`/voting/${p.id}/edit`)}
                    onCardClick={() => history.push(`/voting/${p.id}`)}
                  >
                    <VotingCardListItem
                      name={p.title}
                      dateFrom={p.date_from}
                      dateTo={p.date_to}
                      index={index}
                      marker={getTimeMarkerStyle(p.date_from, p.date_to)}
                    />
                  </CardListItem>
                ))
                : <EmptyPlaceholder text='Список голосований пуст' />}
            </div>
          </ScrollTransition>
        </MainCard>
        {isConfirmShow && (
          <ConfirmationModal
            start={isConfirmShow}
            text={`Удалить голосование "${selectedVoting.title}"?`}
            onConfirm={() => deletePoll(selectedVoting.id, this.closeConfirm)}
            onClose={this.closeConfirm}
          />
        )}
        <Notifications />
      </>
    )
  }
}

const mapStateToProps = ({ poll }) => ({
  list: poll.list || [],
  loading: poll.loading,
  count: poll.count,
})

const mapDispatchToProps = dispatch => ({
  getPolls: (page, filter) => dispatch({ type: GET_POLLS, page, filter }),
  deletePoll: (pollId, callback) => dispatch({ type: DELETE_POLL, pollId, callback }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List))
