import React, { useState } from 'react'

import LargeSpin from '../../UI/Spin/LargeSpin'
import FadeTransition from '../transition/FadeTransition'
import OnAirSwitch from '../../Voting/helpers/OnAirSwitch'
import ChartTypeSwitch from '../../UI/ChartTypeSwitch'
import getAnswerStatistic from '../../charts/statistic/getAnswerStatistic'
import PieChart from '../../charts/PieChart'
import VerticalBarChart from '../../charts/VerticalBarChart'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import GroupedBarChart from '../../charts/GroupedBarChart'
import HorizontalBarChart from '../../charts/HorizontalBarChart'
import HorizontalGroupedBarChart from '../../charts/HorizontalGroupedBarChart'
import packDatasets from '../../charts/helpers/packDatasets'

const SmallChartCard = ({
  activePresenter,
  chartOnAir,
  cropYLabels,
  grouped,
  loading,
  name,
  setActivePresenter,
  setChartOnAir,
  setSwitchActivePopUp,
  source,
  switchActivePopUp,
  title,
  types,
  style,
}) => {
  const [currentType, setCurrentType] = useState(types ? types[0] : 'pie')

  const showGroupedChart = () => {
    switch(currentType) {
      case 'bar': return <GroupedBarChart source={packDatasets(source)} />
      case 'horizontal_bar':
        return <HorizontalGroupedBarChart source={packDatasets(source)} cropYLabels={cropYLabels} />
    }
  }

  const showChart = () => {
    switch(currentType) {
      case 'pie': return <PieChart {...getAnswerStatistic(source, true)} />
      case 'bar': return <VerticalBarChart {...getAnswerStatistic(source)} />
      case 'horizontal_bar':
        return <HorizontalBarChart {...getAnswerStatistic(source)} cropYLabels={cropYLabels} />
    }
  }

  const getCardStyle = () => {
    const isChartOnAir = (chartOnAir?.name === name) && activePresenter
    const basicStyle = 'card__small'

    return isChartOnAir ? `${basicStyle}__active` : basicStyle
  }

  const onSwitchClick = name => {
    if (switchActivePopUp === name) {
      setSwitchActivePopUp(null)
      return
    }
    if (activePresenter) {
      setChartOnAir({ name, type: currentType })
      return
    }
    setSwitchActivePopUp(name)
  }

  const onScreenSelect = async id => {
    await setChartOnAir({ name, type: currentType, presenter: id })
    await setActivePresenter(id)
    await setSwitchActivePopUp(null)
  }

  const onTypeChange = type => {
    const areNoChanges = (type === chartOnAir.type) && (name === chartOnAir.name)
    if (areNoChanges) return

    setCurrentType(type)
    activePresenter && setChartOnAir({ name, type })
  }

  return (
    <div className={getCardStyle()} style={style}>
      {loading
        ? <LargeSpin />
        : (
          <>
            <OnAirSwitch
              name={name}
              chartOnAir={chartOnAir.name}
              onSwitchClick={() => onSwitchClick(name)}
              onScreenSelect={onScreenSelect}
              switchActivePopUp={switchActivePopUp}
              setSwitchActivePopUp={setSwitchActivePopUp}
              activePresenter={activePresenter}
            />
            <FadeTransition loading={!loading}>
              <div className='card_title text__wrapper'>
                <div className='large_btn__title text__wrapper'>{title?.toUpperCase()}</div>
              </div>
            </FadeTransition>
            {source
              ? (
                <div className='card__small__chart_block'>
                  <div className='card__small__chart_wrapper'>
                    {grouped ? showGroupedChart() : showChart()}
                  </div>
                  <ChartTypeSwitch
                    types={types}
                    currentType={currentType}
                    onTypeChange={onTypeChange}
                  />
                </div>
              )
              : <EmptyPlaceholder text='Нет данных' />}
          </>
        )}
    </div>
  )
}

export default SmallChartCard
