import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { ErrorMessage, Form as FormikForm, withFormik } from 'formik'
import { withRouter } from 'react-router-dom'

import ConfirmationModal from '../UI/modal/ConfirmationModal'
import ValidationMsg from '../UI/ValidationMsg'
import PasswordInput from '../UI/input/PasswordInput'
import { CHANGE_PASSWORD } from '../../actions/account'

const ValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Обязательное поле'),
  newPassword: Yup.string()
    .min(8, 'Минимальная длина пароля 8 символов')
    .required('Обязательное поле'),
  newPasswordRepeat: Yup.mixed()
    .oneOf([Yup.ref('newPassword')], 'Подтверждение не совпадает с паролем')
    .required('Обязательное поле'),
})


const InnerForm = ({
  start,
  onClose,
  setFieldValue,
  setFieldTouched,
  values,
  setTouched,
  changeUserPassword,
  resetForm,
}) => {
  const getBasicInputProps = () => ({ onChange: setFieldValue, setFieldTouched })

  const packPasswords = () => ({
    old_password: values.oldPassword,
    new_password: values.newPassword,
  })

  const closeModal = () => {
    onClose()
    resetForm()
  }

  const changePassword = () => {
    setTouched({ oldPassword: true, newPassword: true, newPasswordRepeat: true })

    if (!ValidationSchema.isValidSync(values)) return null
    const payload = packPasswords()
    changeUserPassword(payload, closeModal)
  }

  return (
    <ConfirmationModal
      start={start}
      onClose={closeModal}
      confirmBtnText='Сохранить'
      title='СМЕНА ПАРОЛЯ'
      onConfirm={changePassword}
    >
      <FormikForm style={{ paddingTop: '10px', marginBottom: '50px' }}>
        <PasswordInput
          name='oldPassword'
          value={values.oldPassword}
          label='Старый пароль'
          style={{ width: '100%' }}
          placeholder=''
          {...getBasicInputProps()}
        >
          <ErrorMessage component={ValidationMsg} name='oldPassword' />
        </PasswordInput>
        <PasswordInput
          name='newPassword'
          value={values.newPassword}
          label='Новый пароль'
          style={{ width: '100%' }}
          placeholder=''
          {...getBasicInputProps()}
        >
          <ErrorMessage component={ValidationMsg} name='newPassword' />
        </PasswordInput>
        <PasswordInput
          name='newPasswordRepeat'
          value={values.newPasswordRepeat}
          label='Повторите новый пароль'
          style={{ width: '100%' }}
          placeholder=''
          {...getBasicInputProps()}
        >
          <ErrorMessage component={ValidationMsg} name='newPasswordRepeat' />
        </PasswordInput>
      </FormikForm>
    </ConfirmationModal>
  )
}

const PasswordChangeModal = withFormik({
  mapPropsToValues: () => ({ oldPassword: '', newPassword: '', newPasswordRepeat: '' }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapDispatchToProps = dispatch => ({
  changeUserPassword: (passwords, callback) =>
    dispatch({ type: CHANGE_PASSWORD, passwords, callback }),
})

export default withRouter(connect(null, mapDispatchToProps)(PasswordChangeModal))
