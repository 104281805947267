import React from 'react'
import { connect } from 'react-redux'

import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'
import ScrollTransition from '../../../helpers/transition/ScrollTransition'
import LecturesListItem from '../LecturesListItem'

const LecturesList = ({ lectures, getItemActivityClass, setActiveLecture, onIconClick }) => (
  <>
    <ScrollTransition loading>
      <div className='lectures_list'>
        {lectures?.length > 0
          ? lectures.map((lecture, index) => (
            <LecturesListItem
              key={lecture.id}
              getItemActivityClass={getItemActivityClass}
              lecture={lecture}
              number={index}
              icon='icon__delete'
              onIconClick={onIconClick}
              setActiveLecture={setActiveLecture}
            />
          ))
          : <EmptyPlaceholder text='В этом событии нет докладов' />}
      </div>
    </ScrollTransition>
  </>
)

const mapStateToProps = ({ events }, { newObject }) => {
  let lectures
  if (newObject) lectures = events.newEvent?.lectures || []
  else lectures = events.event?.lectures || []

  return { lectures }
}

export default connect(mapStateToProps)(LecturesList)
