import React from 'react'
import { connect } from 'react-redux'

import EmptyPlaceholder from '../../../../UI/EmptyPlaceholder'
import ScrollTransition from '../../../../helpers/transition/ScrollTransition'
import LectureListBlock from './LectureListBlock'

const LecturesList = ({ lectures }) => (
  <>
    <ScrollTransition loading>
      <div className='lectures_list'>
        {lectures?.length > 0
          ? lectures.map((lecture, index) => (
            <LectureListBlock
              key={lecture.id}
              lecture={lecture}
              index={index}
            />
          ))
          : <EmptyPlaceholder text='В этом событии нет докладов' />}
      </div>
    </ScrollTransition>
  </>
)

const mapStateToProps = ({ events }, { newObject }) => {
  let lectures
  if (newObject) lectures = events.newEvent?.lectures || []
  else lectures = events.event?.lectures || []
  return { lectures }
}

export default connect(mapStateToProps)(LecturesList)
