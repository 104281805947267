import { call, put, takeLatest } from 'redux-saga/effects'
import { message } from 'antd'

import getResponse from './getResponse'
import HOST from '../utils/data/connectionData'
import nextPaginationStart from '../utils/nextPaginationStart'
import { auth, content } from '../utils/data/requestHeaders'
import { PUT, POST, GET, DELETE } from '../utils/methods'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import {
  DELETE_OFFICE,
  DELETE_OFFICE_SUCCEED,
  GET_FLOORS,
  DELETE_TABLE,
  DELETE_TABLE_SUCCEED,
  DELETE_ROOM,
  DELETE_ROOM_SUCCEED,
  GET_FLOORS_SUCCEED,
  GET_OFFICE,
  GET_OFFICE_FAILED,
  GET_OFFICE_SUCCEED,
  GET_OFFICES,
  GET_OFFICES_FAILED,
  GET_OFFICES_SUCCEED,
  GET_ROOMS_LIST,
  GET_ROOMS_LIST_SUCCEED,
  GET_TABLE_TAGS,
  GET_TABLE_TAGS_SUCCEED,
  GET_TABLES,
  GET_TABLES_SUCCEED,
  OFFICES_LOADING,
  POST_OFFICE,
  POST_OFFICE_SUCCEED,
  POST_ROOM,
  POST_ROOM_SUCCEED,
  POST_TABLE,
  POST_TABLE_SUCCEED,
  PUT_TABLE,
  PUT_TABLE_SUCCEED,
  PUT_OFFICE,
  SET_FLOORS_LOADING,
  SET_ROOMS_LOADING,
  SET_TABLES_LOADING,
  PUT_ROOM,
  PUT_ROOM_SUCCEED,
  ACTIVATE_TABLE,
  ACTIVATE_TABLE_SUCCEED,
  GET_SEARCH_OFFICES,
  GET_SEARCH_OFFICES_SUCCEED,
  SET_TABLE_TAGS_LOADING,
} from '../actions/offices'

function* getOfficesList({ page = 1, callback, filter, search }) {
  try {
    yield put({ type: OFFICES_LOADING })
    const startPosition = nextPaginationStart(page)
    const searchPath = search ? `&search=${search}` : ''
    const filterPath = (filter && filter.length) ? `&tags=${filter.join('&tags=')}` : ''
    const path = `/office?start=${startPosition}&limit=${ITEMS_ON_PAGE}${searchPath}${filterPath}`

    const offices = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_OFFICES_SUCCEED, list: offices.results, count: offices.count, page })
    callback && callback()
  } catch (e) {
    yield put({ type: GET_OFFICES_FAILED })
    console.log(e.message)
  }
}

function* getOfficesListSearch({ search }) {
  try {
    const path = `/office?search=${search}`
    const offices = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_SEARCH_OFFICES_SUCCEED, list: offices.results })
  } catch (e) { console.log(e.message) }
}

function* getFloors({ officeId, filter }) {
  try {
    yield put({ type: SET_FLOORS_LOADING })
    const path = `/floor?office=${officeId}&expand=0${filter !== null ? `&type=${filter}` : ''}`

    const floors = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_FLOORS_SUCCEED, floors })
  } catch (e) { console.log(e.message) }
}

function* getOffice({ officeId, editMode, callback, filter }) {
  try {
    if (officeId === null) return null
    yield put({ type: OFFICES_LOADING })
    const office = yield getResponse({ method: GET, path: `/office?id=${officeId}`, headers: auth })

    yield call(() => getFloors({ officeId, filter: filter || null }))
    yield put({ type: GET_OFFICE_SUCCEED, office, editMode })
    callback && callback()
  } catch (e) {
    yield put({ type: GET_OFFICE_FAILED })
    console.log(e.message)
  }
}

export function* getTables({ roomId, page, filter, editMode, callback }) {
  try {
    yield put({ type: SET_TABLES_LOADING })
    let path = `/table?room=${roomId}`
    if (page) path = `${path}&start=${nextPaginationStart(page)}&limit=${ITEMS_ON_PAGE}`
    if (filter) path = `${path}&free=${filter}`

    const tables = yield getResponse({ method: GET, path: path, headers: auth })
    yield put({
      roomId,
      page,
      editMode,
      count: tables.count,
      list: tables.results,
      type: GET_TABLES_SUCCEED,
    })

    yield callback && callback(tables.results)
    return tables.results
  } catch (e) { console.log(e.message) }
}

function* getRoomsList({ floorId, page, filter }) {
  try {
    yield put({ type: SET_ROOMS_LOADING })
    const startPosition = nextPaginationStart(page)
    const path = `/room?floor=${floorId}&start=${startPosition}&limit=${ITEMS_ON_PAGE}&expand=0`

    const floor = yield getResponse({
      method: GET,
      path: `${path}${filter ? `&type=${filter}` : ''}`,
      headers: auth,
    })

    yield put({ type: GET_ROOMS_LIST_SUCCEED, list: floor.results, count: floor.count, page })
  } catch (e) { console.log(e.message) }
}

function* deleteOffice(action) {
  try {
    yield call(fetch, `${HOST}/offices/${action.officeId}`, {
      method: DELETE,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    })

    yield put({ type: DELETE_OFFICE_SUCCEED, officeId: action.officeId })
    message.success(`Вы удалили "${action.title}"`)
  } catch (e) {
    console.log(e.message)
    message.error(`Не удалось удалить "${action.title}"`)
  }
}

function* getTableTags() {
  try {
    yield put({ type: SET_TABLE_TAGS_LOADING })
    const tagsList = yield getResponse({ method: GET, path: '/table_tag', headers: auth })
    yield put({ type: GET_TABLE_TAGS_SUCCEED, tagsList })
  } catch (e) { console.log(e.message) }
}

function* postOffice({ office, callback }) {
  try {
    const newOffice = yield getResponse({
      method: POST,
      path: '/office',
      headers: content,
      body: office,
    })

    message.success('Изменения сохранены')
    yield put({ type: POST_OFFICE_SUCCEED, newOffice })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putOffice({ officeId, office, callback }) {
  try {
    yield getResponse({ method: PUT, path: `/offices/${officeId}`, headers: content, body: office })
    message.success('Изменения сохранены')
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* postRoom({ room, callback }) {
  try {
    const roomWithoutFloorId = { ...room }
    const newRoom = yield getResponse({
      method: POST,
      path: '/room',
      headers: content,
      body: roomWithoutFloorId,
    })

    yield put({ type: POST_ROOM_SUCCEED, newRoom: { ...newRoom } })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putRoom({ room, roomId, callback }) {
  try {
    const newRoom = yield getResponse({
      method: PUT,
      path: `/rooms/${roomId}`,
      headers: content,
      body: room,
    })

    message.success('Изменения сохранены')
    yield put({ type: PUT_ROOM_SUCCEED, newRoom })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* postTable({ table, callback }) {
  try {
    const newTable = yield getResponse({
      method: POST,
      path: '/table',
      headers: content,
      body: table,
    })

    if (newTable.message === 'Meeting room must have no more than single table') {
      message.error('В переговорной может быть только 1 стол')
      throw newTable.message
    }
    yield put({ type: POST_TABLE_SUCCEED, newTable: { ...newTable, room: table.room } })
    message.success('Стол добавлен')
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putTable({ table, tableId, roomId, callback }) {
  try {
    const newTable = yield getResponse({
      method: PUT,
      path: `/tables/${tableId}`,
      headers: content,
      body: table,
    })

    message.success('Изменения сохранены')
    yield put({ type: PUT_TABLE_SUCCEED, newTable, roomId })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* deleteTable({ tableId, roomId }) {
  try {
    yield call(fetch, `${HOST}/tables/${tableId}`, {
      method: DELETE,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    })
    yield put({ type: DELETE_TABLE_SUCCEED, tableId, roomId })
  } catch (e) { console.log(e.message) }
}

function* deleteRoom({ roomId }) {
  try {
    yield call(fetch, `${HOST}/rooms/${roomId}`, {
      method: DELETE,
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
    })
    yield put({ type: DELETE_ROOM_SUCCEED, roomId })
  } catch (e) { console.log(e.message) }
}

function* activateTable({ tableId, roomId }) {
  try {
    const table = yield getResponse({
      method: POST,
      path: '/table/activate',
      headers: content,
      body: { table: tableId },
    })

    yield put({ type: ACTIVATE_TABLE_SUCCEED, table, roomId })
  } catch (e) { console.log(e.message) }
}

export default function* pollSagas() {
  yield takeLatest(ACTIVATE_TABLE, activateTable)
  yield takeLatest(GET_OFFICES, getOfficesList)
  yield takeLatest(GET_SEARCH_OFFICES, getOfficesListSearch)
  yield takeLatest(GET_OFFICE, getOffice)
  yield takeLatest(GET_TABLES, getTables)
  yield takeLatest(DELETE_OFFICE, deleteOffice)
  yield takeLatest(GET_ROOMS_LIST, getRoomsList)
  yield takeLatest(GET_FLOORS, getFloors)
  yield takeLatest(GET_TABLE_TAGS, getTableTags)
  yield takeLatest(POST_OFFICE, postOffice)
  yield takeLatest(PUT_OFFICE, putOffice)
  yield takeLatest(POST_ROOM, postRoom)
  yield takeLatest(PUT_ROOM, putRoom)
  yield takeLatest(POST_TABLE, postTable)
  yield takeLatest(PUT_TABLE, putTable)
  yield takeLatest(DELETE_TABLE, deleteTable)
  yield takeLatest(DELETE_ROOM, deleteRoom)
}
