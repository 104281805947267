import createSagaMiddleware from 'redux-saga'
import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './reducers'

import watchSaga from './sagas/index'
import AuthRouter from './AuthRouter'

const sagaMiddleware = createSagaMiddleware()

// /* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(sagaMiddleware)),
  // applyMiddleware(sagaMiddleware),
)
/* eslint-enable */

sagaMiddleware.run(watchSaga)

ReactDOM.render(
  <Provider store={store}>
    <AuthRouter />
  </Provider>,
  document.getElementById('root'),
)
