import React from 'react'
import { Field as FormikField } from 'formik'
import { Form, Select } from 'antd'

const SimpleSelect = ({
  name,
  label,
  value,
  onChange,
  setFieldTouched,
  style,
  disabled,
  children,
  error,
  placeholder,
}) => (
  <Form.Item label={label}>
    <div className='validation_block'>
      <FormikField
        render={() => (
          <Select
            name={name}
            value={value}
            style={style}
            onChange={val => onChange(name, val)}
            onBlur={setFieldTouched}
            disabled={disabled}
            placeholder={placeholder}
            notFoundContent='Не найдено'
            defaultActiveFirstOption
          >
            {children}
          </Select>
        )}
      />
      <div className='validation_block margin_top__small'>{error && error()}</div>
    </div>
  </Form.Item>
)


export default SimpleSelect
