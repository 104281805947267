import React from 'react'
import { Tooltip } from 'antd'

import BigCardTitle from '../../helpers/cards/BigCardTitle'
import ScreenList from './ScreenList'
import LargeSpin from '../../UI/Spin/LargeSpin'

const ScreensCard = ({ screens, activePresenter, setActivePresenter, setDefaultChartOnAir }) => (
  <div className='card__bottom' id='requests'>
    <Tooltip
      placement='bottomRight'
      arrowPointAtCenter
      title='Если в списке нет нужного экрана, убедитесь что он активирован.'
      overlayStyle={{ whiteSpace: 'pre-wrap' }}
    >
      <div className='icon__info top_right__icon_container' />
    </Tooltip>
    <div className='title_block'>
      <BigCardTitle icon='icon__requests__blue' title='Транслировать на экран:' />
    </div>
    {screens
      ? (
        <ScreenList
          setDefaultChartOnAir={setDefaultChartOnAir}
          activePresenter={activePresenter}
          setActivePresenter={setActivePresenter}
          list={screens}
          fieldToShow='title'
          subtitleField='description'
        />
      )
      : <LargeSpin />}
  </div>
)

export default ScreensCard
