import React from 'react'
import { Tag } from 'antd'

import DisabledInputBlock from '../../../UI/DisabledInputBlock'
import UploadedFilesList from '../fileUploader/UploadedFileList'
import { dateToString } from '../../../../utils/formatDate'

export default class LectureCard extends React.Component {
  state = { lecture: {} }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { lecture } = nextProps

    if (lecture.id !== prevState.lecture.id) return { lecture }
    return { lecture: prevState.lecture }
  }

  renderTags = tags => tags && tags.length > 0
    ? <div className='info__block__display'>{tags.map(t => <Tag key={t}>{t}</Tag>)}</div>
    : <div className='secondary_text'>Теги не установлены</div>

  render() {
    const { lecture } = this.state
    return (
      <div className='lecture_form' style={{ overflow: 'hidden' }}>
        <div className='container_form__left'>
          <DisabledInputBlock title='Название доклада' innerText={lecture.title} />
          <DisabledInputBlock title='Спикер'>
            {lecture?.speaker
              ? <div className='secondary_text__white'>{lecture.speaker.name}</div>
              : <div className='secondary_text'>Спикер не назначен</div>}
          </DisabledInputBlock>
          <DisabledInputBlock title='Материалы'>
            {lecture?.papers.length > 0
              ? lecture.papers.map(f => <UploadedFilesList key={f.id} file={f} icons hideDelete />)
              : <div className='secondary_text'>Нет прикрепленных материалов</div>}
          </DisabledInputBlock>
        </div>

        <div className='container_form__right'>
          <DisabledInputBlock title='Тематические теги'>
            {this.renderTags(lecture?.tags)}
          </DisabledInputBlock>
          <DisabledInputBlock title='Время начала'>
            {lecture?.date
              ? <div className='secondary_text__white'>{dateToString(lecture.date)}</div>
              : <div className='secondary_text'>Время не установлено</div>}
          </DisabledInputBlock>
          <DisabledInputBlock title='Офис/Кабинет'>
            {lecture?.place?.title
              ? <div className='secondary_text__white'>{lecture.place.title}</div>
              : <div className='secondary_text'>Место не установлено</div>}
          </DisabledInputBlock>
        </div>
      </div>
    )
  }
}
