import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'

import horizontalGroupedBarChartOptions from './setups/horizontalGroupedBarChartSetups'

const HorizontalGroupedBarChart = ({ source, cropYLabels }) => {
  const getMaxVal = () => {
    let maxVal = 0
    source.datasets.forEach(item =>
      maxVal = (Math.max(...item.data) > maxVal) ? Math.max(...item.data) : maxVal)
    return maxVal + maxVal * 0.1
  }

  return (
    <div className='group_bar_chart__wrapper'>
      <div className='flex_container__flex_1 full_height'>
        <HorizontalBar
          data={source}
          options={horizontalGroupedBarChartOptions(getMaxVal(), cropYLabels)}
        />
      </div>
    </div>
  )
}

export default HorizontalGroupedBarChart
