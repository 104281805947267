import React from 'react'
import * as _ from 'lodash'
import { Field as FormikField } from 'formik'
import { Form, AutoComplete } from 'antd'

const initialState = { searchString: '', filteredList: [] }

class SelectWithSearch extends React.Component {
  state = initialState

  static getDerivedStateFromProps(nextProps) {
    return { filteredList: nextProps.source }
  }

  setNoSelectedOption = () => {
    const { name, idField, targetField, onChange } = this.props
    onChange(name, { [idField]: null, [targetField]: '' })
  }

  handleChange = val => {
    this.setState({ searchString: val })
    if (val === '') this.setNoSelectedOption()
  }

  getReturnObject = selectedObj => {
    const objectExtraFields = {}
    const { toShownString, targetField, idField, fieldsToReturn } = this.props

    if (fieldsToReturn)
      fieldsToReturn.forEach(field => objectExtraFields[field] = selectedObj[field])

    return {
      [idField]: selectedObj[idField],
      [targetField]: toShownString(selectedObj),
      ...objectExtraFields,
    }
  }

  handleSelect = val => {
    const { name, onChange, idField } = this.props

    const selectedObj = this.state.filteredList.find(item => String(item[idField]) === val)

    onChange(name, this.getReturnObject(selectedObj))
    this.setState({ searchString: '' })
  }

  getAutocompleteProps = () => {
    const {
      name,
      value,
      targetField,
      onSearch,
      style,
      placeholder,
      error,
      setFieldTouched,
    } = this.props
    const { searchString } = this.state

    return ({
      name,
      style: { ...style, border: error && '1px solid #CD4141' },
      placeholder,
      onSearch: val => onSearch(val, 1),
      onChange: this.handleChange,
      onSelect: this.handleSelect,
      onBlur: () => setFieldTouched(name),
      value: searchString || value[targetField],
    })
  }

  getOptions = () => {
    const { toShownString, idField } = this.props
    const { filteredList } = this.state

    if (_.isEmpty(filteredList)) return []
    return filteredList.map(item => (
      <AutoComplete.Option key={item[idField]}>{toShownString(item)}</AutoComplete.Option>
    ))
  }

  render() {
    const { label, children, icon } = this.props

    return (
      <Form.Item label={label}>
        <div className='validation_block'>
          <FormikField
            render={() => (
              <AutoComplete {...this.getAutocompleteProps()}>
                {this.getOptions()}
              </AutoComplete>
            )}
          />
          <div className='validation_block margin_top__small'>{children}</div>
        </div>
        {icon && icon()}
      </Form.Item>
    )
  }
}

export default SelectWithSearch
