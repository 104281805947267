import React from 'react'
import { DatePicker, Form, ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'

const DatePick = ({
  name,
  value,
  label,
  onBlur,
  onChange,
  disabledDate,
  format,
  children,
  error,
  extraStyle,
  allowClear,
  showTime,
}) => (
  <Form.Item label={label} className='date_pick' style={extraStyle}>
    <ConfigProvider locale={ruRU}>
      <DatePicker
        allowClear={allowClear}
        showTime={showTime ? { format: showTime } : false}
        name={name}
        format={format || 'DD MMMM YYYY'}
        value={value}
        style={{ width: '100%', border: error && '1px solid #CD4141' }}
        className='date_picker__single'
        onChange={date => onChange(name, date)}
        // onBlur={() => onBlur(name)}
        disabledDate={disabledDate}
        onOpenChange={status => !status && onBlur(name)}
        suffixIcon={<div className='icon__calendar' />}
      />
      <div className='validation_block margin_top__small'>{children}</div>
    </ConfigProvider>
  </Form.Item>
)

export default DatePick
