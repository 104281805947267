import { call, put, takeEvery } from 'redux-saga/effects'

import HOST from '../utils/data/connectionData'
import {
  POST_FILE,
  POST_PHOTO,
  POST_FILE_SUCCEED,
  POST_PHOTO_SUCCEED,
  CHANGE_USER_PHOTO_SUCCEED,
  ADD_FILE_TO_NEW_LECTURE_SUCCEED,
  ADD_FILE_TO_NEW_LECTURE,
  SET_PHOTO_LOADING,
} from '../actions/files'
import { POST } from '../utils/methods'
import getResponse from './getResponse'
import { auth } from '../utils/data/requestHeaders'

const responsePostFile = file => ({
  method: POST,
  headers: ({ Authorization: `Bearer ${localStorage.getItem('access_token')}` }),
  body: file,
})

function* getPostFileResponse(action) {
  const response = yield call(fetch, `${HOST}/files`, responsePostFile(action.file))
  return yield response.json()
}

function* postFile(action) {
  try {
    const { lectureId, newObject } = action
    const file = yield call(() => getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: action.file,
      isFile: true,
    }))

    yield (!file.message && !file.msg)
      && put({ type: POST_FILE_SUCCEED, file, lectureId, newObject })
  } catch (e) { console.log(e.message) }
}

function* postPhoto({ file, isNew, userId, callback }) {
  try {
    yield put({ type: SET_PHOTO_LOADING })
    const postedFile = yield call(() => getResponse({
      method: POST,
      path: '/files',
      headers: auth,
      body: file,
      isFile: true,
    }))

    if (isNew) yield put({ type: POST_PHOTO_SUCCEED, photo: postedFile })
    else yield put({ type: CHANGE_USER_PHOTO_SUCCEED, photo: postedFile, userId })

    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* addFileToNewLecture(action) {
  try {
    const file = yield call(() => getPostFileResponse({ file: action.file }))
    yield put({ type: ADD_FILE_TO_NEW_LECTURE_SUCCEED, file })
  } catch (e) { console.log(e.message) }
}

export default function* filesSagas() {
  yield takeEvery(POST_FILE, postFile)
  yield takeEvery(POST_PHOTO, postPhoto)
  yield takeEvery(ADD_FILE_TO_NEW_LECTURE, addFileToNewLecture)
}
