import React from 'react'
import { DatePicker, Form, TimePicker, ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'

const DateTimePick = ({
  dateFieldName,
  timeFieldName,
  dateValue,
  timeValue,
  label,
  onBlur,
  onChange,
}) => (
  <Form.Item label={label}>
    <ConfigProvider locale={ruRU}>
      <DatePicker
        id={dateFieldName}
        name={dateFieldName}
        format='DD MMMM YYYY'
        value={dateValue}
        style={{ width: '65%' }}
        className='date_picker'
        onChange={date => onChange(dateFieldName, date)}
        onBlur={() => onBlur(dateFieldName, true)}
        suffixIcon={<div className='icon__calendar' />}
      />
      <TimePicker
        is={timeFieldName}
        name={timeFieldName}
        format='HH:mm'
        value={timeValue}
        style={{ width: '30%' }}
        onChange={time => onChange(timeFieldName, time)}
        onBlur={() => onBlur(timeFieldName, true)}
        suffixIcon={<div className='icon__clock' />}
        allowClear={false}
      />
    </ConfigProvider>
  </Form.Item>
)

export default DateTimePick
