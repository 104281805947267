import React from 'react'
import 'moment/locale/ru'
import * as Yup from 'yup'
import moment from 'moment'
import { connect } from 'react-redux'
import { withFormik, Form as FormikForm } from 'formik'
import { withRouter } from 'react-router-dom'
import * as _ from 'lodash'

import BigCardTitle from '../../../helpers/cards/BigCardTitle'
import LargeSpin from '../../../UI/Spin/LargeSpin'
import LecturesFormBlock from './LecturesFormBlock'
import EventForm from './EventForm'
import packEvent from '../../../../utils/objects/packEvent'
import { getMaxLengthMsg } from '../../../../utils/validation'
import { CLEAR_EVENT, CREATE_EVENT, GET_EVENT, SAVE_EVENT } from '../../../../actions/events'
import { CLEAR_LECTURES } from '../../../../actions/lectures'
import { GET_ACCESS_GROUPS, GET_USERS } from '../../../../actions/users'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Название должно быть длиннее 3 символов')
    .max(120, field => getMaxLengthMsg(120, field))
    .required('Обязательное поле'),
  description: Yup.string()
    .min(3, 'Описание должно быть больше 3 символов')
    .max(250, field => getMaxLengthMsg(250, field))
    .required('Обязательное поле'),
  place: Yup.string()
    .max(120, field => getMaxLengthMsg(120, field)),
})

export class InnerForm extends React.Component {
  componentDidMount() {
    const { newObject, getEvent, match, getGroupsList } = this.props
    if (!newObject) getEvent(match.params.id)

    getGroupsList()
  }

  componentWillUnmount() {
    const { clearLectures, clearEvent } = this.props
    clearLectures()
    clearEvent()
  }

  onEventSave = () => {
    const { values, createEvent, putEvent, newObject, setTouched, history } = this.props

    setTouched({ title: true, description: true, place: true })
    if (!ValidationSchema.isValidSync(values)) return null

    const event = packEvent(values)
    newObject ? createEvent(event, history) : putEvent(event, history)
  }

  getErrorCondition = field => this.props.errors[field] && this.props.touched[field]

  render() {
    const {
      values,
      setFieldTouched,
      setFieldValue,
      handleChange,
      newObject,
      loading,
      groups,
    } = this.props

    return (
      loading
        ? <div className='main'><LargeSpin /></div>
        : (
          <div className='main' id='main'>
            <div className='title_block'>
              <BigCardTitle icon='icon__big_calendar' title='СОЗДАНИЕ СОБЫТИЯ' />
            </div>
            <div className='event_form'>
              <FormikForm>
                <div className='event_form__main'>
                  <EventForm
                    values={values}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    groups={groups}
                    getErrorCondition={this.getErrorCondition}
                  />
                </div>
                <div className='event_form__lecture'>
                  <LecturesFormBlock
                    eventId={values.id}
                    newObject={newObject}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onSave={this.onEventSave}
                  />
                </div>
              </FormikForm>
            </div>
          </div>
        )
    )
  }
}

const EditEvent = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ newObject, event, eventTags }) {
    if (newObject) return {}
    const dataMoment = moment(event.date)

    return {
      id: event.id,
      title: event.title,
      description: event.description,
      tags: eventTags,
      place: event.place?.title || '',
      startDate: dataMoment,
      startTime: dataMoment,
      group_whitelist_read: _.uniq(event.groupWhitelistRead?.map(group => group.id)),
    }
  },
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ events, users }) => ({
  groups: users?.groups?.list,
  event: events.event || {},
  eventTags: events.event?.tags || [],
  loading: events.loading,
})

const mapDispatchToProps = dispatch => ({
  getGroupsList: () => dispatch({ type: GET_ACCESS_GROUPS }),
  clearLectures: () => dispatch({ type: CLEAR_LECTURES }),
  clearEvent: () => dispatch({ type: CLEAR_EVENT }),
  createEvent: (event, history) => dispatch({ type: CREATE_EVENT, event, history }),
  getEvent: eventId => dispatch({ type: GET_EVENT, eventId }),
  putEvent: (event, history) => dispatch({ type: SAVE_EVENT, event, history }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditEvent))
